import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AddButton } from "../AddButton/AddButton";
import styles from './FootnotesSection.module.scss'
import ReactHtmlParser from 'react-html-parser';

export const FootnotesSection = ({ documentNumber, documentId }) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(true)

    const [footnotes, setFootnotes] = useState(null);
    const [currentDoc, setCurrentDoc] = useState(documentNumber)

    useEffect(() => {
        if (!footnotes || currentDoc !== documentNumber) {
            axios.get(`footnotes/${documentNumber}?language=${i18n.language}`).then(res => {
                setFootnotes(res.data.result || [])
            })
        }
        if (currentDoc !== documentNumber)
            setCurrentDoc(documentNumber)
    }, [footnotes, documentNumber])

    return <>
        <div className="card text-justify">
            <h4 className='card-header' onClick={() => setOpen(!open)}
                aria-controls="footnotes-collapse-text"
                aria-expanded={open} >{t('footnotes')} ({footnotes?.length || 0})</h4>

            <Collapse in={open}>
                <div className="card-body" id="footnotes-collapse-text">
                    {footnotes &&
                        footnotes.sort((a, b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0)).map((footnote, index) => {
                            return <Col key={index} id={`footnote-${footnote?.id}`} sm={12} lg={8} className='d-flex flex-column align-items-end'>
                                <Col className={classNames(styles.comment, 'p-3 col-12 d-flex')} >
                                    <span className="public-DraftStyleDefault-block">{index + 1}. </span>
                                    <div> {ReactHtmlParser(footnote.text)} </div>
                                </Col>
                            </Col>
                        })
                    }
                    {
                        footnotes &&
                        footnotes.length == 0 &&
                        <p className={styles.paragraph}><em>{t('no footnotes yet')}</em></p>
                    }

                    <AddButton path={'footnotes/'} itemId={documentId} updateField={() => setFootnotes(null)} footnoteNumber={footnotes?.length + 1} />
                </div>
            </Collapse>

        </div>
    </>
}