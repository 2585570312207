import { Button } from "react-bootstrap";
import React from "react";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

export const LinkToArticle = ({ item }) => {
    const { t } = useTranslation();

    const [articleNumber, setArticleNumber] = useState(null)
    const [marginNumber, setMarginNumber] = useState(null)

    const [uri, setUri] = useState(null)

    useEffect(() => {
        if (!uri && articleNumber) {
            let uri = articleNumber;
            if (marginNumber)
                uri += '&marginNumber=' + marginNumber

            setUri(uri)
        }
    }, [uri, articleNumber])

    const findArticleParentId = async (elementId) => {
        //check here
        const response = axios.get(`documents/${elementId}/find/parent/article`);

        let res = await response
        if (res.data.result.type === 2) {
            setArticleNumber(res.data.result.number)
            return
        }
        else
            findArticleParentId(res.data.result.documentId)
    }

    const saveMarginNumber = async (element) => {
        if(element.type == 4 )
            setMarginNumber(element.number)
        findArticleParentId(element.id)
    }

    useEffect(() => {
        if (item && !articleNumber)
            saveMarginNumber(item)
    }, [item])

    return uri ?
        <>
            <h5>{t('article')}: {articleNumber}</h5>
            <HashLink to={`/article?articleNumber=${uri}`}>
                <Button type="button" className="btn btn-primary">{t('go to')}</Button>
            </HashLink>
        </>

        :
        <>

            <Button disabled type="button" className="btn btn-primary w-10">
                {t('go to')}
            </Button>
        </>
}