import axios from 'axios';
import React, { useState } from 'react'
import { useTranslation } from "react-i18next";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const Register = () => {
    const { t } = useTranslation();


    const [person, setPerson] = useState({})

    const onSubmit = (e) => {
        e.preventDefault();

        if (person.firstName == null || person.firstName == '') {
            toast.error(t('please write your first name'))
        }
        if (person.lastName == null || person.lastName == '') {
            toast.error(t('please write your last name'))
        }
        if (person.email == null || person.email == '') {
            toast.error(t('please write your email address'))
        }
        if (person.telephoneNumber == null || person.telephoneNumber == '') {
            toast.error(t('please write your telephone number'))
        }

        if (person.firstName.length > 0 && person.firstName.length > 0 && person.email.length >0 && person.telephoneNumber.length >0){
            axios.post('users',{
                email: person.email,
                userName : `${person.firstName}.${person.lastName}`,
                password:''
            }).then(res=>{
                toast.success(t('email sent'))
            })
        }
    }

    return (
        <div className='container p-0 d-flex flex-column align-items-center' >
            <h1 className='col-12 p-3 d-flex justify-content-center'>{t('register')}</h1>

            <ToastContainer  />
            <form onSubmit={onSubmit} className="d-flex flex-column align-items-center  col-12 col-md-4">

                <div className="row m-0 d-flex col-12">
                    <div className="form-outline p-3 col-6 col-md-6">
                        <label>{t('first name')}</label>
                        <input onChange={e => setPerson({ ...person, firstName: e.target.value })} className="form-control" />
                    </div>
                    <div className="form-outline p-3 col-6 col-md-6">
                        <label>{t('last name')}</label>
                        <input onChange={e => setPerson({ ...person, lastName: e.target.value })} className="form-control"  />
                    </div>
                </div>

                <div className="form-outline p-3 col-12">
                    <label>{t('email address')}</label>
                    <input onChange={e => setPerson({ ...person, email: e.target.value })} type="email" className="form-control"  />
                </div>

                <div className="form-outline p-3 col-12">
                    <label>{t('telephone number')}</label>
                    <input onChange={e => setPerson({ ...person, telephoneNumber: e.target.value })} className="form-control" />
                </div>


                <div className="row m-0 p-3 col-9">
                    <button type="submit" value="Submit" className="btn btn-primary btn-block" >{t('sign up')}</button>
                </div>

            </form>
        </div>
    )
}

