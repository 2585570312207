import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AddButton } from "../AddButton/AddButton";
import styles from './CaseLawSection.module.scss'
import ReactHtmlParser from 'react-html-parser';

export const CaseLaw = ({ documentNumber, documentId }) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false)

    const [caseLaws, setCaseLaws] = useState(null);
    const [currentDoc, setCurrentDoc] = useState(documentNumber)

    useEffect(() => {
        if (!caseLaws || currentDoc !== documentNumber) {
            axios.get(`caseLaw/${documentNumber}?language=${i18n.language}`).then(res => {
                setCaseLaws(res.data.result || [])
            })
        }
        if (currentDoc !== documentNumber)
            setCurrentDoc(documentNumber)
    },[caseLaws, documentNumber])
    return (
        <div className="card text-justify">
            <h4 className='card-header' onClick={() => setOpen(!open)}
                aria-controls="caselaw-collapse-text"
                aria-expanded={open} >{t('case law section')} ({caseLaws?.length || 0})</h4>

            <Collapse in={open}>
                <div className="card-body" id="caselaw-collapse-text">
                    {caseLaws &&
                        caseLaws.map((caseLaw, index) => {
                            return <Col key={index} sm={12} lg={8} className='d-flex flex-column align-items-end'>
                                <Col className={classNames(styles.comment, 'p-3 col-12')} >

                                    {ReactHtmlParser(caseLaw.text)}

                                    <b>
                                        <p className={classNames(styles.paragraph, styles.italicFont)}>{caseLaw.createdAt.split("T")[0]} {caseLaw.createdAt.split("T")[1].split('.')[0]}</p>
                                    </b>
                                </Col>
                            </Col>
                        })
                    }
                    {
                        caseLaws &&
                        caseLaws.length == 0 &&
                        <p className={styles.paragraph}><em>{t('no case laws yet')}</em></p>
                    }

                    <AddButton path={'caseLaws/'} itemId={documentId} updateField={()=>setCaseLaws(null)} />
                </div>
            </Collapse>

        </div>
    )
}