import React, {useState} from 'react'
import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ChangePassword = () => {
    const { t } = useTranslation();

    const[passwords, setPasswords] = useState({oldPassword: '', newPassword: '', confirmPassword: ''})

    const onSubmit = (e) => {
        e.preventDefault(); 
        
        if(passwords.oldPassword == ''){
            toast.error('Please write your old password', {theme: "colored"})
        }

        if(passwords.newPassword == ''){
            toast.error('Pleace write your new password', {theme: "colored"})
        }

        if(passwords.confirmPassword == ''){
            toast.error('Pleace confirm your new password', {theme: "colored"})
        }
        
        if(passwords.newPassword !== passwords.confirmPassword){
            toast.error('Please write your new password correctly two times', {theme: "colored"})
        }

        if(passwords.oldPassword != '' && passwords.newPassword != '' && passwords.confirmPassword != '' && (passwords.newPassword === passwords.confirmPassword)){
            toast.success('Succesfully changed your password', {theme: "colored"})
        }


    }

    return (
        <div className='container p-0 d-flex flex-column align-items-center'>
            <h1 className='col-12 p-3 d-flex justify-content-center'>{t('change password')}</h1>

            <ToastContainer />
            <form onSubmit={onSubmit} className="d-flex flex-column align-items-center col-12">
                <div className="form-outline p-3 col-12 col-md-4">
                    <label>{t('old password')}</label>
                    <input onChange={e => setPasswords({ ...passwords, oldPassword: e.target.value })} type="password" className="form-control" />
                </div>
                <div className="form-outline p-3 col-12 col-md-4">
                    <label>{t('new password')}</label>
                    <input onChange={e => setPasswords({ ...passwords, newPassword: e.target.value })} type="password" className="form-control" />
                </div>
                <div className="form-outline p-3 col-12 col-md-4">
                    <label>{t('confirm password')}</label>
                    <input onChange={e => setPasswords({ ...passwords, confirmPassword: e.target.value })} type="password" className="form-control" />
                </div>

                <div className="row m-0 p-3 col-9 col-md-3">
                    <button type="submit" value="Submit" className="btn btn-primary btn-block">{t('change password')}</button>
                </div>
            </form>


        </div>
    )
}

