import React from "react";
import styles from './Post.module.scss'
import { useTranslation } from "react-i18next";
import { Button, Collapse } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";

export const Post = () => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false)

    return <div className={styles.container}>

        {/* <div className={styles.firstPart}> */}
        <div className={styles.firstPart}>

            <div className="text-justify container p-3 col-md-6" >

                <h2>{t('legal commentary')}</h2>



                <Button onClick={() => setOpen(!open)} type="button">{t('read the preface')}</Button>
                <Collapse in={open}>
                    <div id="preface-collapse-text">
                        <h4>{t('post.t1')}</h4>
                        <p> {t('post.t2')}<b><u> {t('post.t3')}</u></b> {t('post.t4')}</p>
                        <p><b><u>{t('post.t5')}</u></b> {t('post.t6')}</p>
                        <p>{t('post.t7')}</p>
                        <p>{t('post.t8')}<b><u>{t('post.t9')}</u></b> {t('post.t10')}</p>
                        <p>{t('post.t11')}</p>
                        <p>{t('post.t12')}<b><u>{t('post.t13')}</u></b> {t('post.t14')} <b><u>{t('post.t15')}</u></b> {t('post.t16')}<b><u>{t('post.t17')}</u></b> {t('post.t18')}
                        </p>
                        <p>{t('post.t19')}</p>
                        <p>{t('post.t20')}&nbsp;<b>
                                <u>
                                    {t('post.t21')}
                                </u>
                            </b>
                            {t('post.t22')}
                            <b>
                                <u>
                                    {t('post.t23')}
                                </u>
                            </b>
                            &nbsp;
                            {t('post.t24')}
                            <b>
                                <u>
                                    {t('post.t24_1')}
                                </u>
                            </b>
                            &nbsp;
                            {t('post.t24_2')}
                        </p>
                        <p>
                            {t('post.t25')}
                            <b>
                                <u>
                                    {t('post.t26')}
                                </u>
                            </b>
                            {t('post.t27')}
                            &nbsp;
                            <b>
                                <u>
                                    {t('post.t28')}
                                </u>
                            </b>
                            &nbsp;{t('post.t29')}
                        </p>
                        <p>
                            {t('post.t30')}
                            &nbsp;
                            <b>
                                <u>
                                    {t('post.t31')}
                                </u>
                            </b>
                            &nbsp;
                            {t('post.t32')}
                            <b>
                                <u>
                                    {t('post.t33')}
                                </u>
                            </b>
                            &nbsp;
                            {t('post.t34')}
                            &nbsp;
                            <b>
                                <u>
                                    {t('post.t34_1')}
                                </u>
                            </b>
                            &nbsp;
                            {t('post.t34_2')}
                            <b>
                                <u>
                                    {t('post.t34_3')}
                                </u>
                            </b>
                            &nbsp;
                            {t('post.t34_4')}
                        </p>
                        <p>{t('post.t35')}</p>

                        <h4>{t('post.t36')}</h4>
                        <p>{t('post.t37')}&nbsp;
                            <b>
                                <u>
                                    {t('post.t38')}
                                </u>
                            </b>
                            {t('post.t39')}
                        </p>
                        <p>{t('post.t40')}<a className="text-white" href="https://ec.europa.eu/info/aid-development-cooperation-fundamental-rights/your-rights-eu/eu-charter-fundamental-rights_en" target={'_blank'}>{t('post.t41')}</a> {t('post.t42')}
                        </p>
                        <p>{t('post.t43')}
                        </p>
                        <p>{t('post.t44')} <b><u>{t('post.t45')}</u></b> {t('post.t46')}<b><u>{t('post.t47')}</u></b> {t('post.t48')} <Link className="text-white" to={'/authors'}> {t('post.t49')}</Link> {t('post.t50')}
                        </p>
                        <p>{t('post.t51')}<Link className="text-white" to={'search?text=comentary'}>{t('post.t52')}</Link> {t('post.t53')} </p>
                        <p>{t('post.t54')}<Link className="text-white" to={'/bibliography'}>{t('post.t55')}</Link> {t('post.t56')} </p>
                    </div>
                </Collapse>
            </div>


        </div>


    </div>
}