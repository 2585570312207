import React from "react";
import styles from './Sidebar.module.scss'
import ReactHtmlParser from 'react-html-parser';
import { EditButton } from "../EditButton/EditButton";

export const SubArticleGrouper = ({ item, draftView }) => {

    return <>
        <h4 id={item.id} className={styles.subArticleTitle} style={{
            position: 'sticky',
            top: '12.7rem',
            padding: '5px 8px',
            fontSize: '20px',
            borderBottom: '1px solid lightgray',
            backgroundColor: 'white',
            zIndex: '5',
            fontWeight: 'normal'
        }} >
            {draftView && item.newText ?
                ReactHtmlParser(item.newText)
                :
                ReactHtmlParser(item.text)
            }

            {!draftView && <EditButton item={item} />}
        </h4>
    </>
}