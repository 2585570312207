import React from "react";
import styles from './Sidebar.module.scss'
import { EditButton } from "../EditButton/EditButton";
import ReactHtmlParser from 'react-html-parser';

export const Type14 = ({ item, draftView }) => {
    return <>
        <h4 id={item.id} className={styles.subArticleTitle2} style={{
            // position: 'sticky',
            // top: '12.7rem',
            // padding: '5px 8px',
            // fontSize: '20px',
            // borderBottom: '1px solid lightgray',
            // backgroundColor: 'white',
            // zIndex: '5',
            // fontWeight: 'normal'
        }}>
            <span className={styles.subArticleDescriptionLetter} style={{
                marginLeft: '55px',
                fontWeight: 'normal',
            }}>{item.number > 0 && item.number}. </span>


            <span style={{
                marginLeft: '15px',
            }}>
                {draftView && item.newText ?
                    ReactHtmlParser(item.newText)
                    :
                    ReactHtmlParser(item.text)
                }
            </span>

            {!draftView && <EditButton item={item} />}
        </h4>


    </>
}