import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { LoadingScreen } from '../../components/LoadingComponent/LoadingSpinner';
import classNames from 'classnames';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { ArticleTransition } from '../../components/ArticleTransition/ArticleTransition';

 
export const DraftView = ({ versionNumber = null, removeSaveButton = false }) => {
    const { t, i18n } = useTranslation();

    const [articles, setArticles] = useState(null)
    const [loading, setLoading] = useState(true)
    // const navigate = useNavigate();

    useEffect(() => {
        axios.get(`documents/content/articles${versionNumber ? `/${versionNumber}` : ''}?language=${i18n.language}`).then(res => {
            // setDocumentStructure(res.data.result)
            setArticles(res.data.result)
            setLoading(false)
        })
    }, [])

    const saveFinalDocumentVersion = () => {
        setLoading(true)
        axios.get(`documents-save-changes?language=${i18n.language}`).then((res) => { 
            toast.success(t('saved successfully'), { theme: "colored" })
            setLoading(false)
        })
    }

    useEffect(()=>{
        if(articles){
            axios.get(`documents/content/articles${versionNumber ? `/${versionNumber}` : ''}?language=${i18n.language}`).then(res => {
                // setDocumentStructure(res.data.result)
                setArticles(res.data.result)
                setLoading(false)
            })
        }
    },[i18n.language])

    return <>
        {loading ? <>
            <LoadingScreen />
        </> :
            <div className={'container p-0 d-flex flex-column align-items-center'}>
                <ToastContainer />
                {!removeSaveButton &&
                    <Button className='m-3' onClick={saveFinalDocumentVersion}>{t('save final')}</Button>
                }
                <div className={classNames('col-12 col-md-11 p-2 ')} >
                    {articles &&
                        articles.sort((a, b) => a.number - b.number).map((contentItem, index) => {
                            return <ArticleTransition key={index} item={contentItem} />
                        })
                    }

                </div>
            </div>
        }

    </>
}

