import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux';
import { login } from '../../redux/userSlice'
import axios from 'axios';
import { saveToken } from '../../services/tokenService';


export const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const [person, setPerson] = useState({ email: '', password: '' })

    const onSubmit = (e) => {
        e.preventDefault()

        if (person.email == '') {
            toast.error(t('please write your email address'), { theme: "colored" })

        }
        if (person.password == '') {
            toast.error(t('please write your password'), { theme: "colored" }) 
        }

        axios.post('users/login', person).then(res=>{
            toast.success(t('logged in'), { theme: "colored" })
            dispatch(login(res.data.result.accessToken))
            saveToken(res.data.result.accessToken)
            navigate('/')
        }).catch(err=>{
            toast.error(t('invalid credentials'))
        })
    }

    return (
        <div className='container p-0 d-flex flex-column align-items-center'>

            <h1 className='col-12 p-3 d-flex justify-content-center'>{t('login')}</h1>
            <ToastContainer />
            <form onSubmit={onSubmit} className="d-flex flex-column align-items-center col-12">

                <div className="form-outline p-3 col-12 col-md-4">
                    <label>{t('email address')}</label>
                    <input value={person.email} onChange={e => setPerson({ ...person, email: e.target.value.toLowerCase() })} type="email" className="form-control" />
                </div>

                <div className="form-outline p-3 col-12 col-md-4">
                    <label>{t('password')}</label>
                    <input value={person.password} onChange={e => setPerson({ ...person, password: e.target.value })} type="password" className="form-control" />
                </div>


                <div className="col-12 col-md-4 p-3 d-flex justify-content-center">
                </div>


                <div className="row m-0 p-3 col-9 col-md-3">
                    <button type="submit" value="Submit" className="btn btn-primary btn-block">{t('sign in')}</button>
                </div>

            </form>
            
        </div>
    )
}
