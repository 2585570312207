import React from "react";
import { Navbar } from "react-bootstrap";
import styles from './Header.module.scss'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from 'react-toastify';

import { SearchBar } from '../SearchBar/SearchBar';
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import className from 'classnames'
import { logout } from "../../redux/userSlice";

import classNames from "classnames";
import { currentLanguage, setDocument } from "../../redux/documentSlice";



export const Header = () => {

    const authenticated = useSelector((state) => state.user.token)

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation();
    const changeLanguageHandler = (lang) => {
        i18n.changeLanguage(lang)
        dispatch(currentLanguage(lang))
        dispatch(setDocument(null))
    }
    return <>
        <ToastContainer />
        <Navbar className={styles.header} expand="md">
            <div className={'container p-0'} >
                <div className={className(styles.logoImage, 'col-12 col-md-3 p-3')}>
                    <Link to="/" className={styles.mainTitleLink}>
                        <h5 className={classNames(styles.legalCommentaryText, "mb-1")}>{t('legal commentary')}</h5>
                    </Link>
                </div>
                <div className={styles.links + ' col-12 col-md-9 p-2 '}>
                    <div className="d-flex flex-column">
                        <div className={styles.mainLinks}>
                            <Link className={styles.link} to='/content' >{t('contents')}</Link>
                            <Link className={styles.link} to='/authors'>{t('authors')}</Link>
                            <Link className={styles.link} to='/bibliography'>{t('bibliography')}</Link>
                            <Link className={styles.link} to='/content/history'>
                                {t('previous versions')}
                            </Link>
                        </div>
                        <div className="d-flex ">
                            {authenticated &&
                                <>
                                    <Link to='/content/comments'>
                                        <button type="button" className="btn btn-primary ">{t('comments')}</button>
                                    </Link>
                                    <Link to='/content/questions'>
                                        <button type="button" className="btn btn-primary mx-2">{t('questions')}</button>
                                    </Link>
                                    <Link to='/content/draft-version'>
                                        <button type="button" className="btn btn-primary">{t('see draft version')}</button>
                                    </Link>
                                    <Link to='/visitors'>
                                        <button type="button" className="btn btn-primary mx-2">{t('visitors')}</button>
                                    </Link>


                                </>
                            }
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        {!authenticated &&
                            <>
                                <Link className={styles.link} to='/login'>{t('login')}</Link>
                            </>
                        }

                        {authenticated &&
                            <>
                                <Link className={styles.link} to='/' onClick={() => dispatch(logout())}>{t('logout')}</Link>
                                <Link className={styles.link} to='/register'>{t('register')}</Link>
                            </>
                        }

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {t(i18n.language)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => changeLanguageHandler('al')}>{t('al')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguageHandler('en')}>{t('en')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguageHandler('sr')}>{t('sr')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.links}>

                </div>
            </div>


        </Navbar>


        <SearchBar />

    </>
}