import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import convertToRoman from "../../components/ContentSidebar/helpers/romanNumberConverter";
import { LoadingScreen } from "../../components/LoadingComponent/LoadingSpinner";
import styles from './Content.module.scss'

const STYLE_TYPES = {
    1: styles.ChapterLink,
    2: styles.ArticleLink,
    8: styles.DivisionLink,
    11: styles.PartLink
}

const NAMINGS = {
    1: 'chapter',
    2: 'article-abbreviation',
    8: 'division',
    11: 'partCapitalized'
}

export const Content = () => {

    const { t, i18n } = useTranslation();
    const documentStructureFromRedux = useSelector(state => state.document.structure)
    const documentLanguage = useSelector(state => state.document.language)
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language)

    // let documentArray = [...documentStructureFromRedux] 
    const [sortedDocs, setSortedDocs] = useState(null)
    // const [loading, setLoading] = useState(true)

    const setSortedDocumentRefresh = () => {
        const documentArray = [...documentStructureFromRedux]
        setSortedDocs(documentArray?.sort((a, b) => (a.number > b.number) ? 1 : (a.number === b.number) ? ((a.number > b.number) ? 1 : -1) : -1))

    }

    useEffect(() => {
        if (documentStructureFromRedux && (!sortedDocs || currentLanguage !== i18n.language)) {
            setSortedDocumentRefresh()
        }
        if (currentLanguage !== i18n.language)
            setCurrentLanguage(i18n.language)
    }, [sortedDocs, documentStructureFromRedux, currentLanguage])

    useEffect(() => {
        if (documentStructureFromRedux)
            setSortedDocumentRefresh()
    }, [documentStructureFromRedux])

    const renderItem = (item) => {
        let children = [1, 2, 8, 11].includes(item.type) && item.children && item.children.length > 0 ? [...item.children] : undefined
        if ([1, 2, 8, 11].includes(item.type)) {

            return <h4 key={item.id} className={classNames(styles.contentWrapper, STYLE_TYPES[item.type])}>
                {t(NAMINGS[item.type])}
                <Link className={STYLE_TYPES[item.type]} to={item.type === 2 ? `/article?articleNumber=${item.number}` : '#'} >
                    {[11, 1].includes(item.type) ? convertToRoman(item.number) : ` ` + item.number}. {item.text}
                </Link>
                {children &&
                    children.sort((a, b) => a.number - b.number).map(renderItem)
                }
            </h4>
        }
        return
    }

    return (!documentStructureFromRedux || !sortedDocs || documentLanguage !== i18n.language) ?
        <>
            <LoadingScreen />
        </> :
        <>
            <h2 className="text-center">{t('commentary content')}</h2>
            <div className={styles.contentWrapper}>
                {sortedDocs.sort((a, b) => a.number - b.number).map(renderItem)}
            </div>
        </>



}