import React from "react";
import styles from './Sidebar.module.scss'
import { EditButton } from "../EditButton/EditButton";
import ReactHtmlParser from 'react-html-parser';



export const LawSection = ({ item, draftView }) => {

    // const handleCommentsModal = () => {
    //     dispatch(updateCommentsModal({ type: 'show', value: true }))
    //     dispatch(updateCommentsModal({ type: 'text', value: 'Article '+convertToRoman(item.number) }))
    //     dispatch(updateCommentsModal({ type: 'description', value: item.description }))
    // }

    // let lawNumber = item.number>0?item.number+'.':'' 

    return <div className={styles.lawSection} style={{ position: 'relative' }}>
        <p id={item.id} className={styles.redText} style={{
            color: 'red',
            border: '1px solid black',
            padding: '10px'
        }}>
            {draftView && item.newText ? ReactHtmlParser(item.newText) : ReactHtmlParser(item.text)}
        </p>
        {/* <BsChatDotsFill className={classNames(styles.commentIcon)} size={30} onClick={handleCommentsModal} /> */}
        {!draftView && <EditButton item={item} />}
    </div>
}