import React from "react";
import styles from './Sidebar.module.scss'
import classNames from 'classnames'

import { EditButton } from "../EditButton/EditButton";

export const Footnote = ({ item, onClick, draftView }) => {


    return <div id={item.id} className={styles.partTextContentPage} style={{
        textAlign: 'center',
        marginBottom: '30px',
    }}>
        <p>Lorem  Lorem ipsum dolor sit amet consectetur adipisicing.<span className={classNames(styles.toolkitText, styles.whitesmokeBackground)} data-tooltip={item.text}>*</span> ipsum dolor sit amet, consectetur adipisicing elit.</p>

        <EditButton item={item} />
    </div>
}