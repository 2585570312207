import { createSlice } from "@reduxjs/toolkit";

const PopUpModalSlice = createSlice({
  name: "modal",
  initialState: {
    show: false,
    text: "",
    oldText: "",
    description: "",
    oldDescription: "",
  },
  reducers: {
    //metode qe ndihmon ndryshim state
    updateModal: (state, action) => {
      state[action.payload.type] = action.payload.value;
      if (action.payload.type === "text") state.oldText = action.payload.value;
      if (action.payload.type === "description") state.oldDescription = action.payload.value;
    },
    updateNewText: (state, action) => {
      state.text = action.payload;
    },
    updateNewDescription: (state, action) => {
      state.description = action.payload;
    },
  },
});

export const { updateModal, updateNewText, updateNewDescription } = PopUpModalSlice.actions;
export default PopUpModalSlice.reducer;
