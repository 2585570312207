import React from "react";
import styles from './Sidebar.module.scss'
import { EditButton } from "../EditButton/EditButton";
import ReactHtmlParser from 'react-html-parser';
import convertToRoman from "./helpers/romanNumberConverter";

export const Type15 = ({ item, draftView }) => {
    return <>
        <div id={item.id} className={styles.subArticleTitle4} style={{
            display: 'flex'
            // position: 'sticky',
            // top: '12.7rem',
            // padding: '5px 8px',
            // fontSize: '20px',
            // borderBottom: '1px solid lightgray',
            // backgroundColor: 'white',
            // zIndex: '5',
            // fontWeight: 'normal'
        }}>
            <p className={styles.subArticleDescriptionLetter} style={{
                marginLeft: '55px',
                marginRight: '5px',
                fontWeight: 'normal',
            }}>{item.number > 0 && convertToRoman(item.number).toLowerCase()}) </p>


            <p>
                {draftView && item.newText ?
                    ReactHtmlParser(item.newText)
                    :
                    ReactHtmlParser(item.text)
                }
            </p>

            {!draftView && <EditButton item={item} />}
        </div>


    </>
}