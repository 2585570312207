import { createSlice } from "@reduxjs/toolkit";

const CommentPopUpModalSlice = createSlice({
  name: "modal",
  initialState: {
    // show: false,
    // e bana true qitu veq me kqyr a qelet si te hapet faqja po spo bon
    show: false,
    text: "",
    oldText: "",
    description: "",
    oldDescription: "",
  }, 
  reducers: {
    //metode qe ndihmon ndryshim state

    updateCommentsModal: (state, action) => {
      state[action.payload.type] = action.payload.value;
      if (action.payload.type == "text") state.oldText = action.payload.value;
      if (action.payload.type == "description") state.oldDescription = action.payload.value;
    },

  },
});

export const { updateCommentsModal } = CommentPopUpModalSlice.actions;
export default CommentPopUpModalSlice.reducer;
