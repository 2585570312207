import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from 'react-icons/fa'
import { updateModal } from "../../redux/popUpModalSlice";
import styles from './EditButton.module.scss'

export const EditButton=({item})=>{

    const dispatch = useDispatch()
    const authenticated = useSelector((state) => state.user.token)

    const handleShow = () => {
        dispatch(updateModal({ type: 'show', value: true }))
        dispatch(updateModal({ type: 'text', value: item.text }))
        dispatch(updateModal({ type: 'description', value: item.description }))
        dispatch(updateModal({ type: 'documentId', value: item.id }))
        dispatch(updateModal({ type: 'type', value: item.type }))
        dispatch(updateModal({ type: 'number', value: item.number }))
        dispatch(updateModal({ type: 'children', value: item.children }))
    }
    
    return <>
        {authenticated &&
            <FaEdit onClick={handleShow} className={styles.editButton} />
        }
    </>
}