import React, { useState } from "react";
import styles from './Sidebar.module.scss'
import { useTranslation } from 'react-i18next';
import classNames from 'classnames'
import convertToRoman from "./helpers/romanNumberConverter";
import { Col, Collapse, ListGroup, Row } from "react-bootstrap";
import { ArticleSideBar } from "./ArticleSideBar";
import { DivisionSidebar } from "./DivisionSidebar";

export const ChapterSideBar = ({ item, onClick }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true)

  let children = item.children ? [...item.children] : undefined

  return <div >
    <span onClick={() => setOpen(!open)} className={classNames(styles.chapter, styles.chapterSideBar) }>
      <h4 >
        {t('chapter')} {convertToRoman(item.number)} <span className={styles.chapterText}>{item.text}</span>
      </h4>
    </span>
    {children &&
      <Collapse in={open}>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <ListGroup>
              {children.sort((a,b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0)).map((itemToShow, index) => {
                return itemToShow.type == 8 ?
                <DivisionSidebar key={index} item={itemToShow} /> 
                :<ArticleSideBar key={index} item={itemToShow} />
              })}
            </ListGroup>
          </Col>
        </Row>
      </Collapse>
    }

    {/* <i className="bi bi-arrow-right-circle"></i> */}

  </div>
}