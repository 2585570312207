import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux'
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import UserSlice from "./userSlice";
import PerdoruesSlice from "./perdorues";
import PopUpModalSlice from "./popUpModalSlice";
import DocumentSlice from "./documentSlice";
import SidebarSlice from "./sidebarSlice";
import CommentPopUpModalSlice from "./commentPopUpModalSlice";

const persistConfig = {
  key: "lgap-web",
  storage,
};

const reducers = combineReducers({
  user: UserSlice,
  perdorues: PerdoruesSlice,
  // popUpModal: PopUpModalSlice.apply,
  popUpModal: PopUpModalSlice,
  commentPopUpModal: CommentPopUpModalSlice,
  document: DocumentSlice,
  sidebar: SidebarSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers );


export const store = configureStore({
  reducer: persistedReducer ,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});