import React from "react";
import styles from './Sidebar.module.scss'
import { useTranslation } from 'react-i18next';

import convertToRoman from "./helpers/romanNumberConverter";
import ReactHtmlParser from 'react-html-parser';
import { EditButton } from "../EditButton/EditButton";

export const ContentPageChapter = ({ item, onClick, draftView }) => {
    const { t } = useTranslation();

    return <>
        <h2 className={styles.chapterTitle} id={item.id} >{`${t('chapter')}  ${convertToRoman(item.number)}`}</h2>
        <div className={styles.chapterTextContentPage} style={{ textAlign: 'center', fontStyle: 'italic' }}>
            <h2>
                {draftView && item.newText ?
                    ReactHtmlParser(item.newText)
                    :
                    ReactHtmlParser(item.text)
                }

                {!draftView && <EditButton item={item} />}
            </h2>
        </div>
    </>
}