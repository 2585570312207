import axios from "axios";
import classNames from "classnames";
import React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import styles from './Style.module.scss'
import { LoadingScreen } from "../../components/LoadingComponent/LoadingSpinner";
import { Link } from "react-router-dom";

export const ApprovedComments = () => {
    const { t, i18n } = useTranslation();

    const [requestData, setRequestData] = useState(null)

    useEffect(() => {
        if (!requestData)
            axios.get('comments').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'comment' })))
            })
    }, [requestData])

    return <>{!requestData ?
        <LoadingScreen />
        :
        <div className='container col-md-8'>

            <ToastContainer />
            <div className={classNames(styles.links, 'mt-2')}>
                <Link className={styles.link} to='/content/comments'>
                    <Button>{t('go to pending comments')}</Button>
                </Link>
                <Link className={styles.link} to='/content/rejected/comments'>
                    <Button>{t('go to rejected comments')}</Button>
                </Link>
            </div>

            <h2 className='my-3'>{t('approved comments')}</h2>

            {
                requestData.filter(comment => comment.approved && comment.document.language == i18n.language).sort((a,b) => (a.document.number > b.document.number) ? 1 : ((b.document.number > a.document.number) ? -1 : 0)).map((comment) => {
                    return <div className={styles.file} >
                        <div className={styles.fileInfo}>
                            <i className={classNames(styles.commentIcon, "bi bi-chat-left-text")}></i>

                            <div className='d-flex flex-column'>
                                <div>
                                    <p className={styles.commentText}>{comment.text}</p>
                                </div>
                                <div>
                                    <p className={styles.commentText}>Article: {comment.document.number}</p>
                                </div>

                                <div className='d-flex flex-column'>
                                    <p className={classNames(styles.Button, styles.commentUsername)}>{t('by')}  {comment.userName}</p>
                                    <p className={styles.commentTimeCreated}>at {comment.createdAt.split("T")[0]} {comment.createdAt.split("T")[1].split('.')[0]}</p>
                                    <p className={styles.commentEmail}> {t('email')} {comment.email}</p>
                                    {comment.telephone &&
                                        <p> {t('phone')} {comment.telephone}</p>}

                                    {comment.occupation &&
                                        <p> {t('occupation')} {comment.occupation}</p>}

                                    {comment.socialMedia &&
                                        <p> {t('socialMedia')} {comment.socialMedia}</p>}


                                </div>

                            </div>

                            {comment.approved && <span className={styles.reply}>{('approved')}</span>}
                        </div>


                    </div>

                })
            }

            {
                requestData.filter(comment => comment.approved).length == 0 &&
                <p><em>{t('no approved comments')}</em></p>
            }

        </div>
    }
    </>
}