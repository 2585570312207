import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Authors.module.scss'

export const Authors = () => {
    const { t } = useTranslation()
    return (
        <div className='text-justify'>
            <div className={styles.lettersMenuDiv + ' p-2 col-md-6 container'}>
                {/* <p className={styles.lettersMenu}>A</p>
                <p className={styles.lettersMenu}>B</p>
                <p className={styles.lettersMenu}>D</p>
                <p className={styles.lettersMenu}>G</p> */}
            </div>

            <div className={'container p-0'}>
                <h4>{t('authors_page.t1')}</h4>
                <br />
                <p><b>Ulrich Ramsauer </b> {t('authors_page.t2')}</p>
                <p>{t('authors_page.t3')}</p>
                <p>{t('authors_page.t4')}</p>
                <p><b>Wolfgang Rusch </b> {t('authors_page.t5')}</p>
                <p>{t('authors_page.t6')}</p>
                <p><b></b>{t('authors_page.t7')}</p>
                <p><b>Zhani Shapo </b>{t('authors_page.t8')} </p>
                <p>{t('authors_page.t9')}</p>
                <p>{t('authors_page.t10')}</p>
                <p>{t('authors_page.t11')}</p>

                <h4>{t('authors_page.t12')}</h4>
                <p>{t('authors_page.t13')}</p>
            </div>



        </div>

    )
} 