import axios from "axios";
import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import styles from './Reply.module.scss'

export const Reply = ({ reply, refresh }) => {
    const { t } = useTranslation();

    const [editing, setEditing]= useState(false)
    const [questionText, setQuestionText]= useState('')
    useEffect(()=>{

    },[])
    const save=(replyid)=>{
        axios.put('reply/'+replyid,{
            text:questionText
        }).then(()=>{
            setQuestionText('')
            setEditing(false)
            refresh()
        })
    }
    return <>
        <Col sm={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} lg={{ offset: 1, size: 10 }} className={classNames(styles.file, styles.repliedText, 'justify-content-start col-12')}>
            <i className={classNames(styles.commentIcon, "bi bi-question-square")}></i>
            <div className='d-flex flex-column align-items-start'>
                {!editing &&
                    <h5 className={styles.paragraph}>{reply.text}</h5>
                }
                {editing &&
                    <div className="d-flex">
                        <textarea name="" id="" className='form-control' cols="100" rows="1" value={questionText} onChange={e => setQuestionText(e.target.value)}></textarea>
                        <Button onClick={() => save(reply.id)} variant="primary" className="ml-2">Save</Button>
                    </div>
                }
                <p className={styles.commentUsername}><b>By:</b>{reply.userName} {t('or system admin')}</p>
                <p className={classNames(styles.paragraph, styles.commentTimeCreated)} ><b>at:</b>{reply.createdAt.split("T")[0]} {reply.createdAt.split("T")[1].split('.')[0]}</p>
                <FaEdit onClick={() => { setQuestionText(reply.text); setEditing(true) }} />
            </div>
        </Col>
    </>
}