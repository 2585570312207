import React from "react";
import { useSelector } from "react-redux";
import { FaPlus, FaMinus } from 'react-icons/fa'
import styles from './AddButton.module.scss'
import { useState } from "react";
import { Col, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import axios from "axios";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from "react-i18next";

export const AddButton = ({ itemId, path, updateField, footnoteNumber = undefined }) => {
    const { t } = useTranslation();

    const authenticated = useSelector((state) => state.user.token)
    const [showSection, setShowSection] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const handleShow = () => setShowSection(true)

    const submitText = (e) => {
        e.preventDefault();
        if(draftToHtml(convertToRaw(editorState?.getCurrentContent())) === '<p></p>') return
        axios.post(path + itemId, {
            text: draftToHtml(convertToRaw(editorState?.getCurrentContent())),
            number: footnoteNumber
        }).then(() => {
            updateField()
            setEditorState(EditorState.createEmpty())
        })
    }

    const handleChange =(data)=>{
        setEditorState(data)
    }


    return <>
        {authenticated && !showSection &&
            <FaPlus color="green" onClick={handleShow} className={styles.editButton} />
        }

        {authenticated && showSection &&
            <FaMinus onClick={() => {
                setShowSection(false)
            }} className={styles.editButton} />
        }


        {showSection &&
            <Form onSubmit={submitText}>
                <FormGroup>
                    <FormLabel>Add text:</FormLabel>
                    <Editor wrapperClassName="border my-2" editorClassName='px-2' editorState={editorState} onEditorStateChange={handleChange} />
                    
                    <Col md={1}>
                        <FormControl type="submit" className='btn-primary' value={t('save')} />
                    </Col>
                </FormGroup>
            </Form>
        }
    </>
}