import React, { useState } from "react";
import styles from './Sidebar.module.scss'
import { useTranslation } from 'react-i18next';

import convertToRoman from "./helpers/romanNumberConverter";
import { Col, Collapse, ListGroup, Row } from "react-bootstrap";
import { ChapterSideBar } from "./ChapterSideBar";
import { ArticleSideBar } from "./ArticleSideBar";

const CHILDREN_TYPES={
  2: ArticleSideBar,
  1: ChapterSideBar
}

export const PartSideBar = ({ item, onClick }) => {

  const showChildren=(item, index)=>{
    const Component = CHILDREN_TYPES[item.type]

    return <Component key={index} item={item} />
  }
  
  const { t } = useTranslation();


  const [open, setOpen] = useState(false)

  let children = item.children ? [...item.children] : undefined

  return <div className={styles.chapterListSidebar}>
    <span onClick={() => setOpen(!open)} className={styles.chapter}>
      <h4 >
        {t('part')} {convertToRoman(item.number)} <span className={styles.chapterText}>{item.text}</span> 
      </h4>
    </span>
    {children &&
      <Collapse in={open}>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <ListGroup>
              {children.sort((a,b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0)).map((child, index) => {
                return showChildren(child, index)
              })}
            </ListGroup>
          </Col>
        </Row>
      </Collapse>
    }

    {/* <i className="bi bi-arrow-right-circle"></i> */}

  </div>
}