import React from 'react'
import classNames from 'classnames'
import { Button, Modal } from 'react-bootstrap'
import styles from './PendingRequests.module.scss'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { LoadingScreen } from '../../components/LoadingComponent/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'

const PendingQuestions = () => {
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false)
    const [rejectionShow, setRejectionShow] = useState(false)

    const [selectedRequest, setSelectedRequested] = useState(null)
    const [selectedQuestion, setSelectedQuestion] = useState(null)
    const [reply, setReply] = useState('')
    const [reason, setReason] = useState(null)
    const [documentRelated, setDocumentRelated] = useState(null)

    const showModal = (request) => {
        setShow(true)
        setSelectedRequested(request)
    }

    const hideModal = () => {
        setShow(false)
    }

    const hideRejectionModal = () => {
        setRejectionShow(false)
        hideModal()
        setSelectedRequested(null)
    }

    const [requestData, setRequestData] = useState(null)

    const setSelectedReason = (e) => {
        e.preventDefault();
        setReason(e.target.value)
    }

    useEffect(() => {
        if (selectedRequest && selectedRequest.documentId) {
            axios.get(`documents/${selectedRequest.documentId}?language=${i18n.language}`).then(res => {
                setDocumentRelated(res.data.result)
            })
        }
    }, [selectedRequest])

    useEffect(() => {
        if (!requestData)
            axios.get('questions').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'question' })))
            })
    }, [requestData])

    const replyQuestion = () => {
        if (!reply) {
            toast.error(t('please provide a reply!'))
            return
        }

        axios.post(`replies/${selectedQuestion}`, {
            userName: '',
            email: '',
            text: reply
        }).then(res => {
            toast.success(t('replied to question'), { theme: "colored" })
            setShow(false)
            axios.get('questions').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'question' })))
            })
        }).catch(err => {
            toast.error(t('server error, please try again!'))
        })
    }

    const rejectQuestion = () => {
        if (!reason) {
            toast.error(t('please select a reason first!'))
            return
        }
        let data = {
            reason: parseInt(reason)
        }
        axios.post(`questions/${selectedQuestion}/reject`, data).then(res => {
            toast.success(t('question rejected'), { theme: "colored" })
            setShow(false)
            setReason(null)
            axios.get('questions').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'question' })))
            })
        }).catch(err => {
            toast.error(t('server error, please try again!'))
        })

        hideModal()
        setSelectedRequested(null)
    }

    const handleReply = (e) => {
        e.preventDefault();

        setReply(e.target.value)
    }

    return <>{!requestData ?
        <LoadingScreen />
        :
        <div className='container col-md-8'>
            <ToastContainer />
            <div className={classNames(styles.links, 'mt-2')}>
                <Link className={styles.link} to='/content/approved/questions'>
                    <Button >{t('go to approved questions')}</Button>
                </Link>
                <Link className={styles.link} to='/content/rejected/questions'>
                    <Button>{t('go to rejected questions')}</Button>
                </Link>
            </div>
            <h2 className='my-3'>{t('pending questions')}</h2>
            {
                requestData.filter(question => question.approved == null && question.document.language == i18n.language).sort((a,b) => (a.document.number > b.document.number) ? 1 : ((b.document.number > a.document.number) ? -1 : 0)).map((question) => {
                    return <div className={styles.file} onClick={event => {
                        setSelectedQuestion(question.id)
                        showModal(question)
                    }}>
                        <div className={styles.fileInfo}>
                            <i className={classNames(styles.commentIcon, "bi bi-question-square")}></i>

                            <div className='d-flex flex-column'>
                                <div>
                                    <p className={styles.commentText}>{question.text}</p>
                                </div>
                                <div>
                                    <p className={styles.commentText}>Article: {question.document.number}</p>
                                </div>

                                <div className='d-flex flex-column'>
                                    <p className={classNames(styles.Button, styles.commentUsername)}><b>by:</b> {question.userName}</p>
                                    <p className={styles.commentTimeCreated}><b>at:</b> {question.createdAt.split("T")[0]} {question.createdAt.split("T")[1].split('.')[0]}</p>
                                </div>
                            </div>
                            {question.replies?.length > 0 && <span className={styles.reply}>{t('replied')}</span>}

                            {question.approved && <span><b>{t('approved')}</b></span>}

                        </div>


                    </div>

                })
            }


            {
                requestData.filter(question => question.approved == null).length == 0 &&
                <p><em>{t('no pending question')}</em></p>
            }


            {selectedRequest &&
                <Modal show={show} onHide={hideModal}>
                    <Modal.Header>
                        <Modal.Title className={styles.modalTitleText}>
                            <i className={classNames(styles.commentIcon, "bi bi-question-square-fill")} ></i>

                            {selectedRequest.type}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h5 className={styles.modalBodyText}>
                            {selectedRequest.text}
                        </h5>

                        {documentRelated &&
                            <h6><b>{t('for article')}:</b> {t('article-abbreviation')} {documentRelated.number}. {documentRelated.text}</h6>
                        }
                        <span>
                            <b>{t('at')}:</b> {selectedRequest.createdAt.split("T")[0]} {selectedRequest.createdAt.split("T")[1].split('.')[0]}

                            <br />
                        </span>
                        <span className={styles.italicFont}>
                            <b>{t('by')}:</b> {selectedRequest.userName} <br />
                            <b>{t('email')}:</b> {selectedRequest.email} <br />
                            {selectedRequest.telephone && <>
                                <b>{t('phone')}:</b> {selectedRequest.telephone}</>}
                            <br />
                            {selectedRequest.occupation && <>
                                <b>{t('occupation')}:</b> {selectedRequest.occupation}</>}
                            <br />
                            {selectedRequest.socialMediaLink && <>
                                <b>{t('socialMedia')}:</b> {selectedRequest.socialMediaLink}</>}
                            {!selectedRequest.showPersonalInfo &&
                                <p><em>{t('does not want to show personal info!')}</em></p>
                            }
                        </span>

                        {selectedRequest.type == 'question' &&
                            <>
                                <p className={styles.writeReply}>{t('write your reply below')}:</p>
                                <textarea name="reply" onChange={handleReply} className='form-control' id="" cols="45" rows="5" ></textarea>
                            </>
                        }



                    </Modal.Body>

                    <Modal.Footer>
                        <>
                            <Button variant="primary" onClick={replyQuestion}>
                                {t('reply')}
                            </Button>

                            <Button variant="danger" onClick={() => {
                                hideModal()
                                setRejectionShow(true)
                            }}>
                                {t('reject')}
                            </Button>
                            <Button variant="danger" onClick={hideModal}>
                                {t('cancel')}
                            </Button>
                        </>


                    </Modal.Footer>
                </Modal>

            }

            {/* //rejection modal */}
            {selectedRequest &&
                <Modal show={rejectionShow} onHide={hideModal}>
                    <Modal.Header>
                        <Modal.Title className={styles.modalTitleText}>
                            <i className={classNames(styles.commentIcon, "bi bi-question-square-fill")}></i>

                            {selectedRequest.type}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h5 className={styles.modalBodyText}>
                            {selectedRequest.text}
                        </h5>

                        <span>
                            At: {selectedRequest.createdAt} <br />
                        </span>
                        <span className={styles.italicFont}>
                            {t('by')}: {selectedRequest.userName} <br />
                            {t('email')}: {selectedRequest.email} <br />
                            {t('phone')}: {selectedRequest.telephone} <br />

                            {!selectedRequest.showPersonalInfo &&
                                <p><em>{t('does not want to show personal info!')}</em></p>
                            }
                        </span>

                        <>
                            <p className={classNames(styles.whiteBackgroundColor, 'mt-3 mb-0')} >{t('if you wish to reject, you have to select one of the options below')}:</p>
                            <div className="form-check">
                                <input className="form-check-input" onChange={setSelectedReason} value='0' type="radio" name="flexRadioDefault" />
                                <label className="form-check-label" >
                                    {t('bad language')}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={setSelectedReason} value='1' type="radio" name="flexRadioDefault" />
                                <label className="form-check-label" >
                                    {t('not understandable')}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={setSelectedReason} value='2' type="radio" name="flexRadioDefault" />
                                <label className="form-check-label">
                                    {t('not relevant')}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={setSelectedReason} value='3' type="radio" name="flexRadioDefault" />
                                <label className="form-check-label">
                                    {t('other')}
                                </label>
                            </div>

                        </>



                    </Modal.Body>

                    <Modal.Footer>
                        <>
                            <Button variant="primary" onClick={rejectQuestion}>
                                {t('reject')}
                            </Button>
                            <Button variant="danger" onClick={hideRejectionModal}>
                                {t('cancel')}
                            </Button>
                        </>


                    </Modal.Footer>
                </Modal>

            }

        </div>
    }
    </>
}

export default PendingQuestions
