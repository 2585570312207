import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import convertToRoman from "../../components/ContentSidebar/helpers/romanNumberConverter";
import styles from "./Search.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LoadingScreen } from "../../components/LoadingComponent/LoadingSpinner";
import { LinkToArticle } from "../../components/Articles/LinkToArticle";
import HtmlParser from "react-html-parser";

export const SearchPage = () => {
  const [searchParams] = useSearchParams();

  const [showingItems, setShowingItems] = useState([]);

  const documentStructureFromRedux = useSelector(
    (state) => state.document.structure
  );

  const [loading, setLoading] = useState(true);

  const searchContent = (item, itemsArray) => {
    let searchText = searchParams.get("text");
    if (item?.text?.toLocaleLowerCase().match(searchText.toLocaleLowerCase())) {
      itemsArray.push(item);
    }

    if (item.children && item.children.length > 0) {
      for (var child of item.children.sort((a, b) =>
        a.number > b.number ? 1 : b.number > a.number ? -1 : 0
      ))
        itemsArray = searchContent(child, itemsArray);
    }

    return itemsArray;
  };

  useEffect(() => {
    if (!documentStructureFromRedux) return;
    let array = [];
    let searchText = searchParams.get("text");
    if (searchText != "") {
      for (var item of documentStructureFromRedux)
        array = searchContent(item, array);

      setShowingItems(array);
      setLoading(false);
    }
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <>
      {loading ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <Container className="p-4">
          <h1>{t("search results appear below")}</h1>

          <hr />
          {showingItems.map((item, index) => {
            return (
              <div key={index} className={styles.file}>
                <div className={styles.fileInfo}>
                  <div className="d-flex flex-column">
                    <div>
                      <p className={styles.searchResults}>
                        {" "}
                        {[1, 11].includes(item.type)
                          ? convertToRoman(item.number)
                          : item.number}
                        . {HtmlParser(item.text)}
                      </p>
                    </div>
                    <LinkToArticle item={item} />
                  </div>
                </div>
              </div>
            );
          })}

          {showingItems.length == 0 && (
            <p className={styles.searchResults}>{t("no search results")}</p>
          )}
        </Container>
      )}
    </>
  );
};
