import React from 'react'
import classNames from 'classnames'
import { Button, Modal } from 'react-bootstrap'
import styles from './PendingRequests.module.scss'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { LoadingScreen } from '../../components/LoadingComponent/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'

const PendingRequests = () => {
    const { t, i18n } = useTranslation();

    const [show, setShow] = useState(false)
    const [reason, setReason] = useState(null)
    const [selectedComment, setSelectedComment] = useState(null)

    const [selectedRequest, setSelectedRequested] = useState(null)

    const [documentRelated, setDocumentRelated] = useState(null)

    const showModal = (request) => {
        setShow(true)
        setSelectedRequested(request)
    }

    const hideModal = () => {
        setShow(false)
        setSelectedRequested(null)
        setDocumentRelated(null)
    }

    const [requestData, setRequestData] = useState(null)

    useEffect(() => {
        if (!requestData)
            axios.get('comments').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'comment' })))
            })
    }, [requestData])

    const setSelectedReason = (e) => {
        e.preventDefault();
        setReason(e.target.value)
    }
    useEffect(() => {
        if (selectedRequest && selectedRequest.documentId) {
            axios.get(`documents/${selectedRequest.documentId}?language=${i18n.language}`).then(res => {
                setDocumentRelated(res.data.result)
            })
        }
    }, [selectedRequest])

    const approveComment = () => {
        axios.get(`comments/${selectedComment}/approve`).then(res => {
            toast.success(t('comment approved'), { theme: "colored" })
            setShow(false)
            axios.get('comments').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'comment' })))
            })
        }).catch(err => {
            toast.error(t('server error, please try again!'))
        })
    }

    const rejectComment = () => {
        if (!reason) {
            toast.error(t('please select a reason first!'))
            return
        }
        axios.post(`comments/${selectedComment}/reject`, { reason: parseInt(reason) }).then(res => {
            toast.success(t('comment approved'), { theme: "colored" })
            setShow(false)
            setReason(null)
            axios.get('comments').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'comment' })))
            })
        }).catch(err => {
            toast.error(t('server error, please try again!'))
        })
    }

    const deleteComment = (id) => {
        axios.delete(`comments/${selectedComment}`).then(res => {
            toast.success(t('comment deleted'), { theme: "colored" })
            axios.get('comments').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'comment' })))
            })
        }).catch(err => {
            toast.error(t('server error, please try again!'))
        })
    }

    return <>{!requestData ?
        <LoadingScreen />
        :
        <div className='container col-md-8'>

            <ToastContainer />
            <div className={classNames(styles.links, 'mt-2')}>
                <Link className={styles.link} to='/content/approved/comments'>
                    <Button>{t('go to approved comments')}</Button>
                </Link>
                <Link className={styles.link} to='/content/rejected/comments'>
                    <Button>{t('go to rejected comments')}</Button>
                </Link>
            </div>
            <h2 className='my-3'>{t('pending comments')}</h2>

            {
                requestData.filter(comment => comment.approved == null && comment.document.language == i18n.language).sort((a,b) => (a.document.number > b.document.number) ? 1 : ((b.document.number > a.document.number) ? -1 : 0)).map((comment) => {
                    return <div className={styles.file} onClick={event => {
                        setSelectedComment(comment.id)
                        showModal(comment)
                    }
                    }>
                        <div className={styles.fileInfo}>
                            <i className={classNames(styles.commentIcon, "bi bi-chat-left-text")}></i>


                            <div className='d-flex flex-column'>
                                <div>
                                    <p className={styles.commentText}>{comment.text}</p>
                                </div>
                                <div>
                                    <p className={styles.commentText}>Article: {comment.document.number}</p>
                                </div>


                                <div className='d-flex'>
                                    <p className={classNames(styles.Button, styles.commentUsername)}>by {comment.userName}</p>
                                    <p className={styles.commentTimeCreated}>{t('at')}:{comment.createdAt.split("T")[0]} {comment.createdAt.split("T")[1].split('.')[0]}</p>
                                </div>

                            </div>

                            {comment.approved && <span className={styles.reply}>{t('approved')}</span>}
                        </div>


                    </div>

                })
            }
            {
                requestData.filter(comment => comment.approved == null).length == 0 &&
                <p><em>{t('no pending requests for comments')}</em></p>
            }



            {selectedRequest &&
                <Modal show={show} onHide={hideModal}>
                    <Modal.Header>
                        <Modal.Title className={styles.modalTitleText}>
                            <i className={classNames(styles.commentIcon, "bi bi-chat-left-text-fill")}></i>

                            {selectedRequest.type}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h5 className={styles.modalBodyText}>
                            {selectedRequest.text}
                        </h5>
                        {documentRelated &&
                            <h6><b>{t('for article')}:</b> {t('article-abbreviation')} {documentRelated.number}. {documentRelated.text}</h6>
                        }
                        <span>
                            <b>{t('at')}:</b>{selectedRequest.createdAt.split("T")[0]} {selectedRequest.createdAt.split("T")[1].split('.')[0]}
                            <br />
                        </span>
                        <span className={styles.italicFont}>
                            <b>{t('by')}:</b> {selectedRequest.userName} <br />
                            <b>{t('email')}:</b> {selectedRequest.email} <br />


                            {selectedRequest.telephone && <>
                                <b>{t('phone')}:</b> {selectedRequest.telephone}</>}
                            <br />
                            {selectedRequest.occupation && <>
                                <b>{t('occupation')}:</b> {selectedRequest.occupation}</>}
                            <br />
                            {selectedRequest.socialMediaLink && <>
                                <b>{t('socialMedia')}:</b> {selectedRequest.socialMediaLink}</>}
                            {/* //check again for this one  */}

                            {!selectedRequest.showPersonalInfo &&
                                <p><em>{t('does not want to show personal info!')}</em></p>
                            }
                        </span>

                        <>
                            <p className={classNames(styles.whiteBackgroundColor, 'mt-3 mb-0')} >{t('if you wish to reject, you have to select one of the options below')}:</p>
                            <div className="form-check">
                                <input className="form-check-input" onChange={setSelectedReason} value='0' type="radio" name="flexRadioDefault" />
                                <label className="form-check-label" >
                                    {t('bad language')}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={setSelectedReason} value='1' type="radio" name="flexRadioDefault" />
                                <label className="form-check-label" >
                                    {t('not understandable')}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={setSelectedReason} value='2' type="radio" name="flexRadioDefault" />
                                <label className="form-check-label">
                                    {t('not relevant')}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={setSelectedReason} value='3' type="radio" name="flexRadioDefault" />
                                <label className="form-check-label">
                                    {t('other')}
                                </label>
                            </div>

                        </>
                    </Modal.Body>

                    <Modal.Footer>
                        <>
                            <Button variant="primary" onClick={approveComment}>
                                {t('approve')}
                            </Button>
                            <Button variant="danger" onClick={rejectComment}>
                                {t('reject')}
                            </Button>
                        </>

                    </Modal.Footer>
                </Modal>

            }

        </div>
    }
    </>
}

export default PendingRequests
