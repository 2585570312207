import axios from "axios";
import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import { Collapse, Spinner } from "react-bootstrap";
import { Article } from "../ContentSidebar/Article";
import { ContentPageChapter } from "../ContentSidebar/ContentPageChapter";
import { ContentWrapper } from "../ContentSidebar/ContentWrapper";
import { Part } from "../ContentSidebar/Part";
import styles from './ArticleTransition.module.scss'

export const ArticleTransition = ({ item }) => {
    const [openFirstPart, setOpenFirstPart] = useState(false)
    const [openSecondPart, setOpenSecondPart] = useState(false)
    const [loading, setLoading] = useState(false)
    const [articleWithChildren, setArticleWithChildren] = useState(null)

    const [showChapter, setShowChapter] = useState(null)
    const [showPart, setPart] = useState(null)

    const getChildren = () => {
        if (openFirstPart) {
            setOpenSecondPart(false)
            setOpenFirstPart(false)
        }
        else if (articleWithChildren != null) {
            setOpenFirstPart(true)
            setOpenSecondPart(true)
        }
        else {
            setLoading(true)
            axios.get(`documents/article/${item.id}/children`).then(res => {
                setArticleWithChildren(res.data.result)
            })

            axios.get(`documents/${item.documentId}`).then(res => {
                setShowChapter(res.data.result)
                axios.get(`documents/${res.data.result.documentId}`).then(res => {
                    setPart(res.data.result)
                })
            })
        }
    }

    useEffect(() => {
        if (articleWithChildren !== null && showChapter !== null && showPart !== null) {
            setOpenFirstPart(true)
            setOpenSecondPart(true)
            setLoading(false)
        }
    }, [articleWithChildren, showChapter, showPart])

    return <>
        <Collapse in={openFirstPart}>
            <div>
                {showPart &&
                    <Part item={showPart} />
                }
                {showChapter &&
                    <ContentPageChapter item={showChapter} />
                }
            </div>
        </Collapse>

        <div className="position-relative" onClick={getChildren}>
            <Article item={item} draftView={true} />
            {loading && <Spinner className={classNames(styles.loadingElement, "position-absolute end-0")} animation="border" variant="info" />}
        </div>
        
        <Collapse in={openSecondPart}>
            <div>
                {articleWithChildren &&
                    articleWithChildren.children.map((contentItem, index) => {
                        return <ContentWrapper draftView={true} key={index} item={contentItem} />
                    })
                }
            </div>
        </Collapse>
    </>
}