import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { ArticleBibliographyTransition } from '../../components/ArticleTransition/ArticleBibliographyTransition';
import { LoadingScreen } from '../../components/LoadingComponent/LoadingSpinner';


export const Bibliography = () => {
    const { i18n } = useTranslation();

    const [articles, setArticles] = useState(null)
    const [loading, setLoading] = useState(true)
    // const navigate = useNavigate();

    useEffect(() => {
        axios.get(`documents/content/articles?language=${i18n.language}`).then(res => {
            setArticles(res.data.result)
            setLoading(false)
        })
    }, [])

    return <>
        {loading ? <>
            <LoadingScreen />
        </> :
            <div className={'container p-0 d-flex flex-column align-items-center'}>
                <div className={'col-12 col-md-11 p-2 '} >
                    {articles &&
                        articles.sort((a, b) => a.number - b.number).map((contentItem, index) => {
                            return <ArticleBibliographyTransition key={index} item={contentItem} />
                        })
                    }

                </div>
            </div>
        }

    </>
}

