import axios from "axios";
import React from "react";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const Captcha = ({ verified }) => {
    const captchaRef = useRef(null)

    const verify = async (e) => {
        const token = captchaRef.current.getValue()

        const data = await axios.post('catpcha/verify',{
            token
        })
        
        if(data.data.result.verified){
            verified()
        }
    }

    return <>
        <ReCAPTCHA 
            sitekey={'6LdCA6ciAAAAAOuP0H8D-y81myXkonEKhhsVcWNE'}
            ref={captchaRef}
            onChange={verify}
        />
    </>
}