import React from "react";
import styles from './Sidebar.module.scss'
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from "react-i18next";
import { EditButton } from "../EditButton/EditButton";


export const Division = ({ item, draftView }) => {

    const { t } = useTranslation();


    return <>
        <h2 className={styles.divisionTitle} id={item.id} >{`${t('division')} ${item.number > 0 && item.number}`}</h2>
        <div className={styles.divisionTextContentPage}>
            <h2>
                {draftView && item.newText ?
                    ReactHtmlParser(item.newText)
                    :
                    ReactHtmlParser(item.text)
                }

                {!draftView && <EditButton item={item} />}
            </h2>
        </div>
    </>
}