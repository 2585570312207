import axios from "axios";
import classNames from "classnames";
import React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import styles from './Style.module.scss'
import { LoadingScreen } from "../../components/LoadingComponent/LoadingSpinner";
import { Link } from "react-router-dom";

export const RejectedQuestions = () => {
    const { t, i18n } = useTranslation();

    const [requestData, setRequestData] = useState(null)

    useEffect(() => {
        if (!requestData)
            axios.get('questions').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'question' })))
            })
    }, [requestData])


    const deleteQuestion = (id) => {
        axios.delete(`questions/${id}`).then(res => {
            toast.success(t('question deleted'), { theme: "colored" })
            axios.get('questions').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'question' })))
            })
        }).catch(err => {
            toast.error(t('server error, please try again!'))
        })
    }



    return <>{!requestData ?
        <LoadingScreen />
        :
        <div className='container col-md-8'>
            <ToastContainer />
            <Link className={styles.link} to='/content/approved/questions'>
                <Button >{t('go to approved questions')}</Button>
            </Link>
            <Link className={styles.link} to='/content/questions'>
                <Button>{t('go to pending questions')}</Button>
            </Link>

            <h2 className='my-3'>{t('rejected questions')}</h2>

            {
                requestData.filter(question => question.approved == false && question.document.language == i18n.language).sort((a,b) => (a.document.number > b.document.number) ? 1 : ((b.document.number > a.document.number) ? -1 : 0)).map((question) => {
                    return <div className={styles.file}> 
                        <div className={styles.fileInfo}>
                            <i className={classNames(styles.commentIcon, "bi bi-chat-left-text")} ></i>

                            <div className='d-flex flex-column'>
                                <div>
                                    <p className={styles.commentText}>{question.text}</p>
                                </div>
                                <div>
                                    <p className={styles.commentText}>Article: {question.document.number}</p>
                                </div>

                                <div className='d-flex flex-column'>
                                    <p className={classNames(styles.Button, styles.commentUsername)} ><b>{t('by')}:</b>  {question.userName}</p>
                                    <p className={styles.commentTimeCreated}><b>at:</b> {question.createdAt.split("T")[0]} {question.createdAt.split("T")[1].split('.')[0]}</p>
                                    <p className={styles.commentEmail}> <b>{t('email')}:</b> {question.email}</p>
                                    {question.telephone &&
                                        <p> <b>{t('phone')}:</b> {question.telephone}</p>}

                                    {question.occupation &&
                                        <p> <b>{t('occupation')}:</b> {question.occupation}</p>}

                                    {question.socialMedia &&
                                        <p> <b>{t('socialMedia')}:</b> {question.socialMedia}</p>}


                                </div>

                            </div>


                        </div>
                        <Button variant='danger' onClick={() => deleteQuestion(question.id)}>{t('delete question')}</Button>

                    </div>

                })
            }

            {
                requestData.filter(question => question.approved == false).length == 0 &&
                <p><em>{t('no rejected requests for question')}</em></p>
            }

        </div>
    }
    </>
}
