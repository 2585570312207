import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'react-bootstrap';
import CommentSection from '../../components/CommentSection/CommentSection';
import { ContentWrapper } from '../../components/ContentSidebar/ContentWrapper';
import { LoadingScreen } from '../../components/LoadingComponent/LoadingSpinner';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CommentPopUpModal } from '../../components/PopUpModal/CommentPopUpModal';
import { useTranslation } from "react-i18next";
import styles from './ArticleView.module.scss'
import { ContentPageChapter } from '../../components/ContentSidebar/ContentPageChapter';
import { Part } from '../../components/ContentSidebar/Part';
import { CaseLaw } from '../../components/CaseLawSection/CaseLaw';
import { Bibliography } from '../../components/BibliographySection/Bibliography';
import { KosovoSpecificExample } from '../../components/KosovoSpecificExamplesSection/KosovoSpecificExample';
import { FootnotesSection } from '../../components/FootnotesSection/FootnotesSection';
import { setArticleId } from '../../redux/documentSlice';


export const ArticleView = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    
    const [searchParams, setSearchParams] = useSearchParams();
    // const [documentStructure, setDocumentStructure] = useState(null)
    const documentStructureFromReduxOriginal = useSelector(state=>state.document.structure)
    const documentStructureFromRedux = documentStructureFromReduxOriginal? [...documentStructureFromReduxOriginal]:null
    const [showDocumentPart, setShowDocumentPart] = useState(null)
    const [loading, setLoading] = useState(true)

    const [modalText, setModalText]= useState('')
    const [show, setShow]= useState(false)
    const [currentLanguage, setCurrentLanguage]= useState(i18n.language)

    const findNextOrPreviousArticle = (currentId, next) => {
        if(!currentId) return null
        let currentDocumentStructure = documentStructureFromRedux
        let articles = []
        if (currentDocumentStructure) {
            for (var item of currentDocumentStructure) {
                if (item.type == 2)
                    articles.push(item)
                else if (item.children) {
                    findAllArticles(item.children, articles)
                }
            }

        }
        let currentArticle = articles.filter(article => article.id === currentId)[0]

        if (!currentArticle || currentArticle?.number == articles.length)
            return ''
        let desiredNumber = next ? currentArticle.number + 1 : currentArticle.number - 1
        let nextArticle = articles.filter(article => article.number === desiredNumber)[0]

        return nextArticle?.number
    }

    const [showChapter, setShowChapter] = useState(null)
    const [showPart, setPart] = useState(null)

    useEffect(() => {
        if (showDocumentPart)
            axios.get(`documents/${showDocumentPart.documentId}?language=${i18n.language}`).then(res => {
                setShowChapter(res.data.result)
            })
    }, [showDocumentPart])


    useEffect(() => {
        if (showChapter)
            axios.get(`documents/${showChapter.documentId}?language=${i18n.language}`).then(res => {
                setPart(res.data.result)
            })
    }, [showChapter])

    const findAllMargins=(item, number)=>{
        if(!item) {
            return
        }
        if(item.type==4){
            number=number+1;
        }
        if(item.children && item.children.length > 0 )
            findAllMargins(item.children[0], number)
    }

    useEffect(() => {
        if (documentStructureFromRedux && searchParams.get('articleNumber') || currentLanguage !== i18n.language){
            setLoading(true)
            // window.scrollTo(0, 0)
            // axios.get(`article/number/${searchParams.get('articleNumber')}?language="${i18n.language}"`).then(res => {
                
            // })
            
            let currentDocumentStructure = documentStructureFromRedux
            let articles = []
            if (currentDocumentStructure) {
                for (var item of currentDocumentStructure) {
                    if (item.type == 2)
                        articles.push(item)
                    else if (item.children) {
                        findAllArticles(item.children, articles)
                    }
                }

            }
            let requestedArticle = articles.filter(article => article.number == searchParams.get('articleNumber'))[0]
            findAllMargins(requestedArticle?.children[0], 0)
            setShowDocumentPart(requestedArticle)
            dispatch(setArticleId({ id: requestedArticle?.id, number: requestedArticle?.number }))
            setLoading(false)
        }

        if(searchParams.get('footnote') && searchParams.get('footnote') !== footnoteId)
            setFootnoteId(searchParams.get('footnote'))

        if(currentLanguage !== i18n.language)
            setCurrentLanguage(i18n.language)
    }, [searchParams, currentLanguage, documentStructureFromReduxOriginal])

    const [marginId, setMarginId]= useState(null)
    const [footnoteId, setFootnoteId]= useState(null)

    const findChildId = (item, number) => {
        if ((item.type != 4 && item.children) || (item.number != number && item.children))
            item.children.forEach(itemChild => {
                findChildId(itemChild, number)
            })
        else if (item.number != number && !item.children)
            return null
        else if (item.number == number && item.type == 4){
            setMarginId(item.id) 
        }
            
    }

    useEffect(() => {
        if (marginId) {
            document.getElementById(marginId).scrollIntoView({
                behavior :'smooth',
                block :"center",
                inline :'center'
            })
            document.getElementById(marginId).style.backgroundColor = 'rgba(247, 211, 5, 0.3)'
        }
    }, [marginId])

    useEffect(() => {
        if (footnoteId) {
            document.getElementById(`footnote-${footnoteId}`)?.scrollIntoView({
                behavior :'smooth',
                block :"center",
                inline :'center'
            });
        }
    }, [footnoteId])

    useEffect(() => {
        if (documentStructureFromRedux) {
            if (documentStructureFromRedux && showDocumentPart && searchParams.get('marginNumber')) {
                findChildId(showDocumentPart, parseInt(searchParams.get('marginNumber')))
            }
        }
            
    }, [documentStructureFromRedux, showDocumentPart])

    const findAllArticles = (children, articles) => {
        for (var child of children) {
            if (child.type == 2)
                articles.push(child)
            else if (child.children) {
                findAllArticles(child.children, articles)
            }
        }
    }

    const showCommentPopUp = () => {
        setShow(true)
        setModalText('Article ' + showDocumentPart.number)
    }

    return <>
        {loading || !documentStructureFromRedux || !showDocumentPart ? <>
            <LoadingScreen />
        </> :
            <div className='container p-0 d-flex justify-content-end'>
                <div className={classNames('col-12 col-md-12')} >
                    <div>
                        {showDocumentPart?.id &&
                            <div className={classNames('d-flex justify-content-between')}>
                                <Link aria-disabled={!findNextOrPreviousArticle(showDocumentPart?.id, false)} to={`/article?articleNumber=${findNextOrPreviousArticle(showDocumentPart?.id, false)}`}>
                                    <Button className={styles.buttonsContentPage} disabled={!findNextOrPreviousArticle(showDocumentPart?.id, false)}>{t('previous article')}</Button>
                                </Link>
                                <Button className={styles.buttonsContentPage} onClick={showCommentPopUp}>
                                    {t('make question or comment')}
                                </Button>
                                <Link aria-disabled={!findNextOrPreviousArticle(showDocumentPart?.id, true)} to={`/article?articleNumber=${findNextOrPreviousArticle(showDocumentPart?.id, true)}`}>
                                    <Button className={styles.buttonsContentPage} disabled={!findNextOrPreviousArticle(showDocumentPart?.id, true)}>{t('next article')}</Button>
                                </Link>
                            </div>
                        }
                        {showPart &&
                            <Part item={showPart} />
                        }
                        {showChapter &&
                            <ContentPageChapter item={showChapter} />
                        }
                    </div>
                    {showDocumentPart &&
                    <div className='text-justify'>
                        <ContentWrapper item={showDocumentPart} />
                    </div>
                    }


                    {showDocumentPart?.id &&
                        <FootnotesSection documentNumber={showDocumentPart.number} documentId={showDocumentPart.id} />
                    }

                    {showDocumentPart?.id &&
                        <CaseLaw documentNumber={showDocumentPart.number} documentId={showDocumentPart.id} />
                    }
                    
                    {showDocumentPart?.id &&
                        <Bibliography documentNumber={showDocumentPart.number} documentId={showDocumentPart.id} />
                    }
                    {showDocumentPart?.id &&
                        <KosovoSpecificExample documentNumber={showDocumentPart.number} documentId={showDocumentPart.id} />
                    }
                    {showDocumentPart?.id &&
                        <CommentSection documentNumber={showDocumentPart.number} />
                    }


                </div>
                    <CommentPopUpModal modalText={modalText} show={show} onHide={()=>setShow(false)} documentId={showDocumentPart.id} />
                

            </div>
        }
    </>
}

