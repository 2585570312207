import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { LoadingScreen } from "../../components/LoadingComponent/LoadingSpinner";

export const Visitors = () => {

    const [allVisitors, setAllVisitors] = useState(null)
    const [uniqueVisitors, setUniqueVisitors] = useState(null)

    const getVisitors = async () => {

        axios.get('visitor/all').then(res => {
            setAllVisitors(res.data.result)
        })
        axios.get('visitor/all/unique').then(res => {
            setUniqueVisitors(res.data.result)

        })

    }

    useEffect(() => {
        if (!allVisitors) {
            getVisitors()
        }
    }, [allVisitors])

    return <Container className="my-5">
        {allVisitors && uniqueVisitors ?
            <Row>
                <Col sm={6}>
                    <h2>All visitors within a day</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Numri visitoreve</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allVisitors && allVisitors?.map((item, index) => {
                                return <tr>
                                    <th>{index + 1}</th>
                                    <th>{item.total}</th>
                                    <th>{item.date.split('T')[0]}</th>
                                </tr>
                            })}
                        </tbody>


                    </Table>
                </Col>
                <Col sm={6}>
                    <h2>All unique visitors within a day</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Numri visitoreve</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {uniqueVisitors && uniqueVisitors?.map((item, index) => {
                                return <tr>
                                    <th>{index + 1}</th>
                                    <th>{item.total}</th>
                                    <th>{item.date.split('T')[0]}</th>
                                </tr>
                            })}
                        </tbody>

                    </Table>
                </Col>
            </Row>

            :
            <LoadingScreen />
        }
    </Container>
}