import React from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import styles from './CustomInput.module.scss'

export const LabelInput = ({ label, value, onChange }) => {

    const handleChange = e => {
        return onChange(e.target.value)
    }

    return <>
        <FormGroup>
            {value && label &&
                <FormLabel className={styles.label}>{label}</FormLabel>
            }
            <FormControl onChange={handleChange}  value={value} placeholder={label} />
        </FormGroup>
    </>
}