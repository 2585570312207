import classNames from "classnames";
import React, { useState } from "react";
import { Col, Collapse, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ArticleSideBar } from "./ArticleSideBar";
import styles from './Sidebar.module.scss'

export const DivisionSidebar = ({item}) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(true)

    let children = item.children ? [...item.children] : undefined

    return <div >
        <span onClick={() => setOpen(!open)} className={classNames(styles.division, styles.divisionSideBar)}>
            <h4 >
                {t('division')} {item.number} <span className={styles.chapterText}>{item.text}</span>
            </h4>
        </span>
        {children &&
            <Collapse in={open}>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <ListGroup>
                            {children.sort((a, b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0)).map((article, index) => {
                                return <ArticleSideBar key={index} item={article} />
                            })}
                        </ListGroup>
                    </Col>
                </Row>
            </Collapse>
        }

        {/* <i className="bi bi-arrow-right-circle"></i> */}

    </div>
}