import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,

  paragrafi: `Both the public interests as well as the private interest are indeterminate legal terms . Both the public interests as well as the private interest are indeterminate legal terms . An "undetermined legal term" is understood to mean a word or phrase of a legal provision that from a linguistic point of view does not seem to have a clear content.  An "undetermined legal term" is understood to mean a word or phrase of a legal provision that from a linguistic point of view does not seem to have a clear content.  `
}

export const perdoruesSlice = createSlice({
  name: 'perdorues',
  initialState,
  reducers: {
    logIN: (state) => {
      state.value = true
    },
    logOUT: (state) => {
      state.value = false
    },



    //Paragrafi qitu osht temporary
    ndryshoParagrafi: (state, teksti) => {
      state.paragrafi = teksti
    }
  },
})

export const { logIN, logOUT} = perdoruesSlice.actions

export default perdoruesSlice.reducer