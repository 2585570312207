import React from "react";
import { Article } from "./Article";
import { ContentPageChapter } from "./ContentPageChapter";
import { Division } from "./Division";
import { LawSection } from "./LawSection";
import { Paragraph } from "./Paragraph";
import { SubArticle } from "./SubArticle";
import { SubArticleDescription } from "./SubArticleDescription";
import { SubArticleGrouper } from "./SubArticleGrouper";
import { SubParagraph } from "./SubParagraph";
import { Part } from "./Part";
import { SubsubParagraph } from "./SubsubParagraph";
import { Footnote } from "./Footnote";


import { Type13 } from './Type13'
import { Type14 } from './Type14'
import { Type15 } from './Type15'
import './ContentWrapper.css'

const CONTENT_TYPES = {
    1: ContentPageChapter,
    2: Article,
    3: SubArticle,
    4: Paragraph,
    5: SubParagraph,
    6: SubsubParagraph,
    7: LawSection,
    8: Division,
    9: SubArticleDescription,
    10: SubArticleGrouper,
    11: Part,
    12: Footnote,
    // 13: SubParagraph,//has to be changed to a) b)
    13: Type13,//has to be changed to a) b)
    // 14: SubParagraph,//has to be changed to 1. 2. 3. example search for: "Functions of the concept administrative act"
    14: Type14,//has to be changed to 1. 2. 3. example search for: "Functions of the concept administrative act"
    15: Type15,//has to be changed to i) ii) iii)
    // 15: SubParagraph,//has to be changed to i) ii) iii)
}

export const ContentWrapper = ({ item, draftView = false }) => {

    const getContentType = (type) => {
        if(item.text === '') return
        let Comp = CONTENT_TYPES[type]
        return Comp && <Comp item={item} draftView={draftView} />
    }


    return <>
        {getContentType(item.type)}
        {item.children && item.children.length > 0 && item.children.sort((a, b) => a.number - b.number).map((child, index) => {
            return <ContentWrapper draftView={draftView} key={index} item={child} />
        })
        }
    </>
}