import React, { useState } from "react";
import styles from './SearchBar.module.scss';
import className from 'classnames';

import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

import {GiHamburgerMenu} from 'react-icons/gi'

import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { toggleSidebarMobile } from "../../redux/sidebarSlice";


export const SearchBar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch=useDispatch()

    const [searchString, setSearchString] = useState('')
    const handleKeyUp = (event) => {
        var keyCode = event.keyCode//13 is enter
        var textSearched = event.target.value
        if (keyCode != 13) {
            setSearchString(textSearched)
        }
        else {
            handleSearch()
        }
    }

    const handleSearch = () => {
        if (searchString.trim() == ''){
            toast.error("You haven't searched anything", { theme: "colored" })
            navigate("/content")
        }
        else
            navigate(`/search?text=${searchString}`)
    }

    return <div className={styles.container}>
        <GiHamburgerMenu className={styles.mobileSideBarHamburger} size={35} onClick={()=>dispatch(toggleSidebarMobile())} />
        <div className={className(styles.relativeDiv, "col-9 col-md-6 p-2")}>

            <i className={className(styles.searchIcon, 'bi bi-search')} ></i>
            <button onClick={handleSearch} className={className(styles.searchButton, 'btn btn-light')}>{t('search')}</button>

            <input onKeyUp={handleKeyUp} type="text" className={className('col-12', styles.inputSearchBar, styles.inputPadding)}  placeholder={t('search')} />

        </div>
        <ToastContainer />

    </div>

}