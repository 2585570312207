import axios from "axios";
import React, { useState } from "react";
import { Modal, Button, FormControl, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import classNames from 'classnames'
import styles from './CommentPopUpModal.module.scss'
import { Captcha } from "../Captcha/Captcha";

const defaultState = {
    interactionType: 'comment',
    showPersonalInfo: true,
    userName: '',
    lastName: '',
    text: '',
    occupation: '',
    email: '',
    telephone: '',
    socialMediaLink: ''
}

export const CommentPopUpModal = ({ show, modalText, documentId, onHide }) => {
    const { t } = useTranslation();

    // const dispatch = useDispatch();
    // const modalText = useSelector(state => state.commentPopUpModal.text)
    // const show = useSelector(state => state.commentPopUpModal.show)

    const [data, setData] = useState(defaultState)
    const [verified, setVerified] = useState(false)

    const hideCommentsModal = () => {
        onHide()
        // dispatch(updateCommentsModal({ type: 'show', value: false }))
    }


    const handleData = (e) => {
        let tempData = data;
        tempData = {
            ...tempData,
            [e.target.name]: (e.target.value)
        }

        setData(tempData)
    }

    const handleCheck = (e) => {
        let tempData = data;
        tempData = {
            ...tempData,
            [e.target.name]: e.target.checked
        }

        setData(tempData)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!data.userName || !data.lastName || !data.email || !data.text) {
            toast.error(t('please check the fields'))
            return
        }

        let sentData = {
            ...data,
            documentId: documentId,
            userName: data.userName + ' ' + data.lastName
        }

        axios.post(`${sentData.interactionType}s/${documentId}`, sentData).then(res => {
            toast.success(t('submited successfully'), { theme: "colored" })
            onHide()
            setData(defaultState)
        }).catch(err => {
            toast.error(t('please check the fields'), { theme: "colored" })
        })
    }

    return <>
        <Modal show={show} onHide={onHide} >

            <ToastContainer />
            <form onSubmit={handleSubmit}>
                <Modal.Header>
                    <h5 className={styles.writeCommentQuestionText}>
                        {t('write a comment or question')}
                    </h5>
                </Modal.Header>

                <Modal.Body>
                    <em>{modalText}</em>

                    <div className="form-group">
                        <label>{t('select your type of interaction')}:</label>
                        <select className="form-control" name='interactionType' onChange={handleData}>
                            <option>{t('comment')}</option>
                            <option>{t('question')}</option>
                        </select>
                    </div>

                    <textarea name="text" id="" cols="40" rows="3" className='form-control my-3 col-12' onChange={handleData} placeholder={t('your comment/question...')}></textarea>

                    <div className='d-flex justify-content-between'>
                        <div>
                            <label>{t('first name')}*:</label>
                            <input type="text" className="form-control" name='userName' onChange={handleData} placeholder={`${t('your first name')}`}></input>
                        </div>
                        <div>
                            <label>{t('last name')}*:</label>
                            <input type="text" className="form-control" name='lastName' onChange={handleData} placeholder={t('your last name')}></input>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <label>{t('email address')}*:</label>
                            <input type="email" className="form-control" name='email' onChange={handleData} placeholder={t('your email')}></input>
                        </div>
                        <div>
                            <label>{t('phone number')}</label>
                            <input type="text" className="form-control" name='telephone' onChange={handleData} placeholder={`${t('your phone number')} (${t('optional')})`}></input>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between col-sm-12 col-md-12 col-lg-12'>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <label>{t('occupation')}:</label>
                            <input type="text" className="form-control" name='occupation' onChange={handleData} placeholder={`Your occupation (${t('optional')})`}></input>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <label>{t('social media link')}</label>
                            <input type="text" className="form-control" name='socialMediaLink' onChange={handleData} placeholder={`Your Social Media Link (${t('optional')})`}></input>
                        </div>
                    </div>

                    <div className='my-3'>
                        <input type="checkbox" className={classNames(styles.showPersonalInfo, "form-check-input")} checked={data.showPersonalInfo} name='showPersonalInfo' onChange={handleCheck} />
                        <label>{t('show my personal info')}</label>
                    </div>

                    <Captcha verified={()=>setVerified(true)} />

                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col>
                            <FormControl type="submit" className="btn btn-primary" disabled={!verified} value={t('submit')} />
                        </Col>
                        <Col>
                            <Button variant="danger" onClick={hideCommentsModal}>
                                {t('cancel')}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </form>
        </Modal>
    </>
}