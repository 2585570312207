import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AddButton } from "../AddButton/AddButton";
import styles from './KosovoSpecificExample.module.scss'
import ReactHtmlParser from 'react-html-parser';

export const KosovoSpecificExample = ({ documentNumber, documentId }) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false)

    const [kosovoExamples, setKosovoExamples] = useState(null);
    const [currentDoc, setCurrentDoc] = useState(documentNumber)

    useEffect(() => {
        if (!kosovoExamples || currentDoc !== documentNumber) {
            axios.get(`kosovo-specific-example/${documentNumber}?language=${i18n.language}`).then(res => {
                setKosovoExamples(res.data.result || [])
            })
        }

        if (currentDoc !== documentNumber)
            setCurrentDoc(documentNumber)
    },[kosovoExamples, documentNumber])
    return (
        <div className="card text-justify">
            <h4 className='card-header' onClick={() => setOpen(!open)}
                aria-controls="kosovo-examples-collapse-text"
                aria-expanded={open} >{t('kosovo examples section')} ({kosovoExamples?.length || 0})</h4>

            <Collapse in={open}>
                <div className="card-body" id="kosovo-examples-collapse-text">
                    {kosovoExamples &&
                        kosovoExamples.map((example, index) => {
                            return <Col key={index} sm={12} lg={8} className='d-flex flex-column align-items-end'>
                                <Col className={classNames(styles.comment, 'p-3 col-12')} >

                                    {/* <h5 className={styles.paragraph}> */}
                                        {ReactHtmlParser(example.text)}
                                    {/* </h5> */}

                                    <p className={classNames(styles.paragraph, styles.italicFont)}>{example.createdAt.split("T")[0]} {example.createdAt.split("T")[1].split('.')[0]}</p>
                                </Col>
                            </Col>
                        })
                    }
                    {
                        kosovoExamples &&
                        kosovoExamples.length == 0 &&
                        <p className={styles.paragraph}><em>{t('no kosovo examples yet')}</em></p>
                    }
                    <AddButton path={'kosovo-specific-example/'} itemId={documentId} updateField={() => setKosovoExamples(null)} />
                </div>
            </Collapse>

        </div>
    )
}