import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './ShowHistory.module.scss'
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { LoadingScreen } from '../../components/LoadingComponent/LoadingSpinner';
import { Link } from 'react-router-dom';

const ShowHistory = () => {

    const [versions, setVersions] = useState(null)
    const { t } = useTranslation();


    useEffect(() => {
        if (!versions)
            axios.get('documents-versions').then(res => setVersions(res.data.result))
    }, [versions])
    return <>{!versions ?
        <LoadingScreen />
        :
        <div className='container col-md-8'>

            <h2 className='my-3'>{t('history')}</h2>

            {
                versions.map((version) => {
                    return <div className={styles.file} >

                        <div className={styles.fileInfo}>
                            <i className={classNames(styles.fileIcon, "bi bi-filetype-pdf")}></i>

                            <div className='d-flex flex-column'>
                                <div>
                                    <p className={styles.fileTitle}>{t('version')} {version.number}</p>
                                </div>
                                <div className='d-flex'>
                                    <p className={classNames(styles.Button, styles.fileDate)} >{version.createdAt.split("T")[0]} </p>
                                    <p className={styles.fileTime}>{version.createdAt.split("T")[1].split('.')[0]}</p>

                                </div>
                            </div>

                        </div>

                        <div>
                            <Link to={`/pdf-view/${version.number}`} className={classNames(styles.fileButton,'btn btn-outline-success')}> {t('open')}</Link>
                        </div>


                    </div>
                })
            }
        </div>
    }
    </>
}

export default ShowHistory
