import React, { useEffect, useState } from 'react'
import { Col, Collapse } from 'react-bootstrap';

import styles from './CommentSection.module.scss';

import classNames from 'classnames'
import axios from 'axios';

import { useTranslation } from "react-i18next";

const CommentSection = ({ documentNumber }) => {
    const { t } = useTranslation();
    const [openQuestions, setOpenQuestions] = useState(false)
    const [openComments, setOpenComments] = useState(false)
    const [currentDoc, setCurrentDoc] = useState(documentNumber)

    const [questions, setQuestions] = useState(null);
    const [comments, setComments] = useState(null);

    useEffect(() => {
        if (!questions || currentDoc !== documentNumber) {
            axios.get('approved-questions').then(res => {
                setQuestions(res.data.result.filter(question => question.document.number == documentNumber))
            })
        }
        if (!comments || currentDoc !== documentNumber) {
            axios.get('approved-comments').then(res => {
                setComments(res.data.result.filter(comment => comment.document.number == documentNumber))
            })
        }

        if (currentDoc !== documentNumber)
            setCurrentDoc(documentNumber)
    }, [documentNumber, questions, comments])
    return (
        <div>
            <div className='card'>
                <h4 className='card-header' onClick={() => setOpenQuestions(!openQuestions)}
                    aria-controls="questions-collapse-text"
                    aria-expanded={openQuestions} >{t('questions section')} ({questions?.length || 0})</h4>

                <Collapse in={openQuestions}>
                    <div className='card-body' id="questions-collapse-text">
                        {questions &&
                            questions.map((question, index) => {
                                return <Col key={index} sm={12} lg={8} className='d-flex flex-column align-items-end'>
                                    <Col className={classNames(styles.comment, 'p-3 col-12')} >

                                        <h5 className={styles.paragraph}>{question.text}</h5>
                                        <p>{question.showPersonalInfo ? question.userName : t('anonymous')}</p>
                                        {question.showPersonalInfo ? <>
                                            <p> {t('email')} {question.email}</p>
                                            {question.telephone &&
                                                <p> {t('phone')} {question.telephone}</p>}

                                            {question.occupation &&
                                                <p> {t('occupation')} {question.occupation}</p>}

                                            {question.socialMediaLink &&
                                                <p> {t('socialMedia')} {question.socialMediaLink}</p>}
                                        </> : ''
                                        }

                                        <p className={classNames(styles.paragraph, styles.italicFont)}>{question.createdAt.split("T")[0]} {question.createdAt.split("T")[1].split('.')[0]}</p>
                                    </Col>
                                    {question.replies?.length > 0 &&
                                        question.replies.map((reply, index) => {
                                            return <Col key={index} sm={12} lg={11} className={classNames(styles.reply, 'p-3 col-12')}>

                                                <h5 className={styles.paragraph}>{reply.text}</h5>
                                                <p>{reply.userName} {t('or system admin')}</p>


                                                <p className={classNames(styles.paragraph, styles.italicFont)}>{reply.createdAt.split("T")[0]} {reply.createdAt.split("T")[1].split('.')[0]}</p>
                                            </Col>
                                        })

                                    }
                                </Col>
                            })


                        }{
                            questions &&
                            questions.length == 0 &&
                            <p className={classNames(styles.paragraph, 'card-cody')}><em>{t('no questions yet')}</em></p>
                        }

                    </div>
                </Collapse>
            </div>

            <div className='card'>
                <h4 className='card-header' onClick={() => setOpenComments(!openComments)}
                    aria-controls="questions-collapse-text"
                    aria-expanded={openComments} >{t('comments section')} ({comments?.length || 0})</h4>

                <Collapse in={openComments}>
                    <div className='card-body' id="comments-collapse-text">
                        {comments &&

                            comments.map((comment, index) => {
                                return <Col key={index} sm={12} lg={8} className='d-flex flex-column align-items-end'>
                                    <Col className={classNames(styles.comment, 'p-3 col-12')} >

                                        <h5 className={styles.paragraph}>{comment.text}</h5>
                                        <p>{comment.showPersonalInfo ? comment.userName : 'Anonim'}</p>
                                        {comment.showPersonalInfo ? <>
                                            <p> {t('email')} {comment.email}</p>
                                            {comment.telephone &&
                                                <p> {t('phone')} {comment.telephone}</p>}

                                            {comment.occupation &&
                                                <p> {t('occupation')} {comment.occupation}</p>}

                                            {comment.socialMediaLink &&
                                                <p> {t('socialMedia')} {comment.socialMediaLink}</p>}
                                        </> : ''
                                        }

                                        <p className={classNames(styles.paragraph, styles.italicFont)}>{comment.createdAt.split("T")[0]} {comment.createdAt.split("T")[1].split('.')[0]}</p>

                                    </Col>
                                </Col>

                            })
                        }


                        {
                            comments &&
                            comments.length == 0 &&
                            <p className={classNames(styles.paragraph, 'card-body')}><em>{t('no comments yet')}</em></p>
                        }
                    </div>
                </Collapse>

            </div>
        </div>
    )
}

export default CommentSection
