import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { HomePage } from "./views/Home/Home";
import { ArticleView } from "./views/Article/ArticleView";
import { Section } from "./views/Section/Section";
import { Authors } from "./views/Authors/Authors";
import { ErrorPage } from "./views/Error/Error";
import { SearchPage } from "./views/Search/Search";
import { Bibliography } from "./views/Bibliography/Bibliography";
import { Login } from "./views/Login/Login";
import { Register } from "./views/Register/Register";
import { ChangePassword } from "./views/Login/ChangePassword";
import { EditPublishing } from "./views/Publishing/EditPublishing";
import { useDispatch, useSelector } from "react-redux";
import { PopUpModal } from "./components/PopUpModal/PopUpModal";
import ShowHistory from "./views/Publishing/ShowHistory";
import PendingRequests from "./views/Publishing/PendingRequests";
import PendingQuestions from "./views/Publishing/PendingQuestions";
import axios from "axios";
import { PATH, getAuthToken } from "./services/tokenService";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ContentSidebar } from "./components/ContentSidebar/ContentSidebar";
import { DraftView } from "./views/DraftVersion/DraftVersion";
import { Content } from "./views/Contentt/Content";
import { ApprovedComments } from "./views/Approved/Comments";
import { ApprovedQuestions } from "./views/Approved/Questions";
import { RejectedComments } from "./views/Rejected/Comments";
import { RejectedQuestions } from "./views/Rejected/Questions";
import { currentLanguage, setDocument, setLoadingDocument } from "./redux/documentSlice";
import i18next from "i18next";
import { Visitors } from "./views/Visitors/Visitors";
import ContactForm from "./views/Contact/ContactUs";

axios.interceptors.request.use((config) => {
  config.timeout = 120000;
  if (!config.url.startsWith("http://") && !config.url.startsWith("https://")) {
    // Add trailing slash
    let q = config.url.search("\\?");
    if (q === -1) q = config.url.length;
    if (config.url[q - 1] !== "/")
      config.url =
        config.url.substr(0, q) +
        "/" +
        config.url.substr(q, config.url.length - 1);
    // config.timeout=5;
    // Add auth token
    const token = getAuthToken();
    if (token != null) config.headers["Authorization"] = "Bearer " + token;

    config.url = PATH + config.url;
  }

  return config;
});

const App = () => {
  const [gridConfig, setGridConfig] = useState({
    lg: 2,
    md: 3,
    sm: 4,
  });

  const MINUTE_MS = 600000; //1min
  const { mobileSidebar } = useSelector((state) => state.sidebar);

  const showPopUpModal = useSelector((state) => state.popUpModal.show);
  const documentStructure = useSelector((state) => state.document.structure);
  const documentLanguage = useSelector((state) => state.document.language);
  const [currentLang, setCurrentLang] = useState(i18next.language);

  const dispatch = useDispatch();

  // const getLatestVersion = async () => {
  //   const version = await axios.get(
  //     "version/latest?language=" + i18next.language
  //   );

  //   return version.data.result;
  // };

  useEffect(() => {
    getIp();
  }, []);

  const getIp = () => {
    axios
      .get("https://geolocation-db.com/json/")
      .then((response) => {
        const ip = response.data.IPv4;
        axios.post("visitor", {
          ip,
        });
      })
      .catch((error) => console.error(error));
  };

  const getDocument = () => {
    dispatch(setLoadingDocument(true))
    axios.get("documents/tree?language=" + i18next.language).then((res) => {
      if (res.data.result) {
        dispatch(setDocument(res.data.result.children));
        dispatch(currentLanguage(i18next.language));
        setCurrentLang(i18next.language);
      }

      dispatch(setLoadingDocument(false))
    });
  };

  // const checkNewVersion = () => {
  //   getLatestVersion().then(version => {
  //     if (!documentStructure || currentLang !== documentLanguage || version?.id !== documentStructure[0].versionId) {
  //       getDocument()
  //     }
  //   })
  // }

  useEffect(() => {
    if (!documentStructure) {
      getDocument();
    }
    // else
    //   checkNewVersion()
  }, [i18next.language, documentStructure]);

  useEffect(() => {
    if (!documentLanguage) {
      i18next.changeLanguage("en");
    } else {
      i18next.changeLanguage(documentLanguage);
    }
  }, [documentLanguage]);

  useEffect(() => {
    const interval = setInterval(() => {
      getDocument();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const expand = () => {
    setGridConfig({
      lg: 3,
      md: 4,
      sm: 6,
    });
  };

  const shrink = () => {
    setGridConfig({
      lg: 2,
      md: 3,
      sm: 4,
    });
  };

  useEffect(() => {
    if (mobileSidebar) {
      setGridConfig({
        ...gridConfig,
        sm: 4,
      });
    } else {
      setGridConfig({
        ...gridConfig,
        sm: 0,
      });
    }
  }, [mobileSidebar]);

  return (
    <BrowserRouter>
      {showPopUpModal && <PopUpModal />}
      <Header />
      <Row>
        <>
          <Col
            className="ease"
            {...gridConfig}
            onMouseEnter={expand}
            onMouseLeave={shrink}
          >
            <ContentSidebar />
          </Col>
          <Col>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/content/draft-version" element={<DraftView />} />
              <Route path="/content" element={<Content />} />
              <Route path="/article" element={<ArticleView />} />
              <Route path="/section" element={<Section />} />
              <Route path="/authors" element={<Authors />} />
              <Route path="/bibliography" element={<Bibliography />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/changepassword" element={<ChangePassword />} />
              <Route path="/visitors" element={<Visitors />} />

              <Route path="/content/edit" element={<EditPublishing />} />
              <Route path="/content/history" element={<ShowHistory />} />
              <Route path="/contact-us" element={<ContactForm />} />

              <Route path="/content/comments" element={<PendingRequests />} />
              <Route path="/content/questions" element={<PendingQuestions />} />

              <Route
                path="/content/approved/comments"
                element={<ApprovedComments />}
              />
              <Route
                path="/content/approved/questions"
                element={<ApprovedQuestions />}
              />
              <Route
                path="/content/rejected/comments"
                element={<RejectedComments />}
              />
              <Route
                path="/content/rejected/questions"
                element={<RejectedQuestions />}
              />
            </Routes>
          </Col>
        </>
      </Row>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
