import React from "react";
import styles from './Sidebar.module.scss'
import { EditButton } from "../EditButton/EditButton";
import ReactHtmlParser from 'react-html-parser';
import { convertToAlphabetical } from "./helpers/romanNumberConverter";

export const SubArticle = ({ item, draftView }) => {
    return <>
        <h4 className={styles.subArticleTitle} id={item.id}  >
            <span className={styles.subArticleTitleLetter} style={{
                marginLeft: '50px',
                fontWeight: 'normal',
            }}>{item.number > 0 && convertToAlphabetical(item.number)}. </span>
            <span style={{
                marginLeft: '30px'
            }}>
                {draftView && item.newText ?
                    ReactHtmlParser(item.newText)
                    :
                    ReactHtmlParser(item.text)
                }
            </span>
            {!draftView && <EditButton item={item} />}
        </h4>
    </>
}