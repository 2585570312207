import React from "react";
import styles from "./Sidebar.module.scss";
import ReactHtmlParser from "react-html-parser";
import { EditButton } from "../EditButton/EditButton";

export const SubsubParagraph = ({ item, draftView }) => {
  return (
    <div className={styles.paragraphWrapper} style={{ display: "flex" }}>
      <p id={item.id}>
        {draftView && item.newText
          ? ReactHtmlParser(item.newText)
          : ReactHtmlParser(item.text)}

        {!draftView && <EditButton item={item} />}
        {/* <i className={[styles.editButton, 'bi bi-chat-dots-fill']}></i> */}
        {/* <i className={classNames('bi bi-chat-dots-fill', styles.commentIcon)} onClick={handleCommentsModal}></i> */}
      </p>
    </div>
  );
};
