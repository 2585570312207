export const translationResources = {
  en: {
    translation: {
      "saved successfully":
        "New document version saved successfully. The new version will be available in around 10 minutes.",
      home_page: {
        t1: "The Kosovo Assembly adopted the Law on General Administrative Procedures (LGAP) on the 25th of May 2016 and the law entered into force on 21 June 2017, The law defines the principles of good administration that apply to all administrative procedures. It also establishes general standards for decision-making, issuing and implementing administrative acts as well as provides rules for communication between the parties of the procedure and the authority conducting the procedure.Even though the law has been in force for more than five years, it has not been consistently applied by the administration as evidenced by the ",
        t1_1: '',
        t5_1: '',
        t2: "European Commission in its reports.",
        t3: "One of the reasons for this has been the slow harmonisation of legal acts regulating the specific administrative procedures with the LGAP, but also the general lack of awareness about the provisions of the LGAP and their purpose",
        t4: "In order to overcome these implementation challenges, ",
        t5: "– a joint initiative of the OECD and the EU – was asked to develop this Commentary of the LGAP.  The Commentary provides, for every article, an explanatory introduction to its regulatory purpose followed by comments on how to interpret each legal concept, with a view to their relevance for administrative decision making in an individual case. Target groups of this Commentary are firstly the two “parties”, who directly participate in an administrative procedure, namely the citizens and the administrative practitioners in administrative bodies. However, other professionals will also benefit from this instrument, such as judges of administrative courts, members of the bar, teaching staff of law faculties, trainers of civil servants, law students and journalists.",
        t6: "Tips for using the Commentary",
        t7: "The Commentary can be accessed from the",
        t8: "Contents",
        t9: "the Commentary is structured according to the articles of the LGAP.",
        t10: "Each article is accompanied by an explanatory introduction to its regulatory purpose followed by comments on how to interpret the provisions in practice, when handling administrative matters.",
        t11: "The text of the Commentary contains references to relevant external sources as well as relevant other sections of the Commentary that can be accessed via hyperlinks for ensuring a smoother reading experience.",
        t12: "All readers can submit comments, when they would like to add something to the text of the Commentary (e.g. a reference to case law, administrative practice or useful article/source).",
        t13: "The readers can also ask questions from the ",
        t14: "Editorial Board",
        t15: "about the text of the commentary of the application of the LGAP in practice. ",
        t16: "For selected articles, the authors have developed Kosovo specific examples that analyse how the law should be applied in very specific real-life situations. ",
        t17: "The Commentary contains a ",
        t18: "bibliography",
        t19: "refers to the external materials that were used during the drafting of the text of the Commentary.",
        t20: "The readers can use the search function to find the specific word or phrase that they are interested in.",
      },
      authors_page: {
        Rusch: '',
        t1: "The commentary was written by Ulrich Ramsauer; Wolfgang Rusch; Zhani Shapo. ",
        t2: "is Professor emeritus in Administrative Law at the University of Hamburg and retired presiding judge at the Superior Administrative Court in Hamburg. For many years, he has been the author of the regularly updated reference commentary on the German Law on General Administrative Procedures. After retiring as a judge he joined one of the leading independent law firms in Germany as senior counsellor in public law matters. ",
        t3: 'Mr Ramsauer has been engaged in legal reform processes abroad and at home as Advisor to the German Federal Government and to the Government and Parliament of the State of Hamburg as well. He has consulted SIGMA in various administrative law drafting projects since 2009 including the legislative work in Albania and Kosovo and was member of the team of co-authors, who wrote in 2016 – 2018 the "Legal Commentary on the Albanian Code of Administrative Procedure". ',
        t4: "For the Legal Commentary on the Law on General Administrative Procedure of the Republic of Kosovo Mr Ramsauer wrote Part VII  “Administrative Remedies” and contributed to Part I “General Provisions”.",
        t5: "is a retired senior advisor of OECD/SIGMA specialising in the areas of administrative law and legal drafting. He started his professional career as a civil servant in Germany followed by almost twenty years of teaching students in Constitutional Law and Administrative Law. After finishing his academic profession he was engaged in various EU projects on public administration and judicial reform, most of them implemented in South-eastern European countries.",
        t6: 'Since 2008, Mr. Rusch has been working for SIGMA, where he continues to work as a freelancer after his regular retirement. In this position, he has been primarily active in promoting, planning and drafting legislation on administrative procedure and administrative justice, including the respective draft laws for Albania and Kosovo. His main activities of the recent years were the initiation, concept development, and co-authorship of the "Legal Commentary on the Albanian Code of Administrative Procedure", the first publication of its kind in South-eastern European countries, and his ongoing engagement for the Egyptian Government in the area of "Good Administrative Practices" and “Methodology of Drafting Good Legislation", the latter including RIA.',
        t7: "For the Legal Commentary on the Law on General Administrative Procedure of the Republic of Kosovo Mr Rusch wrote Part III “Administrative Actions”, Part VI “Notification”, Part VIII “Enforcement of Administrative Act”, and contributed to Part I “General Provisions” and, furthermore, was also responsible for the overall concept of the Commentary as a whole, coordinated the substantive work of the group of authors and did the final legal editorial work on the English text.",
        t8: "is a former Senior Civil Servant in Albania, who works now for his independent law firm and as university lecturer in Administrative Law in Tirana and Member of Board of Editors of the Albanian Law Journal. Comparative law studies and many years of professional experience have established him as internationally esteemed legal scholar in the fields of both European law and national administrative law systems in Italy, Germany and France. Since 1998, Mr Shapo has been repeatedly entrusted by the EU and other international agencies such as the World Bank and USAID with public sector reform projects both in Albania and in other transition countries. ",
        t9: 'Since that time, he has also been part of the pool of external experts of OECD/SIGMA, who have been regularly deployed for the implementation of reform projects in the fields of legal, administrative and civil service reform. In 2016 – 2018 he played the key role as the originator and main author of the "Legal Commentary on the Albanian Code of Administrative Procedure". In doing so, he broke new ground for the development of the administrative and legal system not only in Albania but also for the entire South-Eastern European region',
        t10: "For the Legal Commentary on the Law on General Administrative Procedure of the Republic of Kosovo Mr Shapo wrote Part II The Subjects of Administrative Procedure”, Part IV “General Rules on Administrative Proceeding”, Part V “Course of Administrative Proceeding”, Part IX Transitional and Final Provisions”, and contributed to Part I “General Provisions”. ",
        t11: "Thanks also go to Naser Shamolli for providing suggestions on how to best fit the text of the commentary to the Kosovo legal and administrative context and to Manfred Möller for proofreading and revision of Parts VI and VIII. The commentary was translated into Albanian by Ilir Marleka. The process was co-ordinated by Primož Vehar and Timo Ligi of the SIGMA Programme. ",
        t12: "The Editorial Board",
        t13: "As the Commentary is intended as a living online document, it is possible for every reader to submit comments and questions to the Editorial Board of the Commentary. The questions and comments can be submitted from the pages containing the commentary for each of the articles of the law. The Editorial Board processes these before deciding on their publication. The Board will publish all comments that provide additional info on how to apply the law in practice or which refer to examples of the application of the law (administrative practice, case law of courts, academic papers, etc.). The Editorial Board will also try to provide answers to the questions submitted, as much as possible. The Editorial Board can decide to publish additional Kosovo-specific examples, references to case law as well as to adjust the text of the commentary, if needed. ",
      },
      post: {
        t1: "Role of a legal commentary in the continental European legal tradition",
        t2: "In countries of the continental European law system legal commentaries are the classic working tool of every law applicant - be it ",
        t3: "civil servant, judge, advocate, individual citizen, law professor or student –",
        t4: "that helps explaining and interpreting laws and other regulations for application to practical cases. ",
        t5: "Explanation of legal texts is needed,",
        t6: "because the language of the law differs significantly from the easily understandable everyday language by its abstractness. Legal regulations are formulated typically by using abstract terms because the normative content of the provision is to be applied to an unknown multitude of both addressees and future situations or events whose concrete manifestation is uncertain (thus differing from the concrete individual regulation of administrative acts). ",
        t7: "As a result, even legally trained high professionals do not always find it easy to understand the meaning of the wording used in laws, their position in relation to each other and the overall context. Commentaries should therefore help all interested parties to apply the abstract and general legal norm correctly to a specific individual case.",
        t8: "It is necessary to underline that a legal commentary is not an official publication of a legally binding order. It is designed as an useful ",
        t9: "tool that may and should be consulted",
        t10: "by professionals to understand a legal norm by offering convincing, the reasoned, plausible legal views. This explains that it is quite possible that one can find in the commentary two (or even more) conflicting opinions on a legal problem presented that both (or all of them) might be equally justifiable according to the rules of logic and legal methodology, and to leave the final decision on the legal problem to the user. ",
        t11: "Legal commentaries are usually structured in the order of the articles of a law and their paragraphs, in this way following the order, which the legislator already chose not arbitrarily, but according to methodological aspects. Within the explanation of an article or its paragraphs the commentary reflects the logical order of their regulatory content and explains legal consequence and its legal requirements by interpreting each single legal term or short phrase. ",
        t12: "Commentaries on laws are not designed to be read from A to Z like a novel or a textbook. They are reference works, comparable to an encyclopaedia or dictionary, which the legal practitioner consults whenever it is a question of ",
        t13: "finding",
        t14: "a single provision or a group of provisions that could possibly be relevant for the legal solution of a concrete individual case, ",
        t15: "understanding",
        t16: "it correctly with the help of the legal methods of interpretation and ",
        t17: "applying",
        t18: "it to the case, in other words, subsuming the facts of the case without logical error under a legal term.",
        t19: "Instruments for interpreting the law have been developed in legal methodology. They include arguments from the wording of the legal provision (grammatical interpretation), from its context (systematic interpretation), from its purpose (teleological interpretation) and from the legislative materials and the history of its origins (historical interpretation).",
        t20: "The major",
        t21: "source",
        t22: ", which a legal commentary feals with for the substantiation of its legal views, is the ",
        t23: "adjudicative practice.",
        t24: 'This presupposes, however, that judgments are published regularly and as complete as possible, i.e. including the detailed reasoning of the decision. In countries where the use of legal commentaries is part of everyday legal practice, such complete court decisions are not only published by the courts themselves in official collections of decisions. They can also be found in legal literature such as periodicals or textbooks, where they are not only cited but also commented on, either approvingly or critically, i.e. with a dissenting legal opinion. Arguments in a commentary that can be based on court decisions are of particular weight. Nevertheless, in the continental European legal space, a court decision does not have a generally binding precedential effect, as we know it from Anglo-American common law. But in the reasoning of especially higher courts’ decisions one can find arguments that are to be respected as important for the interpretation in other cases. But even then, according to the continental European legal principle of "legicentrism", courts have to apply the codified law and not a court decision as a precedence ruling; the decision of a superior court only represents an interpretation guideline, albeit a very weighty one, which in further judicial practice should only be deviated from in exceptional cases.',
        t24_1: '',
        t24_2: '',
        t25: "Further sources to which a commentary can refer are ",
        t26: "legal opinions of academia or administrative practice",
        t27: " as published in monographs, textbooks and journals. Finally, it remains to be mentioned that in countries with a longer tradition of using legal commentaries, there are usually more than one commentary on the specialised book market. At least, this is true for the essential areas of law, such as general administrative procedural law. Therefore, the supportive comment or critical rejection of ",
        t28: "another commentary's legal opinion",
        t29: "on a certain question of interpretation is also part of the argumentation for one's own position. ",
        t30: "By considering different legal views from various sources on questions of interpreting a legal provision, the institution of the legal commentary becomes an important medium of the civil society’s ",
        t31: "public discourse on the law",
        t32: "and its relevance for the practice. Moreover, it makes a significant contribution to further ",
        t33: "developing the existing legislation",
        t34: "of a country by revealing and describing inadequacies, inappropriate developments, or gaps of the existing law or pointing out the need for adopting new or amended regulation.",
        t34_1: '',
        t34_2: '',
        t34_3: '',
        t34_4: '',
        t35: "In conclusion, it can be said that, at least until today, the institution of the legal commentary is a medium for the functioning of a state where the Rule of Law is maintained, whereby in the future digital forms of publication - such as digital paid versions available from publishers' databases or non-profit open-access commentaries - will take their place alongside the traditional form of the printed book for further dissemination and easier accessibility.",
        t36: "The approach of the Legal Commentary on the Law on General Administrative Procedure of the Republic of Kosovo ",
        t37: "The",
        t38: "Commentary on the Law on General Administrative Procedure of the Republic of Kosovo ",
        t39: "(hereinafter “the Commentary”) presented here is the first of its kind in legal and administrative practice in Kosovo. It is intended to be an everyday tool for practitioners in applying the provisions of the still relatively new LGAP of 2017, to contribute to law-based and appropriate administrative and judicial decisions, to facilitate the work of lawyers in safeguarding the rights interests of their clients vis-á-vis public administration, and to deepen the understanding of administrative law in academic research and teaching. Thus, in its purpose, function and methodological approach, the Commentary corresponds, at least to large extent, to its models used in many countries of the European Union as explained above.",
        t40: "The explanations of each individual article of the LGAP, its paragraphs and individual legal concepts are based on the principles of law applicable in the EU, as they derive from Democracy and the Rule of Law and are laid down in the ",
        t41: "EU Charter of Fundamental Rights.",
        t42: "Furthermore, as the LGAP has many similarities with the national law of other European countries, especially with the comparable Italian and German administrative law, the Commentary also refers to publications from these countries. Finally, the four generally applicable methods of legal interpretation mentioned above are applied, not least the historical method, which enquires into the presumed intention of the legislator. For using the historical method, it was an advantage that the three authors of the Commentary were also engaged as advisors to the Government and Parliament in the legislative process.",
        t43: "However, cases from practice in Kosovo, both relevant court decisions and actual administrative decisions of fundamental importance, as well as academic publications in journals of law and public administration, must be added. Up to now, there has been a shortage of suitable material here. However, this has to and will be made up for in the course of the further development of the commentary, which is by virtue of its nature a work in progress.",
        t44: "The",
        t45: "continuous updating",
        t46: "of the Commentary is facilitated by the digital form of publication, an innovation that puts it ahead of the traditional works of other European countries and makes the commentary an ",
        t47: "interactive means of communication.",
        t48: "Users are invited to submit comments, suggestions for improvement, or indications of inaccuracies to the text, in particular to provide cases of judicial and administrative practice as mentioned above. One can also raise legal questions related to the text of Commentary and the application of the LGAP in practice that will be considered and responded to by the ",
        t49: "Editorial Board",
        t50: "within a reasonable period of time.",
        t51: "The digital form also allows a ",
        t52: " search function",
        t53: "for specific terms or phrases and references to relevant external sources as well as relevant other sections of the Commentary that can be accessed via hyperlinks for ensuring a smoother reading experience.",
        t54: "Finally, the Commentary contains a ",
        t55: "bibliography",
        t56: "refers to the external materials used during the drafting of the text of the Commentary.",
      },
      "article-abbreviation": "Art.",
      footnotes: "Footnotes",
      "case law section": "Case law section",
      "bibliography section": "Bibliography section",
      "kosovo examples section": "Kosovo examples section",
      "contact us": "Contact us",
      "email sent": "Confirmation email has been sent",
      al: "Albanian",
      sr: "Serbian",
      en: "English",
      by: "By",
      email: "Email",
      phone: "Phone Number",
      occupation: "Occupation",
      socialMedia: "Social Media Link",
      "Welcome to React": "Welcome to React and react-i18next",
      chapter: "Chapter ",
      division: "Division",
      contents: "Contents",
      authors: "Authors",
      bibliography: "Bibliography",
      keyword: "Keyword",
      "executive issue": "Executive Issue",
      "the constitution of the republic of Kosovo":
        "The Constitution of the Republic of Kosovo",
      "executive edition": "Executive Edition",
      "read the preface": "Read the preface",
      "birth of the constitution": "Birth of the Constitution",
      "digital newsletter": "Digital Newsletter",
      "paper edition": "Paper Edition",
      supporter: "Supporter",
      "editorial board": "Editorial Board",
      "privacy policy": "Privacy Policy",
      "terms of use": "Terms of Use",
      login: "Login",
      register: "Register",
      download: "Download",
      "email address": "Email Address",
      password: "Password",
      "remember me": "Remember Me",
      "forgot password": "Forgot Password",
      "sign in": "Sign In",
      "don't have an account?": "Don't have an account?",
      "change password": "Change Password",
      "old password": "Old Password",
      "new password": "New Password",
      "confirm password": "Confirm Password",
      "first name": "First Name",
      "last name": "Last Name",
      "telephone number": "Telephone Number",
      "sign up": "Sign Up",
      "bibliography of the constitution of the republic of Kosovo":
        "Bibliography of the Constitution of the Republic of Kosovo",
      section: "Section",
      previous: "Previous",
      next: "Next",
      "edit publishing": "Edit Publishing",
      "show history": "Show History",
      "pending comments": "Pending Comments",
      "approved comments": "Approved Comments",
      "pending questions": "Pending Questions",
      "approved questions": "Approved and Replied Questions",
      "rejected questions": "Rejected Questions",
      "go to": "Go To",
      "questions section": "Questions Section",
      "comments section": "Comments Section",
      article: "Article ",
      part: "Part",
      partCapitalized: "PART ",
      content: "Content",
      search: "Search",
      logout: "Logout",
      "invalid credentials": "Invalid credentials",
      "please write your password": "Please write your Password",
      "please write your email address": "Please write your Email Address",
      "please write your first name": "Please write your First Name",
      "please write your last name": "Please write your Last Name",
      "please write your telephone number":
        "Please write your Telephone Number",
      "signing in": "Signing In",
      "logged in": "Logged In",
      history: "History",
      save: "Save",
      comments: "Comments",
      questions: "Questions",
      "see draft version": "See draft version",
      "visitors": "Visitors",
      open: "Open",
      version: "Version",
      "edit the text": "Edit the text",
      text: "Text",
      close: "Close",
      "save changes": "Save Changes",
      "write a comment or question": "Write a comment or question",
      "your comment/question...": "Your comment/question...",
      "your first name": "Your First Name",
      "your last name": "Your Last Name",
      "your phone number": "Your Phone Number",
      "your email": "Your Email",
      "phone number": "Phone Number",
      "show my personal info": "Show my personal info",
      "select your type of interaction": "Select your type of interaction",
      comment: "Comment",
      question: "Question",
      cancel: "Cancel",
      submit: "Submit",
      optional: "optional",
      "rejected comments": "Rejected Comments",
      "please check the fields": "Please check the fields",
      "submited successfully": "Submited successfully",
      "not implemented yet": "Not implemented yet",
      "update successfully": "Update successfully",
      "something went wrong, please try again!":
        "Something went wrong, please try again!",
      "legal commentary":
        "Legal Commentary on the Law on General Administrative Procedure of the Republic of Kosovo",
      "make question or comment": "Add Question or Comment",
      "previous article": "Previous Article",
      "next article": "Next Article",
      "go to approved comments": "Go to approved comments",
      "go to approved questions": "Go to approved questions",
      "go to rejected comments": "Go to rejected comments",
      "go to rejected questions": "Go to rejected questions",
      "delete comment": "Delete Comment",
      "or system admin": "or System Admin",
      "no questions yet": "No Questions yet",
      "no comments yet": "No Comments yet",
      anonymous: "Anonymous",
      "for article": "For Article",
      "no matches": "No Matches",
      refresh: "Refresh",
      "social media link": "Social Media Link",
      "please provide a reply!": "Please provide a reply!",
      "replied to question": "Replied to question",
      "server error, please try again!": "Server error, please try again!",
      "please select a reason first!": "Please select a reason first!",
      "question rejected": "Question rejected",
      "question deleted": "Question deleted",
      replied: "Replied",
      "article search": "Search for article",
      approved: "Approved",
      "no pending question": "No pending question",
      "no approved question": "No approved question",
      "delete question": "Delete Question",
      "no rejected requests for question": "No rejected requests for question",
      at: "At",
      "does not want to show personal info!":
        "Does not want to show personal info!",
      "write your reply below": "Write your reply below",
      reply: "Reply",
      reject: "Reject",
      "if you wish to reject, you have to select one of the options below":
        "If you wish to reject, you have to select one of the options below",
      "bad language": "Bad language",
      "not understandable": "Not understandable",
      "not relevant": "Not relevant",
      other: "Other",
      "comment approved": "Comment approved",
      "comment deleted": "Comment deleted",
      "no pending requests for comments": "No pending requests for comments",
      "no approved comments": "No approved comments",
      "no rejected comments": "No rejected comments",
      approve: "Approve",
      "search results appear below": "Search results appear below",
      "no search results": "No Search results",
      "you are in edit mode": "You are in Edit Mode",
      "copy the link": "Copy the link",
      "enter link": "Enter link",
      "commentary content": "Commentary content",
      "enter the word": "Enter the word that should become a link",
      "previous versions": "Previous versions",
      "no footnotes yet": "No footnotes yet",
      "no kosovo examples yet": "No Kosovo examples yet",
      'message': 'Message',
      'name': 'Name',
      'send': 'Send',
      "copy and paste the link":
        "In order to create a link, please copy the link and replace the word(s) that you want to become links with the copied link in the Text above.",
    },

  },
  al: {
    translation: {
      'message': 'Mesazhi',
      'name': 'Emri',
      'send': 'Dergo',
      "saved successfully":
        "Versioni i ri eshte ruajtur me sukses dhe do te jete i gatshem ne rreth 10 minuta.",
      home_page: {
        t1: "Kuvendi i Kosovës ka miratuar Ligjin për Procedurën e Përgjithshme Administrative (LPPA) më 25 maj 2016 dhe Ligji ka hyrë në fuqi më datën 21 qershor 2017. Ligji përcakton parimet e administrimit të mirë që vlejnë për të gjitha procedurat administrative. Ai gjithashtu përcakton standarde të përgjithshme për vendimmarrjen, nxjerrjen dhe zbatimin e akteve administrative si dhe parashikon rregulla për komunikimin ndërmjet palëve në procedurë dhe autoritetin që zhvillon procedurën.",
        t1_1: "Edhe pse Ligji ka qenë në fuqi për më shumë se pesë vjet, ai nuk është zbatuar në mënyrë konsekuente nga administrata, siç dëshmohet nga ",
        t2: "Komisioni Evropian në raportet e tij.",
        t3: "Një nga arsyet për këtë ka qenë harmonizimi i ngadalshëm i akteve ligjore që rregullojnë procedurat specifike administrative me LGAP, por edhe mungesa e përgjithshme e ndërgjegjësimit për dispozitat e LGAP dhe qëllimin e tyre.",
        t4: "Për të kapërcyer këto sfida të zbatimit,",
        t5: "– që është një nismë e përbashkët e OECD-së dhe BE-së – iu kërkua të hartonte këtë Komentar të LPPA-së. Komentari jep një hyrje shpjeguese të qëllimit rregullator për çdo nen që pasohet nga komente për mënyrën se si të interpretohet çdo koncept ligjor në funksion të rëndësisë së tij për vendimmarrjen administrative në një rast të veçantë.",
        t5_1: "Grupet e shënjestëruara të këtij Komentari janë së pari dy “palët”, të cilat marrin pjesë drejtpërdrejt në një procedurë administrative, gjegjësisht qytetarët dhe juristët administrativë në organet administrative. Megjithatë, nga ky instrument do të përfitojnë edhe profesionistë të tjerë, si gjyqtarët e gjykatave administrative, anëtarët e Odës së Avokatëve, stafi pedagogjik i fakulteteve të drejtësisë, trajner të nëpunësve civilë, studentë të drejtësisë dhe gazetarë",
        t6: "Këshilla për përdorimin e Komentit",
        t7: "Komentarit mund t`i qaset nga",
        t8: "Përmbajtja",
        t9: "ndërkohë që Komentari është i strukturuar sipas neneve të LPPA-së.",
        t10: "Çdo nen shoqërohet nga një hyrje që shpjegon qëllimin e tij rregullator dhe që ndiqet nga shpjegimet se si të interpretohen dispozitat në praktikë, kur trajtohen çështjet administrative.",
        t11: "Teksti i Komentarit përmban referenca tek burimet përkatëse të jashtme, si dhe seksionet e tjera përkatëse të Komentarit, të cilat mund të qasen nëpërmjet hiperlinqeve për të siguruar një përvojë më të lehtë leximi.",
        t12: "Të gjithë lexuesit mund të paraqesin komente kur dëshirojnë të shtojnë diçka në tekstin e Komentarit (p.sh. referencë në praktikën gjyqësore, praktikën administrative ose artikull/burim të dobishëm).",
        t13: "Lexuesit mund t'i bëjnë pyetje edhe ",
        t14: "Redaksisë",
        t15: "në lidhje me tekstin e Komentarit për zbatimin e LPPA-së në praktikë.",
        t16: "Në rastin e disa neneve të caktuara, autorët kanë zhvilluar shembuj specifikë të Kosovës që analizojnë se si duhet të zbatohet ligji në situata shumë specifike të jetës reale.",
        t17: "Komentari përmban një ",
        t18: "bibliografi",
        t19: "që i referohet materialeve të jashtme që janë përdorur gjatë hartimit të tekstit të Komentarit.",
        t20: "Lexuesit mund të përdorin funksionin e kërkimit për të gjetur fjalën ose frazën specifike për të cilën janë të interesuar.",
      },
      authors_page: {
        Rusch: 'Z. Rusch',
        t1: "Komentari është shkruar nga z. Ulrich Ramsauer; z. Wolfgang Rusch; z. Zhani Shapo",
        t2: "është profesor emeritus  i të drejtës administrative në Universitetin e Hamburgut dhe kryetar në pension i Gjykatës së Lartë Administrative të Hamburgut. Ai ka qenë për shumë vite autor i  komentarit të përditësuar, më të njohur, të Ligjit Gjerman për Procedurat e Përgjithshme Administrative. Pasi doli në pension si gjyqtar, ai iu bashkua një prej firmave kryesore të pavarura ligjore në Gjermani si këshilltar i lartë për çështjet e së drejtës publike.",
        t3: "ka qenë i angazhuar në proceset e reformës ligjore jashtë dhe brenda vendit si Këshilltar i Qeverisë Federale Gjermane si dhe i Qeverisë dhe Parlamentit të Land-it të Hamburgut. Ai ka ofruar konsulencë për SIGMA-n në projekte të ndryshme për hartimin e ligjeve administrative që nga viti 2009, duke përfshirë punën legjislative në Shqipëri dhe Kosovë dhe ka qenë anëtar i ekipit të bashkautorëve, të cilët kanë shkruar “Komentarin Ligjor të Kodit Shqiptar të Procedurës Administrative” në vitet 2016 – 2018.",
        t4: "Për Komentarin Ligjor të Ligjit për Procedurën e Përgjithshme Administrative të Republikës së Kosovës, z. Ramsauer shkroi Pjesën VII “Mjetet juridike administrative” dhe kontribuoi në Pjesën I “Dispozitat e Përgjithshme”.",
        t5: "është këshilltar i lartë në pension i OECD/SIGMA-s i specializuar në fushat e së drejtës administrative dhe hartimit ligjor. Ai e filloi karrierën e tij profesionale si nëpunës civil në Gjermani, pasuar nga gati njëzet vjet mësimdhënie për studentët në të Drejtën Kushtetuese dhe të Drejtën Administrative. Pas përfundimit të profesionit të tij akademik, ai u angazhua në projekte të ndryshme të BE-së për administratën publike dhe reformën në drejtësi, shumica e tyre të zbatuara në vendet e Evropës Juglindore.",
        t6: 'Që nga viti 2008, z. Rusch ka punuar për SIGMA-n, ku dhe vazhdon të punojë si ekspert i pavarur pas daljes në pension. Në këtë pozicion, ai ka qenë aktiv kryesisht në promovimin, planifikimin dhe hartimin e legjislacionit për procedurën administrative dhe drejtësinë administrative, duke përfshirë projektligjet përkatëse për Shqipërinë dhe Kosovën. Veprimtaria e tij kryesore e viteve të fundit përfshin fillimin, zhvillimin e konceptit dhe bashkautorësinë e "Komentarit Ligjor të Kodit të Procedurave Administrative të Republikës së Shqipërisë", botimi i parë i këtij lloji në vendet e Evropës Juglindore dhe angazhimi i tij i vazhdueshëm pranë Qeverisë egjiptiane në fushën e "Praktikave të mira administrative" dhe në fushën e "Metodologjisë së hartimit të legjislacionit të mirë", kjo e fundit duke përfshirë edhe Vlerësimin e Ndikimit Rregullator - RIA.',
        t7: 'shkroi Pjesën III "Veprimet Administrative", Pjesën VI "Njoftimi", Pjesën VIII "Ekzekutimi i aktit administrativ" dhe kontribuoi në Pjesën I "Dispozitat e Përgjithshme" ndërkohë që ai ka qenë gjithashtu edhe përgjegjës për konceptin e përgjithshëm të Komentarit në tërësi, ka koordinuar punën përmbajtësore të grupit të autorëve dhe ka realizuar editimin (lektorimin) juridik të tekstit në anglisht.',
        t8: "është një ish-nëpunës i lartë civil në Shqipëri, i cili tani punon për studion e tij të pavarur ligjore. Ai është  lektor universitar i të Drejtës Administrative në Tiranë dhe Anëtar i Redaksisë të Revistës Juridike Shqiptare. Studimet krahasuese të së drejtës dhe përvoja shumëvjeçare profesionale e kanë bërë atë të njohur si studiues i së drejtës i vlerësuar ndërkombëtarisht në fushën e të drejtës evropiane dhe të sistemeve të së drejtës administrative kombëtare në Itali, Gjermani dhe Francë. Që nga viti 1998, z. Shapo i janë besuar në mënyrë të përsëritur nga BE-ja dhe agjenci të tjera ndërkombëtare si Banka Botërore dhe USAID-i, projekte reformuese në sektorin publik si në Shqipëri ashtu edhe në vende të tjera në tranzicion.",
        t9: "Që nga ajo kohë, ai ka qenë gjithashtu pjesë e grupit të ekspertëve të jashtëm të OECD/SIGMA-s, të cilët janë angazhuar rregullisht në zbatimin e projekteve reformuese në fushën e reformës ligjore, administrative dhe të shërbimit civil. Në vitet 2016 – 2018 ai ka luajtur rolin kryesor si iniciatori dhe autori kryesor i “Komentarit Ligjor të Kodit Shqiptar të Procedurave Administrative”. Në këtë mënyrë, ai hapi terren të ri për zhvillimin e sistemit administrativ dhe juridik jo vetëm në Shqipëri, por edhe në të gjithë rajonin e Evropës Juglindore.",
        t10: "Në Komentarin Ligjor të Ligjit për Procedurën e Përgjithshme Administrative të Republikës së Kosovës z. Shapo ka shkruar Pjesën II “Subjektet e procedurës administrative”, Pjesën IV “Rregullat e përgjithshme të procedurës administrative”, Pjesën V “Zhvillimi i procedurës administrative”, Pjesën IX “Dispozitat kalimtare dhe të fundit”, dhe ka kontribuar në Pjesën I “Dispozitat e Përgjithshme”.",
        t11: "Faleminderojmë gjithashtu edhe z. Naser Shamolli për sugjerimet e vlefshme për  përshtatjen sa më të mirë të tekstit të Komentarit me kontekstin ligjor dhe administrativ të Kosovës dhe z. Manfred Möller për korrigjimin dhe rishikimin e Pjesëve VI dhe VIII. Komentari është përkthyer në shqip nga z. Ilir Marleka. I gjithë proçesi u koordinua nga z. Primož Vehar dhe z. Timo Ligi të Programit SIGMA.",
        t12: "Redaksia (Bordi i Redaktimit)",
        t13: "Duke qenë se Komentari është synuar që të jetë një dokument dinamik online, është e mundur që çdo lexues të paraqesë komente dhe pyetje pranë Redaksisë të Komentarit. Pyetjet dhe komentet mund të paraqiten nga faqja që përmban shpjegimin për secilin prej neneve të ligjit. Redaksia i përpunon këto para se të vendosë për publikimin e tyre. Redaksia do të publikojë të gjitha komentet që ofrojnë informacion shtesë për mënyrën e zbatimit të ligjit në praktikë ose që i referohen shembujve të zbatimit të ligjit (praktikë administrative, praktikë gjyqësore e gjykatave, punime akademike, etj.). Redaksia do të përpiqet t’i japë përgjigje sa më shumë prej pyetjeve të parashtruara. Redaksia mund të vendosë të publikojë shembuj shtesë specifikë për Kosovën, referenca në praktikën gjyqësore si dhe të përshtasë tekstin e Komentarit, nëse është e nevojshme.",
      },
      post: {
        t1: "Roli i një komenti ligjor në traditën juridike evropiane kontinentale",
        t2: "Në vendet e sistemit të së drejtës kontinentale evropiane, komentarët ligjorë janë mjeti klasik i punës për çdo përdorues të ligjit – qoftë",
        t3: "nëpunës civil, gjykatës, avokat, qytetar, profesor i së drejtës apo student i saj",
        t4: "që ndihmon në shpjegimin dhe interpretimin e ligjeve dhe rregullave të tjera për zbatimin e rasteve praktike.",
        t5: "Shpjegimi i teksteve ligjore është i nevojshëm,",
        t6: "sepse gjuha e ligjit ndryshon dukshëm nga gjuha e përditshme lehtësisht e kuptueshme për shkak të abstraktitetit të saj. Rregullat ligjore formulohen në mënyrë tipike duke përdorur terma abstrakte, sepse përmbajtja normative e dispozitës duhet të zbatohet për një numër të madh të panjohur si të palëve, të cilave u drejtohet, ashtu dhe situatave ose ngjarjeve të ardhshme, ndodhja konkrete e të cilave është i pasigurt (duke ndryshuar kështu nga rregullimi konkret i veçantë i akteve administrative).",
        t7: "Si rezultat, edhe profesionistët e nivelit të lartë të kualifikuar në fushën e së drejtës nuk e kanë gjithmonë të lehtë të kuptojnë formulimin e përdorur në ligje, pozicionin e tyre në raport me njëri-tjetrin dhe kontekstin e përgjithshëm. Prandaj, komentarët duhet të ndihmojnë të gjitha palët e interesuara që të zbatojnë në mënyrë korrekte normën juridike abstrakte dhe të përgjithshme në një rast specifik dhe konkret.",
        t8: "Është e nevojshme të nënvizohet se një komentar ligjor nuk është botim zyrtar me karakter të detyrueshëm. Ai është konceptuar si një ",
        t9: "mjet i dobishëm që mund dhe duhet të konsultohet ",
        t10: "nga profesionistët për të kuptuar një normë juridike duke ofruar pikëpamje ligjore bindëse, të arsyetuara dhe të besueshme. Kjo shpjegon faktin se është shumë e mundur që në një komentar të mund të gjenden, të paraqitura, dy (ose edhe më shumë) mendime kontradiktore për një problem juridik, ku të dyja (ose të gjitha) mund të jenë njëlloj të justifikueshme sipas rregullave të logjikës dhe metodologjisë juridike duke ia lënë përdoruesit vendimin përfundimtar për problemin juridik. ",
        t11: "Komentarët ligjorë zakonisht strukturohen sipas renditjes së neneve të një ligji dhe paragrafëve të tyre, duke ndjekur në këtë mënyrë rendin, të cilin ligjvënësi tashmë e ka zgjedhur jo në mënyrë arbitrare, por sipas aspekteve metodologjike. Komentari pasqyron brenda shpjegimit të një neni ose paragrafëve të tij rendin logjik të përmbajtjes së tyre rregullatore si dhe shpjegon pasojat dhe kërkesat e tij ligjore duke interpretuar çdo term ose frazë të shkurtër juridike.  ",
        t12: "Komentarët e ligjeve nuk janë krijuar për t'u lexuar nga A në Z si një roman apo një tekst shkollor. Ata janë vepra referimi, të krahasueshme me një enciklopedi ose fjalor, të cilin juristi e konsulton sa herë që bëhet fjalë për ",
        t13: "gjetjen",
        t14: "e një dispozite të vetme ose të një grupi dispozitash që mund të jenë potencialisht të rëndësishme për zgjidhjen ligjore të një çështjeje konkrete të veçantë, ",
        t15: "duke e kuptuar ",
        t16: "atë drejt me ndihmën e metodave ligjore të interpretimit dhe ",
        t17: "duke e zbatuar ",
        t18: "atë në çështje të caktuara, me fjalë të tjera, duke i’a nënshtruar faktet e çështjes, pa gabime logjike, një dispozite ligjore. ",
        t19: "Në metodologjinë juridike  janë zhvilluar instrumentet e interpretimit të ligjit. Ato përfshijnë argumente nga formulimi i dispozitës ligjore (interpretimi gramatikor), nga konteksti i saj (interpretimi sistematik), nga qëllimi i saj (interpretimi teleologjik) dhe nga materialet legjislative dhe historia e origjinës së saj (interpretimi historik).",
        t20: "",
        t21: "Praktika gjyqësore ",
        t22: "është ",
        t23: "burimi",
        t24: 'kryesor, në të cilin mbështetet një komentar ligjor për argumentimin e pikëpamjeve të tij juridike. Megjithatë, kjo presupozon që vendimet gjyqësore të publikohen rregullisht dhe sa më të plota, pra duke përfshirë arsyetimin e detajuar të vendimit. Në vendet ku përdorimi i komentarëve ligjorë është pjesë e praktikës së përditshme ligjore, vendime të tilla të plota gjyqësore nuk publikohen vetëm nga vetë gjykatat në koleksionet zyrtare të vendimeve. Ato mund të gjenden edhe në literaturën juridike si revista periodike apo tekste shkollore, ku jo vetëm citohen, por edhe komentohen, qoftë në mënyrë miratuese apo kritike, pra me një mendim juridik të kundërt. Argumentet që përmban një komentar dhe që mund të bazohen në vendime gjyqësore kanë një peshë të veçantë. Megjithatë, në hapësirën juridike kontinentale evropiane, vendimi gjyqësor nuk ka efektin e precedentit përgjithësisht detyrues, siç e njohim nga e drejta anglo-amerikane e common law. Sidoqoftë, në arsyetimin e vendimeve të gjykatave, veçanërisht në ato të gjykatave të larta, mund të gjenden argumente që duhen respektuar si të rëndësishme për interpretimin në raste të tjera. Por edhe atëherë, sipas parimit ligjor kontinental evropian të "legi-centrizmit", gjykatat duhet të zbatojnë ligjin e kodifikuar dhe jo një vendim gjykate si vendim i bazuar tek precedenti; vendimi i një gjykate më të lartë përfaqëson vetëm ',
        t24_1: "një udhëzim interpretimi,",
        t24_2:
          "ndonëse është një udhëzues me shumë peshë, i cili në praktikën e mëtejshme gjyqësore duhet të shmanget vetëm në raste të jashtëzakonshme",
        t25: "Burime të tjera të cilave mund t'u referohet një komentar, janë ",
        t26: "opinionet ligjore të intelektualëve apo praktikës administrative,",
        t27: " botuar në monografi, tekste shkollore dhe revista. Së fundi, duhet të përmendet se në vendet me një traditë më të gjatë të përdorimit të komentarëve ligjorë, zakonisht ka më shumë se një komentar në tregun e specializuar të librit. Kjo është e vërtetë së paku, për fushat kryesore të së drejtës, siç është e drejta e përgjithshme procedurale administrative. Prandaj, një koment mbështetës ose kundërshtimi kritik i",
        t28: "opinionit juridik të një komentari tjetër",
        t29: "për një çështje të caktuar interpretimi është gjithashtu pjesë e argumentimit për qëndrimin e dikujt.",
        t30: "Duke marrë në konsideratë pikëpamje të ndryshme ligjore nga burime të ndryshme për çështjet e interpretimit të një dispozite ligjore, institucioni i komentarit ligjor bëhet një mjet i rëndësishëm i",
        t31: "diskursit publik ",
        t32: "të shoqërisë civile ",
        t33: "mbi të drejtën ",
        t34: "dhe rëndësinë e saj për praktikën. Gjithashtu, ai jep një kontribut të rëndësishëm për",
        t34_1: "zhvillimin ",
        t34_2: "e mëtejshëm ",
        t34_3: "të legjislacionit ekzistues ",
        t34_4:
          "të një vendi duke zbuluar dhe përshkruar mangësitë, zhvillimet e papërshtatshme ose boshllëqet e ligjit ekzistues ose duke vënë në dukje nevojën për miratimin e një ligji të ri ose të ndryshuar.",
        t35: "Si përfundim, mund të thuhet se, të paktën deri më sot, institucioni i komentarit ligjor është një mjet për funksionimin e një shteti ku ruhet sundimi i ligjit, ku në të ardhmen format dixhitale të publikimit – të tilla si versionet dixhitale me pagesë që janë të disponueshme në bazat e të dhënave të botuesve ose komentarët jofitimprurës me qasje të hapur - do të zënë vendin e tyre së bashku me formën tradicionale të librit të shtypur për shpërndarje të mëtejshme dhe qasje më të lehtë.",
        t36: "Qasja e Komentarit Ligjor të Ligjit për Procedurën e Përgjithshme Administrative të Republikës së Kosovës",
        t37: "Komentari i Ligjit për Procedurën e Përgjithshme Administrative të Republikës së Kosovës (në vijim “Komentari”) i paraqitur këtu është ndër të parët e këtij lloji në praktikën juridike dhe administrative në Kosovë. Ai synon të jetë një mjet i përditshëm për juristët në funksion të zbatimit të dispozitave të LPPA-së që është ende një ligj relativisht i ri i vitit 2017, për të kontribuar në marrjen e vendimet administrative dhe gjyqësore të bazuara në ligj dhe rregulla, për të lehtësuar punën e avokatëve në mbrojtjen e interesave dhe të drejtave të klientëve të tyre kundrejt administratës publike dhe për të thelluar të kuptuarit e së drejtës administrative në kërkimin akademik dhe mësimdhënien. Kështu Komentari korrespondon për sa i takon qëllimit, funksionit dhe qasjes metodologjike në një masë të madhe, me modelet e tij, të përdorura në shumë vende të Bashkimit Evropian siç u shpjegua edhe më sipër.",
        t40: "Shpjegimet e çdo neni të veçantë të LPPA-së, të paragrafëve të tij dhe të koncepteve juridike individuale bazohen në parimet e ligjit të zbatueshëm në BE, pasi ato rrjedhin nga Demokracia dhe Shteti i së Drejtës dhe janë të përcaktuara në",
        t41: "Kartën e të Drejtave Themelore të BE-së",
        t42: "Gjithashtu, duke qenë se LPPA-ja ka shumë ngjashmëri me legjislacionin kombëtar të vendeve të tjera evropiane, veçanërisht me të drejtën e krahasueshme administrative italiane dhe gjermane, Komentari u referohet edhe botimeve nga këto vende. Së fundi, zbatohen katër metoda që përdoren përgjithësisht për interpretimin ligjor dhe që janë përmendur më sipër, pa përjashtuar metodën historike, e cila hulumton qëllimin e prezumuar të ligjvënësit. Fakti që tre autorët e Komentarit kanë qenë të angazhuar edhe si këshilltarë të Qeverisë dhe Parlamentit në procesin legjislativ ka sjellë një avantazh për sa i përket përdorimit të metodës historike.",
        t43: "Megjithatë, duhet të shtohen rastet nga praktika në Kosovë, si vendimet gjyqësore domethënëse ashtu edhe vendimet aktuale administrative me rëndësi themelore, si dhe publikimet akademike në revista të së drejtës dhe administratës publike. Deri më tani, këtu ka pasur mungesë të materialit të përshtatshëm. Megjithatë, kjo duhet dhe do të plotësohet gjatë zhvillimit të mëtejshëm të Komentarit, i cili për nga natyra e tij është një punë në vazhdim.",
        t44: "",
        t38: '',
        t39: '',
        t45: "Përditësimi i vazhdueshëm",
        t46: "i Komentarit lehtësohet nga forma dixhitale e publikimit, një risi që e vendos atë përpara veprave tradicionale të vendeve të tjera evropiane dhe e bën komentarin një",
        t47: "mjet ndërveprues komunikimi",
        t48: "Përdoruesit ftohen të paraqesin komente, sugjerime për përmirësim, ose identifikojnë raste të pasaktësive të tekstit, dhe të ofrojnë veçanërisht raste të praktikës gjyqësore dhe administrative siç u përmend më lart. Mund të lindin edhe pyetje ligjore në lidhje me tekstin e Komentarit dhe zbatimin e LPPA-së në praktikë, të cilat do të shqyrtohen dhe do t'u përgjigjet ",
        t49: "Redaksia",
        t50: "brenda një periudhe kohore të arsyeshme",
        t51: "Forma dixhitale mundëson gjithashtu edhe një ",
        t52: " funksion kërkimi",
        t53: "për terma ose fraza specifike dhe referenca për burimet përkatëse të jashtme, si dhe seksione të tjera përkatëse të Komentarit që mund të qasen nëpërmjet hiperlinqeve për të siguruar një përvojë më të lehtë leximi.",
        t54: "Së fundi, Komentari përmban një ",
        t55: "bibliografi",
        t56: "që i referohet materialeve të jashtme të përdorura gjatë hartimit të tekstit të Komentarit.",
      },
      "article-abbreviation": "Neni",
      "case law section": "Seksioni i praktikës gjyqësore",
      "bibliography section": "Seksioni i bibliografisë",
      "kosovo examples section": "Seksioni i shembujve të Kosovës",
      "contact us": "Na kontakto",
      "no footnotes yet": "Nuk ka ende fusnotë",
      "email sent": "Email konfirmues eshte derguar",
      al: "Shqip",
      footnotes: "Fusnotat",
      sr: "Serbisht",
      en: "Anglisht",
      "Welcome to React": "Bienvenue à React et react-i18next",
      chapter: "Kapitulli ",
      contents: "Kontenti",
      authors: "Autorët",
      bibliography: "Bibliografia",
      by: "Nga",
      "commentary content": "Përmbajtja e komentuesit",
      email: "Email",
      phone: "Numri kontaktues",
      division: "Nënkapitulli",
      occupation: "Profesioni",
      socialMedia: "Linku per rrjete sociale",
      keyword: "Fjalët Kyçe",
      optional: "Opsionale",
      "rejected questions": "Pyetjet e refuzuara",
      "rejected comments": "Komentet e refuzuara",
      "executive issue": "Botim ekzekutiv",
      "the constitution of the republic of Kosovo":
        "Kushtetuta e Republikës së Kosovës",
      "executive edition": "Edicion Ekzekutiv",
      "read the preface": "Lexo parathënien",
      "birth of the constitution": "Lindja e kushtetutës",
      "digital newsletter": "Gazeta digjitale",
      "paper edition": "Botimi në letër",
      supporter: "Mbështetës",
      "editorial board": "Bordi editorial",
      "privacy policy": "Të drejtat",
      "terms of use": "Kushtet e përdorimit",
      login: "Kyçu",
      register: "Regjistrohu",
      download: "Shkarko",
      "email address": "Adresa e emailit",
      password: "Fjalëkalimi",
      "remember me": "Më mbaj mend",
      "forgot password": "Ke harruar fjaëkalimin?",
      "sign in": "Kyçu",
      "don't have an account?": "Nuk ke një llogari?",
      "change password": "Ndrysho fjalëkalimin",
      "old password": "Fjalëkalimi i vjetër",
      "new password": "Fjalëkalimi i ri",
      "confirm password": "Konfirmo fjalëkalimin",
      "first name": "Emri",
      "last name": "Mbiemri",
      comments: "Komentet",
      questions: "Pyetjet",
      "see draft version": "Shiko draft versionin",
      "visitors": "Visitoret",
      "telephone number": "Numri i telefonit",
      "sign up": "Regjistrohu",
      "bibliography of the constitution of the republic of Kosovo":
        "Bibliografia e Kushtetutës së Republikës së Kosovës",
      section: "Seksioni",
      previous: "E kaluara",
      next: "E ardhmja",
      "edit publishing": "Ndrysho tekstin",
      "show history": "Trego historinë",
      "pending comments": "Kërkesa për komente",
      "approved comments": "Komente te aprovuara",
      "pending questions": "Kërkesa për pyetje",
      "approved questions": "Pyetje te aprovuara dhe pergjigjura",
      "go to": "Shiko",
      "questions section": "Seksioni i pyetjeve",
      "comments section": "Seksioni i komenteve",
      article: "Neni ",
      part: "Pjesa",
      partCapitalized: "PJESA ",
      content: "Përmbajtja",
      search: "Kërko",
      logout: "Shkyçu",
      "invalid credentials": "Kredencialet e pavlefshme",
      "please write your password": "Ju lutem shkruani fjalëkalimin tuaj",
      "please write your email address":
        "Ju lutem shkruani adresën tuaj të emailit",
      "please write your first name": "Ju lutem shkruani emrin tuaj",
      "please write your last name": "Ju lutem shkruani mbiemrin tuaj",
      "please write your telephone number":
        "Ju lutem shkruani numrin tuaj të telefonit",
      "signing in": "Duke u regjistruar",
      "logged in": "Jeni kyçur",
      history: "Historia",
      open: "Hape",
      version: "Versioni",
      "edit the text": "Ndrysho tekstin",
      text: "Teksti",
      close: "Mbylle",
      "save changes": "Ruaj ndryshimet",
      save: "Ruaj",
      "write a comment or question": "Shkruaj një koment apo pyetje",
      "your comment/question...": "Komenti juaj...",
      "your first name": "Emri juaj",
      "your last name": "Mbiemri juaj",
      "your phone number": "Numri juaj i telefonit",
      "your email": "Emaili juaj",
      "phone number": "Numri i telefonit",
      "show my personal info": "Trego të dhënat e mia personale",
      "select your type of interaction": "Zgjidhe llojin e ndërveprimit",
      comment: "Koment",
      question: "Pyetje",
      cancel: "Anulo",
      submit: "Dorëzoje",
      "go to approved comments": "Shko te komentet e aprovuara",
      "go to approved questions": "Shko te pyetjet e aprovuara",
      "go to rejected comments": "Shko te komentet e refuzuara",
      "go to rejected questions": "Shko te pytjet e aprovuara",
      "please check the fields": "Ju lutem kontrolloni infot",
      "submited succesfully": "U dorzua me sukses",
      "not implemented yet": "Nuk është zbatuar akoma",
      "update successfully": "U përditësua me sukses",
      "something went wrong, please try again!":
        "Diçka shkoj gabim, ju lutem provoni përsëri!",
      "legal commentary":
        "Komentar ligjor i ligjit për procedurën e përgjithshme administrative të Republikës së Kosovës",
      "make question or comment": "Shto një pyetje apo koment",
      "previous article": "Neni i kaluar",
      "for article": "Për nenin",
      "next article": "Neni i ardhshëm",
      "delete comment": "Fshij komentin",
      "or system admin": "ose Admini i Sistemit",
      "no questions yet": "Nuk ka pyetje akoma",
      "no comments yet": "Nuk ka komente akome",
      anonymous: "Anonim",
      "no matches": "Nuk ka rezultate",
      refresh: "Refresh",
      "social media link": "Linku i mediave sociale",
      "please provide a reply!": "Ju lutem shkruani një përgjigjje!",
      "replied to question": "U përgjigj tek pyetja",
      "server error, please try again!":
        "Gabim në server, ju lutem provoni përsëri!",
      "please select a reason first!":
        "Ju lutem zgjedhni një arsye fillimisht!",
      "question rejected": "Pyetja u refuzua",
      "question deleted": "Pyetje u fshi",
      replied: "U përgjigj",
      approved: "E miratuar",
      "no pending question": "Asnjë pyetje në pritje",
      "no approved question": "Asnjë pyetje e miratuar",
      "delete question": "Fshij pyetjen",
      "no rejected requests for question":
        "Asnjë kërkesë e refuzuar për pyetje",
      at: "Tek",
      "does not want to show personal info!":
        "Nuk dëshiron të shfaq informacionet personale!",
      "write your reply below": "Shkruaj përgjigjjen poshtë",
      reply: "Përgjigju",
      reject: "Refuzo",
      "if you wish to reject, you have to select one of the options below":
        "Nëse dëshiron të e refuzoni, ju lutem zgjidhni njërin nga mundësitë e mëposhtme",
      "bad language": "Gjuhë e keqe",
      "not understandable": "I pakuptueshëm",
      "not relevant": "I parëndësishëm",
      other: "Tjetër",
      "comment approved": "Komenti u miratua",
      "comment deleted": "Komenti u fshi",
      "article search": "Kerko nenin",
      "no pending requests for comments": "Asnjë kërkesë për koment në pritje",
      "no approved comments": "Nuk ka komente të miratuara",
      "no rejected comments": "Nuk ka komente të refuzuara",
      "no kosovo examples yet": "Nuk ka ende shembuj te Kosovës",
      approve: "Mirato",
      "search results appear below": "Rezultatet e kërkimit shfaqen më poshtë",
      "no search results": "Nuk ka rezultate të vlefshme nga kërkimi",
      "you are in edit mode": "Tani je në modën e editimit",
      "copy the link": "Kopjo linkun",
      "enter link": "Sheno linkun",
      "enter the word": "Sheno fjalen qe duhet te shnderrohet ne link",
      "copy and paste the link":
        "Ne menyre qe te krijohet linku, ju lutem te kopjoni linkun dhe zevendesoni fjalet qe deshironi te kthehen ne link ne Tekstin me siper.",
      "previous versions": "Versionet e kaluara",
    },
  },
  sr: {
    translation: {
      "saved successfully": "Nova verzija dokumenta uspešno sačuvana. Nova verzija će biti dostupna za oko 10 minuta.",
      "home_page": {
        "t1": "Kosovska skupština je usvojila Zakon o Opštem Upravnom Postupku (ZOUP) 25. maja 2016. godine, a zakon je stupio na snagu 21. juna 2017. godine. Zakon definiše načela dobrog upravljanja koja se primenjuju na sve upravne postupke. On takođe uspostavlja opšte standarde za donošenje odluka, izdavanje i sprovođenje upravnih akata, kao i pravila za komunikaciju između strana u postupku i organa koji sprovodi postupak. Iako je zakon na snazi više od pet godina, nije dosledno primenjivan od strane uprave, kao što potvrđuje",
        "t1_1": "",
        "t5_1": "",
        "t2": "Evropska komisija u svojim izveštajima.",
        "t3": "Jedan od razloga za to jeste sporo usklađivanje pravnih akata koji regulišu specifične upravne postupke sa ZOUP-om, ali i opšti nedostatak svesti o odredbama ZOUP-a i njihovoj svrsi.",
        "t4": "Kako bi se prevazišli ovi izazovi u primeni,",
        "t5": "– zajednička inicijativa OECD-a i EU – je zamoljena da izradi ovaj Komentar ZOUP-a. Komentar za svaki član pruža objašnjenje njegove regulatorne svrhe, praćeno komentarima o tome kako tumačiti svaki pravni koncept, s ciljem da se utvrdi njegova relevantnost za upravno odlučivanje u pojedinačnom slučaju. Ciljne grupe ovog Komentara su, pre svega, dve ‘strane’ koje direktno učestvuju u upravnom postupku, a to su građani i upravni praktičari u upravnim organima. Međutim, koristi od njega će takođe imati i drugi profesionalci, kao što su sudije upravnih sudova, advokati, nastavno osoblje pravnih fakulteta, treneri državnih službenika, studenti prava i novinari.",
        "t6": "Saveti za korišćenje Komentara",
        "t7": "Komentaru se može pristupiti kroz ",
        "t8": "Sadržaj",
        "t9": "koji je struktuiran prema članovima ZOUP-a.",
        "t10": "Svaki član prati objašnjenje njegove regulatorne svrhe, praćeno komentarima o tome kako se tumače odredbe u praksi, prilikom rješavanja upravnih stvari.",
        "t11": "Tekst Komentara sadrži pozivanje na relevantne spoljne izvore, kao i relevantne druge odeljke Komentara kojima se može pristupiti putem hiperlinkova radi obezbeđivanja iskustva laganog čitanja.",
        "t12": "Svi čitaoci mogu dostavljati komentare kada žele da dodaju nešto tekstu Komentara (npr. pozivanje na sudsku praksu, upravnu praksu ili korisni članak/izvor).",
        "t13": "Čitaoci takođe mogu da postavljaju pitanja ",
        "t14": "Uredničkom odboru",
        "t15": "o tekstu komentara o primeni ZOUP-a u praksi.",
        "t16": "Za odabrane članove, autori su razradili primere specifične za Kosovo, koji analiziraju kako zakon treba da se primenjuje u vrlo konkretnim situacijama iz stvarnog života.",
        "t17": "Komentar sadrži ",
        "t18": "bibliografiju",
        "t19": "koja se odnosi na spoljne materijale koji su korišćeni tokom izrade teksta Komentara.",
        "t20": "Čitaoci mogu da koriste funkciju pretrage kako bi pronašli određenu reč ili frazu koja ih zanima."
      },
      authors_page: {
        "Rusch": "",
        "t1": "Komentar su napisali Ulrih Ramzauer; Volfgang Ruš; Žani Šapo.",
        "t2": "je profesor emeritus upravnog prava na Univerzitetu u Hamburgu i penzionisani predsedavajući sudija Vrhovnog upravnog suda u Hamburgu. Godinama je bio autor redovno ažuriranog referentnog komentara na nemački zakon o opštem upravnom postupku. Nakon što se povukao kao sudija, pridružio se jednoj od vodećih nezavisnih pravnih firmi u Nemačkoj kao viši savetnik za pitanja javnog prava.",
        "t3": "Gospodin Ramzauer je bio angažovan u procesima reforme prava u inostranstvu i kod kuće i kao savetnik nemačke savezne vlade, kao i vlade i parlamenta savezne države Hamburg. Savetovao je SIGMA-u u raznim projektima izrade zakona o upravnom postupku od 2009. godine, uključujući rad na zakonodavstvu u Albaniji i na Kosovu, a bio je i član tima koautora koji su pisali 'Pravni komentar na albanski zakonik o upravnom postupku' u periodu od 2016. do 2018. godine.",
        "t4": "Za Pravni komentar Zakona o opštem upravnom postupku Republike Kosova, gospodin Ramzauer je napisao Deo VII „Upravni lekovi“ i doprineo Delu I „Opšte odredbe“.",
        "t5": "je penzionisani viši savetnik OECD/SIGMA specijalizovan za oblasti upravnog prava i izradu pravnih akata. Počeo je svoju profesionalnu karijeru kao državni službenik u Nemačkoj, a zatim je gotovo dvadeset godina predavao studentima ustavno i upravno pravo. Nakon završetka akademske karijere, bio je angažovan u raznim EU projektima za reformu javne uprave i pravosuđa, većinom implementiranim u zemljama Jugoistočne Evrope.",
        "t6": "Od 2008. godine, gospodin Ruš radi za SIGMU, gde i dalje radi kao slobodni saradnik nakon redovnog penzionisanja. U tom svojstvu bio je pre svega aktivan u promociji, planiranju i izradi zakonodavstva o upravnom postupku i upravnom pravosuđu, uključujući nacrte zakona za Albaniju i Kosovo. Njegove glavne aktivnosti poslednjih godina obuhvataju započinjanje, razvoj koncepta i koautorstvo 'Pravnog komentara na albanski zakonik o upravnom postupku', prvog takvog izdanja u zemljama Jugoistočne Evrope, kao i njegovo stalno angažovanje za vladu Egipta u oblastima 'Dobre upravne prakse' i 'Metodologija izrade dobrih zakona', ova druga uključuje Procenu uticaja propisa.",
        "t7": "Za Pravni komentar Zakona o opštem upravnom postupku Republike Kosova, gospodin Ruš je napisao Deo III „Upravni akti“, Deo VI „Obaveštenja“, Deo VIII „Izvršenje upravnog akta“ i doprineo Delu I „Opšte odredbe“. Takođe je bio odgovoran za celokupni koncept Komentara, koordinirao je suštinski rad grupe autora i obavio konačni pravni urednički posao na engleskoj verziji.",
        "t8": "je bivši viši državni službenik u Albaniji, koji sada radi u svojoj nezavisnoj advokatskoj firmi i kao profesor upravnog prava na Univerzitetu u Tirani, i član Uredničkog odbora Albanskog pravnog časopisa. Studije komparativnog prava i mnogo godina profesionalnog iskustva učinili su ga međunarodno cenjenim pravnim stručnjakom iz oblasti evropskog prava i nacionalnih sistema upravnog prava u Italiji, Nemačkoj i Francuskoj. Od 1998. godine, gospodin Šapo je više puta bio angažovan od strane EU i drugih međunarodnih agencija kao što su Svetska banka i USAID na projektima reforme javnog sektora kako u Albaniji tako i u drugim zemljama u tranziciji.",
        "t9": "Od tog vremena je takođe deo tima spoljnih eksperata OECD/SIGMA koji se redovno angažuju za sprovođenje projekata reforme u oblastima reforme zakonodavstva, upravne reforme i reforme državne službe. U periodu od 2016. do 2018. godine igrao je ključnu ulogu kao inicijator i glavni autor 'Pravnog komentara na albanski zakonik o upravnom postupku'. Pri tome je otvorio nove puteve za razvoj upravnog i pravnog sistema ne samo u Albaniji, već i za čitavu jugoistočnoevropsku regiju.",
        "t10": "Za Pravni komentar Zakona o opštem upravnom postupku Republike Kosova, gospodin Šapo je napisao Deo II „Subjekti upravnog postupka“, Deo IV „Opšta pravila upravnog postupka“, Deo V „Tok upravnog postupka“, Deo IX „Prelazne i završne odredbe“ i doprineo Delu I „Opšte odredbe“.",
        "t11": "Zahvalnost takođe ide Naseru Šamoliju za sugestije o tome kako najbolje uklopiti tekst komentara u pravni i upravni kontekst Kosova, te Manfredu Meleru na lekturi i reviziji Delova VI i VIII. Komentar je na albanski jezik preveo Ilir Marleka. Proces su koordinirali Primož Vehar i Timo Ligi iz programa SIGMA.",
        "t12": "Urednički odbor",
        "t13": "Kako je Komentar zamišljen da bude živi onlajn dokument, svaki čitalac može da dostavi komentare i pitanja Uredničkom odboru Komentara. Pitanja i komentari se mogu dostaviti sa stranica koje sadrže komentare za svaki od članova zakona. Urednički odbor obrađuje ove komentare pre nego što odluči da ih objavi. Odbor će objaviti sve komentare koji pružaju dodatne informacije o tome kako primenjivati zakon u praksi ili koji se pozivaju na primere primene zakona (upravnu praksu, sudsku praksu, akademske radove itd.). Urednički odbor će takođe pokušati da pruži odgovore na postavljena pitanja, koliko god je to moguće. Urednički odbor može odlučiti da objavi dodatne primere specifične za Kosovo, pozivanja na sudsku praksu, kao i da prilagodi tekst komentara, ako je potrebno."
      },
      post: {
        "t1": "Uloga pravnog komentara u evropskoj kontinentalnoj pravnoj tradiciji",
        "t2": "U zemljama evropskog kontinentalnog pravnog sistema, pravni komentari su uobičajeni alat svakog ko primenjuje pravo",
        "t3": "– bilo da se radi o državnom službeniku, sudiji, advokatu, običnom građaninu, profesoru prava ili studentu -",
        "t4": "koji pomaže u objašnjavanju i tumačenju zakona i drugih propisa za primenu u praktičnim slučajevima",
        "t5": "Objašnjenje pravnih tekstova je potrebno zato što se,",
        "t6": "jezik prava značajno razlikuje od lako razumljivog svakodnevnog jezika svojom apstraktnošću. Pravne norme se obično formulišu korišćenjem apstraktnih pojmova jer normativni sadržaj odredbe treba da se primeni na nepoznati broj adresata i budućih situacija ili događaja čija je konkretna manifestacija neizvesna (stoga se razlikuju od konkretnih pojedinačnih normi upravnih akata).",
        "t7": "Kao rezultat toga, čak i pravno obučeni veliki profesionalci ne mogu uvek lako da razumeju značenje formulacija koje se koriste u zakonima, njihov međusobni odnos i opšti kontekst. Komentari bi stoga trebalo da pomognu svim zainteresovanim stranama da pravilno primene apstraktne i opšte pravne norme na određeni pojedinačni slučaj.",
        "t8": "Potrebno je istaći da pravni komentar nije zvanična publikacija pravno obavezujućeg nivoa. On je zamišljen kao koristan ",
        "t9": "alat kojega mogu i treba da konsultuju ",
        "t10": "profesionalci, nudeći ubedljive, obrazložene i verodostojne pravne stavove. To objašnjava da je sasvim moguće da se u komentaru nađu dva (ili čak više) suprotstavljena mišljenja o nekom pravnom pitanju koja su oba (ili sva) možda podjednako opravdana prema pravilima logike i pravne metodologije, i da se konačna odluka o tom pravnom pitanju ostavlja korisniku.",
        "t11": "Pravni komentari su obično strukturirani prema redosledu članova zakona i njihovih stavova, prateći time redosled koji je zakonodavac već izabrao, ne proizvoljno, već prema metodološkim aspektima. U okviru objašnjenja nekog člana ili njegovih stavova, komentar odražava logički redosled njihovog regulatornog sadržaja i objašnjava pravnu posledicu i njene pravne zahteve tumačenjem svakog pojedinog pravnog pojma ili kratke fraze.",
        "t12": "Nije zamišljeno da se komentari zakona čitaju od A do Z kao roman ili udžbenik. Oni su referentna radovi, uporedivi sa enciklopedijom ili rečnikom, koje pravnik konsultuje kad god postoji pitanje ",
        "t13": "nalaženja",
        "t14": " jedne odredbe ili grupe odredaba koje bi mogle biti relevantne za pravno rešenje određenog slučaja, njegovog pravilnog ",
        "t15": "razumevanja",
        "t16": " uz pomoć pravnih metoda tumačenja i, drugim rečima, ",
        "t17": "uključivanje",
        "t18": " činjenica slučaja bez logičke greške u pravni pojam.",
        "t19": "Instrumenti za tumačenje zakona razvijeni su u pravnu metodologiju. Oni uključuju argumente iz teksta zakonske odredbe (gramatičko tumačenje), iz njenog konteksta (sistemsko tumačenje), iz njenog cilja (teleološko tumačenje) i iz zakonodavnih materijala i istorije njenog nastanka (istorijsko tumačenje).",
        "t20": "Glavni ",
        "t21": "izvor",
        "t22": " , kojim se pravni komentar bavi u cilju podupiranja svojih pravnih stavova je ",
        "t23": "sudska praksa.",
        "t24":"To podrazumeva, međutim, da se presude redovno objavljuju i što je moguće potpunije, uključujući i detaljno obrazloženje odluke. U zemljama gde je korišćenje pravnih komentara deo svakodnevne prakse, takve potpune sudske odluke ne objavljuju samo sami sudovi u zvaničnim zbornicima odluka, već se one mogu naći i u pravnoj literaturi kao što su serijska izdanja ili udžbenici, gde se ne samo citiraju, već i komentarišu, bilo uz odobravanje ili kritički, tj. sa izdvojenim pravnim mišljenjem. Argumenti u komentaru koji se mogu bazirati na sudskim odlukama imaju posebnu težinu. Ipak, u evropskom kontinentalnom pravnom prostoru, sudska odluka nema opšte obavezujuće dejstvo presedana, kao što ga poznajemo iz anglo-američkog običajnog prava. Ali u obrazloženju, posebno odluka viših sudova, mogu se naći argumenti koji se moraju uvažiti kao važni za tumačenje u drugim predmetima. Ali čak i tada, prema evropskom kontinentalnom pravnom načelu ‘legicentrizma’, sudovi moraju primenjivati kodifikovano pravo, a ne sudsku odluku kao odluku koja postavlja  presedan; odluka višeg suda samo predstavlja smernicu za tumačenje, iako vrlo značajnu, od koje se u daljoj sudskoj praksi može odstupiti samo u izuzetnim slučajevima.",
        "t24_1": "",
        "t24_2": "",
        "t25": "Dalji izvori na koje se komentar može pozivati su ",
        "t26": "pravni stavovi akademskih krugova ili administrativna praksa",
        "t27": " kako su objavljeni u monografijama, udžbenicima i časopisima. Na kraju, treba napomenuti da u zemljama sa dužom tradicijom korišćenja pravnih komentara obično postoji više od jednog komentara na specijalizovanom tržištu knjiga. Barem to važi za ključna pravna područja, kao što je opšti upravni postupak. Stoga podrška ili kritičko odbacivanje",
        "t28": "pravnog mišljenja drugog komentara",
        "t29": "o određenom pitanju tumačenja takođe je deo argumentacije za vlastiti stav.",
        "t30": "Razmatranjem različitih pravnih stavova iz različitih izvora o pitanjima tumačenja pravne odredbe, institucija pravnog komentara postaje važno sredstvo ",
        "t31": "javnog diskursa o pravu",
        "t32": "i njegovog značaja za praksu. Osim toga, ona značajno doprinosi daljem ",
        "t33": "razvoju postojećeg zakonodavstva",
        "t34": "zemlje otkrivajući i opisujući nedostatke, neodgovarajuće razvoje ili praznine postojećeg zakona ili ukazujući na potrebu za usvajanjem novih ili izmenjenih propisa.",
        "t34_1": "",
        "t34_2": "",
        "t34_3": "",
        "t34_4": "",
        "t35": "Na kraju, može se reći da je, barem do danas, institucija pravnog komentara sredstvo za funkcionisanje države u kojoj se održava vladavina prava, pri čemu će u budućnosti digitalni oblici publikacije - poput digitalnih plaćenih verzija dostupnih iz izdavačkih baza podataka ili neprofitnih komentara sa otvorenim pristupom - zauzeti svoje mesto uz tradicionalni oblik štampane knjige za dalje širenje i lakšu dostupnost.",
        "t36": "Pristup Pravnog komentara Zakona o opštem upravnom postupku Republike Kosovo",
        "t37": "",
        "t38": "Pravni komentar Zakona o opštem upravnom postupku Republike Kosovo",
        "t39": " (u daljem tekstu „Komentar“) ovde predstavljen je prvi takve vrste u praksi prava i uprave u Kosovu. Namena mu je da bude svakodnevni alat za praktičare u primeni odredbi još uvek relativno novog LGAP-a iz 2017. godine, kako bi doprineo odlukama zasnovanim na zakonu i odgovarajućim administrativnim i sudskim odlukama, olakšao rad advokata u zaštiti prava i interesa njihovih klijenata prema javnoj upravi i dublje razumevanje upravnog prava u akademskom istraživanju i nastavi. Dakle, u svom cilju, funkciji i metodološkom pristupu, Komentar odgovara, barem u velikoj meri, svojim modelima korišćenim u mnogim zemljama Evropske unije, kao što je objašnjeno gore.",
        "t40": "Objašnjenja svakog pojedinog člana ZOUP-a, njegovih stavova i pojedinačnih pravnih koncepata zasnovana su na načelima prava koja se primenjuju u EU, budući da proizilaze iz demokratije i vladavine prava i da su utvrđeni u ",
        "t41": "Povelji Evropske unije o osnovnim pravima.",
        "t42": "Osim toga, budući da ZOUP ima mnogo sličnosti sa nacionalnim pravom drugih evropskih zemalja, posebno sa uporedivim italijanskim i nemačkim upravnim pravom, Komentar se takođe poziva na publikacije iz ovih zemalja. Naposletku, primenjuju se četiri generalno primenjiva metoda pravnog tumačenja koja su prethodno pomenuta, uključujući i istorijski metod, koji istražuje pretpostavljenu nameru zakonodavca. Za korišćenje istorijskog metoda, prednost je bila što su tri autora Komentara bili angažovani kao savetnici Vlade i Parlamenta u zakonodavnom procesu.",
        "t43":"Međutim, moraju se dodati slučajevi iz prakse na Kosovu, kako relevantne sudske odluke tako i stvarne upravne odluke od suštinskog značaja, kao i akademski članci u časopisima o pravu i javnoj upravi. Do sada je ovde postojala nestašica odgovarajućeg materijala. Međutim, to mora i moraće da se nadoknadi u toku daljeg razvoja komentara, koji je po svojoj prirodi kontinuirani proces.",
        "t44": "",
        "t45": "Kontinuirano ažuriranje",
        "t46": " Komentara olakšava digitalni oblik publikacije, inovacija koja ga stavlja ispred tradicionalnih dela u drugim evropskim zemljama i čini ga ",
        "t47": "interaktivnim sredstvom komunikacije.",
        "t48": " Korisnici su pozvani da dostavljaju komentare, sugestije za poboljšanje ili ukazuju na netačnosti u tekstu, posebno kako bi pružili slučajeve sudskih i administrativnih praksi kako je gore navedeno. Takođe se mogu postavljati pravna pitanja koja se odnose na tekst Komentara i primenu LGAP-a u praksi, koja će biti razmotrena i odgovorena od strane ",
        "t49": "Uredničkog odbora",
        "t50": "u razumnom roku.",
        "t51": "Digitalni oblik takođe omogućava ",
        "t52":"pretragu funkcija",
        "t53": "za određene termine ili fraze i reference na relevantne spoljne izvore, kao i na relevantne druge sekcije Komentara koje se mogu pristupiti putem hiperlinkova kako bi se obezbedilo glatko iskustvo čitanja.",
        "t54": "Naposletku, Komentar sadrži ",
        "t55": "bibliografiju",
        "t56": "koja se odnosi na spoljne materijale korišćene tokom izrade teksta Komentara."
      },
      "article-abbreviation": "Član",
      "footnotes": "Fusnote",
      "case law section": "Odeljak o sudskoj praksi",
      "bibliography section": "Odeljak sa bibliografijom",
      "kosovo examples section": "Odeljak sa primerima iz Kosova",
      "contact us": "Kontaktirajte nas",
      "email sent": "Potvrda putem e-pošte je poslata",
      "al": "Albanski",
      "sr": "Srpski",
      "en": "Engleski",
      "by": "Od",
      "email": "E-pošta",
      "phone": "Broj telefona",
      "occupation": "Zanimanje",
      "socialMedia": "Linkovi društvenih mreža",
      "Welcome to React": "Dobrodošli u React i react-i18next",
      "chapter": "Poglavlje ",
      "division": "Divizija ",
      "contents": "Sadržaj",
      "authors": "Autori",
      "bibliography": "Bibliografija",
      "keyword": "Ključna reč",
      "executive issue": "Izvršni broj",
      "the constitution of the republic of Kosovo": "Ustav Republike Kosova",
      "executive edition": "Izvršno izdanje",
      "read the preface": "Pročitajte predgovor",
      "birth of the constitution": "Rojenje Ustava",
      "digital newsletter": "Digitalni bilten",
      "paper edition": "Papirno izdanje",
      "supporter": "Podržavač",
      "editorial board": "Urednički odbor",
      "privacy policy": "Politika privatnosti",
      "terms of use": "Uslovi korišćenja",
      "login": "Prijavi se",
      "register": "Registruj se",
      "download": "Preuzmi",
      "email address": "Adresa e-pošte",
      "password": "Lozinka",
      "remember me": "Zapamti me",
      "forgot password": "Zaboravljena lozinka",
      "sign in": "Prijavi se",
      "don't have an account?": "Nemate nalog?",
      "change password": "Promeni lozinku",
      "old password": "Stara lozinka",
      "new password": "Nova lozinka",
      "confirm password": "Potvrdi lozinku",
      "first name": "Ime",
      "last name": "Prezime",
      "telephone number": "Broj telefona",
      "sign up": "Registruj se",
      "bibliography of the constitution of the republic of Kosovo": "Bibliografija Ustava Republike Kosova",
      section: "Divizija ",
      previous: "Prethodni",
      next: "Sledeći",
      "edit publishing": "Izmeni objavljivanje",
      "show history": "Prikaži istoriju",
      "pending comments": "Nerealizovani komentari",
      "approved comments": "Odobreni komentari",
      "pending questions": "Nerealizovana pitanja",
      "approved questions": "Odobrena i odgovorena pitanja",
      "rejected questions": "Odbačena pitanja",
      "go to": "Idi na",
      "questions section": "Odeljak sa pitanjima",
      "comments section": "Odeljak sa komentarima",
      article: "Član ",
      part: "Deo ",
      partCapitalized: "DEO ",
      content: "Sadržaj",
      search: "Pretraga",
      logout: "Odjava",
      "invalid credentials": "Nevažeći podaci",
      "please write your password": "Molimo vas da unesete lozinku",
      "please write your email address": "Molimo vas da unesete adresu e-pošte",
      "please write your first name": "Molimo vas da unesete svoje ime",
      "please write your last name": "Molimo vas da unesete svoje prezime",
      "please write your telephone number": "Molimo vas da unesete broj telefona",
      "signing in": "Prijavljivanje",
      "logged in": "Prijavljeni ste",
      history: "Istorija",
      save: "Sačuvaj",
      comments: "Komentari",
      questions: "Pitanja",
      "see draft version": "Pogledaj verziju nacrta",
      visitors: "Posetioci",
      open: "Otvori",
      version: "Verzija",
      "edit the text": "Izmeni tekst",
      text: "Tekst",
      close: "Zatvori",
      "save changes": "Sačuvaj izmene",
      "write a comment or question": "Napiši komentar ili pitanje",
      "your comment/question...": "Vaš komentar/pitanje...",
      "your first name": "Vaše ime",
      "your last name": "Vaše prezime",
      "your phone number": "Vaš broj telefona",
      "your email": "Vaša e-pošta",
      "phone number": "Broj telefona",
      "show my personal info": "Prikaži moje lične podatke",
      "select your type of interaction": "Izaberite vrstu interakcije:",
      comment: "Komentar",
      question: "Pitanje",
      cancel: "Otkaži",
      submit: "Pošalji",
      optional: "opciono",
      "rejected comments": "Odbačeni komentari",
      "please check the fields": "Molimo vas da proverite polja",
      "submited successfully": "Uspešno poslato",
      "not implemented yet": "Još uvek nije implementirano",
      "update successfully": "Uspešno ažurirano",
      "something went wrong, please try again!": "Došlo je do greške, molimo vas da pokušate ponovo!",
      "legal commentary": "Pravni komentar Zakona o opštem upravnom postupku Republike Kosova",
      "make question or comment": "Dodajte pitanje ili komentar",
      "previous article": "Prethodni članak",
      "next article": "Sledeći članak",
      "go to approved comments": "Idi na odobrene komentare",
      "go to approved questions": "Idi na odobrena pitanja",
      "go to rejected comments": "Idi na odbačene komentare",
      "go to rejected questions": "Idi na odbačena pitanja",
      "delete comment": "Obriši komentar",
      "or system admin": "ili administrator sistema",
      "no questions yet": "Još uvek nema pitanja",
      "no comments yet": "Još uvek nema komentara",
      "anonymous": "Anonimno",
      "for article": "Za članak",
      "no matches": "Nema podudaranja",
      "refresh": "Osveži",
      "social media link": "Link ka društvenim mrežama",
      "please provide a reply!": "Molimo vas da pružite odgovor!",
      "replied to question": "Odgovoreno na pitanje",
      "server error, please try again!": "Greška na serveru, molimo vas da pokušate ponovo!",
      "please select a reason first!": "Molimo vas da prvo izaberete razlog!",
      "question rejected": "Pitanje odbačeno",
      "question deleted": "Pitanje obrisano",
      "replied": "Odgovoreno",
      "article search": "Pretraga članaka",
      "approved": "Odobreno",
      "no pending question": "Nema nerealizovanih pitanja",
      "no approved question": "Nema odobrenih pitanja",
      "delete question": "Obriši pitanje",
      "no rejected requests for a question": "Nema odbačenih zahteva za pitanje",
      "at": "Na",
      "does not want to show personal info!": "Ne želi da prikaže lične informacije!",
      "write your reply below": "Napišite svoj odgovor ispod",
      "reply": "Odgovor",
      "reject": "Odbij",
      "if you wish to reject, you have to select one of the options below": "Ako želite da odbijete, morate izabrati jednu od opcija ispod",
      "bad language": "Vulgarnost",
      "not understandable": "Nerazumljivo",
      "not relevant": "Nije relevantno",
      "other": "Drugo",
      "comment approved": "Komentar odobren",
      "comment deleted": "Komentar obrisan",
      "no pending requests for comments": "Nema nerealizovanih zahteva za komentare",
      "no approved comments": "Nema odobrenih komentara",
      "no rejected comments": "Nema odbačenih komentara",
      "approve": "Odobri",
      "search results appear below": "Rezultati pretrage se pojavljuju ispod",
      "no search results": "Nema rezultata pretrage",
      "you are in edit mode": "Nalazite se u režimu za izmenu",
      "copy the link": "Kopirajte link",
      "enter link": "Unesite link",
      "commentary content": "Sadržaj Komentara",
      "enter the word": "Unesite reč koja treba da postane link",
      "previous versions": "Prethodne verzije",
      "no footnotes yet": "Nema fusnota",
      "no Kosovo examples yet": "Nema primera iz Kosova",
      "message": "Poruka",
      "name": "Ime",
      "send": "Pošaljite",
      "copy and paste the link": "Da biste kreirali link, kopirajte link i zamenite reči koje želite da postanu linkovi kopiranim linkom u tekstu iznad."
    },
  },

}
