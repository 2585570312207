import React from "react";
import styles from './Sidebar.module.scss'
import { EditButton } from "../EditButton/EditButton";
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from "react-i18next";

export const Article = ({ item, draftView }) => {

    const { t } = useTranslation();

    return <>
        <h5 className={styles.articleTitle} style={{
            padding: '15px 10px',
            borderBottom: '1px solid lightgray',
            backgroundColor: 'white ',
            position: draftView ? 'static':'sticky'
        }}>
            {`${t('article-abbreviation')} ${item.number>0 && item.number}`} <span className={styles.articleNumber} id={item.id}>{draftView && item.newText ?
                <>
                     {ReactHtmlParser(item.newText)}
                </>
                :
                <>
                    {ReactHtmlParser(item.text)}
                </>

            }</span>

            {!draftView && <EditButton item={item} />}
        </h5>

    </>
}