import React from "react";
import styles from './Sidebar.module.scss'
import { useTranslation } from 'react-i18next';

import convertToRoman from "./helpers/romanNumberConverter";
import ReactHtmlParser from 'react-html-parser';
import { EditButton } from "../EditButton/EditButton";

export const Part = ({ item, onClick, draftView }) => {
    const { t } = useTranslation();
    return <>
        <h2 className={styles.partTitle}> {t('part')} {item.number > 0 && convertToRoman(item.number)}</h2>
        <div id={item.id} className={styles.partTextContentPage} style={{
            textAlign: 'center',
        }}>
            <h2 style={{ fontSize: '1.5rem' }}>
                {draftView && item.newText ?
                    ReactHtmlParser(item.newText)
                    :
                    ReactHtmlParser(item.text)
                }

                {!draftView && <EditButton item={item} />}
            </h2>
        </div>
    </>
}