import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AddButton } from "../AddButton/AddButton";
import styles from './Bibliography.module.scss'
import ReactHtmlParser from 'react-html-parser';

export const Bibliography = ({ documentNumber, documentId }) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false)

    const [biblios, setBiblios] = useState(null);
    const [currentDoc, setCurrentDoc] = useState(documentNumber)

    useEffect(() => {
        if (!biblios || currentDoc !== documentNumber) {
            axios.get(`bibliography/${documentNumber}?language=${i18n.language}`).then(res => {
                setBiblios(res.data.result || [])
            })
        }

        if (currentDoc !== documentNumber)
            setCurrentDoc(documentNumber)
    },[biblios, documentNumber])
    return (
        <div className="card text-justify">
            <h4 className='card-header' onClick={() => setOpen(!open)}
                aria-controls="bibliography-collapse-text"
                aria-expanded={open} >{t('bibliography section')} ({biblios?.length || 0})</h4>

            <Collapse in={open}>
                <div className="card-body" id="bibliography-collapse-text">
                    {biblios &&
                        biblios.map((biblio, index) => {
                            return <Col key={index} sm={12} lg={8} className='d-flex flex-column align-items-end'>
                                <Col className={classNames(styles.comment, 'p-3 col-12')} >

                                    {/* <p className={styles.paragraph}> */}
                                        {ReactHtmlParser(biblio.text)}
                                    {/* </p> */}

                                    <p className={classNames(styles.paragraph, styles.italicFont)}>{biblio.createdAt.split("T")[0]} {biblio.createdAt.split("T")[1].split('.')[0]}</p>
                                </Col>
                            </Col>
                        })
                    }
                    {
                        biblios &&
                        biblios.length == 0 &&
                        <p className={styles.paragraph}><em>{t('no bibliography yet')}</em></p>
                    }
                    <AddButton path={'bibliography/'} itemId={documentId} updateField={()=>setBiblios(null)} />
                </div>
            </Collapse>

            
        </div>
    )
}