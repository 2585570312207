import React from 'react'
// import styles from '../Content/Content.module.scss';
import styles from './EditPublishing.module.scss';
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import contents from '../../assets/contents/contents.json'

import { useSelector } from "react-redux";
import { Button } from 'react-bootstrap';


export const EditPublishing = () => {
    const paragrafi = useSelector((state) => state.user.token)



    const { t } = useTranslation();
    return (
        <div>
            <div className={'container p-0 d-flex justify-content-end'}>
                <div className={'col-12 col-md-9 p-3'} >
                    <div className={styles.chapterTitle} style={{ position: 'relative' }}>

                        <div style={{ position: 'absolute', right: 0 }}>

                            <button type="button" className="btn btn-warning mx-2">{t('you are in edit mode')}</button>

                            <button type="button" className="btn btn-primary">{t('show history')}</button>
                        </div>


                        <h2 style={{ fontWeight: '400' }}>{t('chapter')} I</h2>
                        <h2 style={{ fontWeight: '700' }}>{contents[0].name}</h2>


                    </div>

                    <div >

                        <textarea id="w3review" name="w3review" rows="10" cols="100" value={paragrafi}>
                        </textarea>
                        {/* <textarea id="w3review" name="w3review" rows="10" cols="100" > {{ paragrafi }}
                        </textarea> */}
                    </div>

                    <div style={{ position: 'relative' }}>

                        <div style={{ position: 'absolute', right: 0, marginBottom: 10 }}>

                            <Button className="mx-2">Save</Button>
                            <Link to={'/content'} variant='danger'><Button variant='danger'>Cancel</Button></Link>
                        </div>

                        <div style={{ marginBottom: 100 }}>
                            .
                        </div>

                    </div>


                    {/* <hr style={{ margin: '3vw 0' }} />

                <div className={styles.chapterAuthors}>
                    <h5>{t('authors')}</h5>
                    <p>Lasse Lehis, Kaspar Lind</p>
                </div> */}

                    {/* <hr style={{ margin: '3vw 0' }} /> */}

                    {/* <div>
                    <h4 className={styles.articleTitle}>Article {contents[0].articles[0].number} - {contents[0].articles[0].title}</h4>

                    <p style={{ color: 'red' }}>{contents[0].articles[0].articleDescription}</p>


                    {contents[0].articles[0].subarticles.map((sub) => {
                        return <div>
                            <h4 className={styles.subArticleTitle} >{sub.title}</h4>


                            {sub.paragraphs && <div>
                                {sub.paragraphs.map(sub => {
                                    return <p>{sub.text}</p>
                                })}
                            </div>
                            }

                            {sub.subArticleDescription && <div>
                                {sub.subArticleDescription.map(subarticle => {
                                    return <div>
                                        <p style={{ fontWeight: 'bold' }}><i className="bi bi-caret-right-fill"></i> {subarticle.title}</p>
                                        {subarticle.paragraphs.map(paragraph => {
                                            return <p className={'ms-4'} style={{ marginLeft: 'auto', marginRight: 0 }} >{paragraph}</p>
                                        })}
                                    </div>
                                })}
                            </div>} 
                        </div>
                    })}

                </div> */}
                </div>
            </div>
        </div>
    )
}

