import axios from "axios";
import classNames from "classnames";
import React, { useState } from "react";
import { Col, Collapse, Spinner } from "react-bootstrap";
import { Article } from "../ContentSidebar/Article";
import styles from './ArticleTransition.module.scss'
import ReactHtmlParser from 'react-html-parser';

export const ArticleBibliographyTransition = ({ item }) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [biblios, setBiblios] = useState(null)

    const getBiblios = () => {
        if (open) {
            setOpen(false)
            return
        }
        if(biblios){
            setOpen(true)
            return
        }
        setLoading(true)
        axios.get(`documents/article/${item.id}/bibliographies`).then(res => {
            setBiblios(res.data.result)
            setOpen(true)
            setLoading(false)
        })
    }

    return <>
        <div className="position-relative" onClick={getBiblios}>
            <Article item={item} draftView={true} />
            {loading && <Spinner className={classNames(styles.loadingElement, "position-absolute end-0")} animation="border" variant="info" />}
        </div>

        <Collapse in={open}>
            <div>
                {biblios && biblios.length > 0 &&
                    biblios.map((biblio, index) => {
                        // return <ContentWrapper draftView={true} key={index} item={contentItem} />
                        return <Col key={index} sm={12} lg={8} className='d-flex flex-column align-items-end'>
                            <Col className={classNames(styles.comment, 'p-3 col-12')} >

                                {ReactHtmlParser(biblio.text)}

                                <p className={classNames(styles.paragraph, styles.italicFont)}>{biblio.createdAt.split("T")[0]} {biblio.createdAt.split("T")[1].split('.')[0]}</p>
                            </Col>
                        </Col>
                    })
                }
                {
                    biblios && biblios.length === 0 && <p>No Bilbiography for this article</p>
                }
            </div>
        </Collapse>
    </>
}