import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "user",
  initialState: {
    token: null,
    isAuthenticated: false,
    userData: { email: "", role: "", id: "", userName: "" },
  },
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payloads;
    },
    login: (state, action) => {
      state.token = action.payload
      state.isAuthenticated = true
      // loginApi(payload).then((response) => {
      //   if (response.status !== 200) {
      //     throw new Error("Error encountered!");
      //   }

      //   if (response.data.result.accessToken) {
      //     const { accessToken, refreshToken } = response.data.result;
      //     const decodedToken = jwtDecode(accessToken);
      //     console.log(decodedToken)
      //     state.isAuthenticated = true;
      //     state.userData = {
      //       email: decodedToken.email,
      //       role: decodedToken.role,
      //       id: decodedToken.sub,
      //       userName: decodedToken.userName,
      //     };
      //     const cookie = new Cookie();
      //     cookie.set(
      //       "auth",
      //       { accessToken, refreshToken },
      //       {
      //         domain: process.env.REACT_APP_DOMAIN,
      //         pathname: "/",
      //       }
      //     );
      //   }
      // });
    },
    logout: (state, action) => {
      state.token=null
      state.isAuthenticated=false
    }
  },
});

export const { updateToken, login,logout } = UserSlice.actions;
export default UserSlice.reducer;
