import React from "react";
import styles from './Sidebar.module.scss'
import classNames from 'classnames'
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideSidebarMobile } from '../../redux/sidebarSlice'
import { useTranslation } from "react-i18next";

export const ArticleSideBar = ({ item }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return <div >
    <Link to={`/article?articleNumber=${item.number}`} onClick={()=>dispatch(hideSidebarMobile())} className={classNames(styles.chapter, styles.articleSideBar) }>
      <h4 >
      {t('article-abbreviation')} {item.number}. {item.text}
      </h4>
    </Link>

  </div>
}