import axios from "axios";
import React from "react";
import { useState } from "react";
import { Button, FormGroup } from "react-bootstrap";

export const FootnoteItem = ({ footnote, selectFootnote, update }) => {
    const [text, setText] = useState(footnote.text)
    const [openEdit, setOpenEdit] = useState(false)

    const updateFootnote = async() => {
        let footnoteTemp = footnote
        footnoteTemp.text = text
        await axios.put('footnotes/' + footnote.id, footnoteTemp).then(() => {
            update()
        })
    }

    const deleteFootnote = async() => {
        await axios.delete('footnotes/' + footnote.id).then(() => {
            update()
        })
    }
    return <>
        <p className="border mt-2 p-2" onClick={() => selectFootnote(footnote)}>{footnote.number}. {footnote.text}</p>
        <div>
            <Button variant='info' onClick={()=>setOpenEdit(!openEdit)}>Edit</Button>
            <Button variant='danger' className="m-2" onClick={deleteFootnote}>Delete</Button>
        </div>
        <hr />
        {openEdit &&
            <div>
                <FormGroup className="mb-2 mt-2">
                    <textarea type="text" name="" id="" className='form-control' cols="10" rows="1" placeholder="eg. this is some text for footnote..." value={text} onChange={e => { setText(e.target.value) }}></textarea>
                </FormGroup>
                <Button type="button" onClick={updateFootnote}>Edit footnote</Button>
            </div>
        }
    </>
}