import React from "react";
import styles from './Footer.module.scss';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import fileAl from '../../assets/commentaryDocuments/Komentar_LPPA.pdf'
import fileEn from '../../assets/commentaryDocuments/LGAP_Commentary_en.pdf'
import { useSelector } from "react-redux";
import classNames from "classnames";
const logoSigma = require("../../assets/images/logo1.jpg")

export const Footer = () => {
    const { t, i18n } = useTranslation();
    const { structure: documentStructure } = useSelector((state) => state.document);
    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = i18n.language === 'en' ? fileEn : fileAl;
        link.download = i18n.language === 'en' ? 'LGAP_Commentary_en.pdf' : 'Komentar_LPPA.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return <footer className={classNames(styles.footer, documentStructure ? '':'fixed-bottom')}>
        <div className={'container p-0'}>

            <div className="row m-0  d-flex justify-content-between">
                <div className={'col-md-4 p-3 text-center'}>
                    <p>{t('legal commentary')}</p>
                </div>
                <div className={'col-md-2 col-6 p-3'}>
                    <button onClick={downloadFile} type="button" className="btn btn-light col-12">{t('download')}</button></div>
                <div className={'col-md-2  col-6 p-3'}>

                    <Link to={'/contact-us'} className="btn btn-light col-12">{t('contact us')}</Link>

                </div>
            </div>
            <div className={styles.copyrightsContainer}>
                <p>Copyrights &copy;  <img src={logoSigma} className={styles.logoImage} /></p>
            </div>

        </div>


    </footer>
}