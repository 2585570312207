import React from "react";
import styles from './Sidebar.module.scss'
import ReactHtmlParser from 'react-html-parser';

import { EditButton } from "../EditButton/EditButton";

export const Paragraph = ({ item, draftView }) => {



    return <>
        <div className={styles.paragraphWrapper} style={{ display: 'flex' }}>
            <p className={styles.paragraphNumber} style={{
                marginRight: '30px',
                paddingLeft: '5px',
            }}>{item.number > 0 && item.number}</p>
            <p id={item.id} >
                {draftView && item.newText ?
                    ReactHtmlParser(item.newText)
                    :
                    ReactHtmlParser(item.text)
                }
                {!draftView && <EditButton item={item} />}
                {/* <i className={classNames('bi bi-chat-dots-fill', styles.commentIcon)} onClick={handleCommentsModal}></i> */}
            </p>
            {/* <CommentPopUpModal itemId={item.id} /> */}
        </div>

        {/* <Toolkit /> */}
    </>
}