import axios from "axios";
import classNames from "classnames";
import React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import styles from './Style.module.scss'
import { LoadingScreen } from "../../components/LoadingComponent/LoadingSpinner";
import { Link } from "react-router-dom";
import { Reply } from "../../components/Reply/Reply";

export const ApprovedQuestions = () => {
    const { t, i18n } = useTranslation();

    const [requestData, setRequestData] = useState(null)

    useEffect(() => {
        if (!requestData)
            axios.get('questions').then(res => {
                setRequestData(res.data.result.map(item => ({ ...item, type: 'question' })))
            })
    }, [requestData])

    return <>{!requestData ?
        <LoadingScreen />
        :
        <div className='container col-md-8'>
            <ToastContainer />
            <Link className={styles.link} to='/content/questions'>
                <Button >{t('go to pending questions')}</Button>
            </Link>
            <Link className={styles.link} to='/content/rejected/questions'>
                <Button>{t('go to rejected questions')}</Button>
            </Link>

            <h2 className='my-3'>{t('approved questions')}</h2>

            {
                requestData.filter(question => question.approved && question.document.language == i18n.language).sort((a,b) => (a.document.number > b.document.number) ? 1 : ((b.document.number > a.document.number) ? -1 : 0)).map((question) => {
                    return <> <div className={styles.file}>
                        <div className={styles.fileInfo}>
                            <i className={classNames(styles.commentIcon, "bi bi-question-square")}  ></i>

                            <div className='d-flex flex-column'>
                                <div>

                                    <p className={styles.commentText}>{question.text}</p>
                                </div>
                                <div>
                                    <p className={styles.commentText}>Article: {question.document.number}</p>
                                </div>

                                <div className='d-flex flex-column'>
                                    <p className={classNames(styles.Button, styles.commentUsername)}><b>{t('by')}:</b> {question.userName}</p>
                                    <p className={styles.commentTimeCreated}><b>at:</b> {question.createdAt.split("T")[0]} {question.createdAt.split("T")[1].split('.')[0]}</p>
                                    <p className={styles.commentEmail}> <b>{t('email')}:</b> {question.email}</p>
                                    {question.telephone &&
                                        <p> <b>{t('phone')}:</b> {question.telephone}</p>}

                                    {question.occupation &&
                                        <p> <b>{t('occupation')}:</b> {question.occupation}</p>}

                                    {question.socialMedia &&
                                        <p> <b>{t('socialMedia')}:</b> {question.socialMedia}</p>}
                                </div>
                            </div>
                        </div>



                    </div>
                        {question.replies?.length > 0 &&
                            question.replies.map((reply, index) => {
                                return <Reply reply={reply} key={index} refresh={()=>setRequestData(null)} />
                            })

                        }
                    </>

                })
            }

            {
                requestData.filter(question => question.approved).length == 0 &&
                <p><em>{t('no approved question')}</em></p>
            }

        </div>
    }
    </>
}
