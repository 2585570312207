import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.scss";
import { LabelInput } from "../CustomInput/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { PartSideBar } from "./PartSideBar";
import { expandSidebar, shrinkSidebar } from "../../redux/sidebarSlice";
import classNames from "classnames";
import { LoadingScreen } from "../LoadingComponent/LoadingSpinner";
import { ArticleSideBar } from "./ArticleSideBar";
import { useTranslation } from "react-i18next";

export const ContentSidebar = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  // const [documentStructure, setDocumentStructure] = useState(null)
  const { structure: documentStructureFromRedux, loadingDocument } =
    useSelector((state) => state.document);
  const documentLanguage = useSelector((state) => state.document.language);
  const mobileVersion = useSelector((state) => state.sidebar.mobileSidebar);
  const [showingDocumentStructure, setShowingDocumentStructure] = useState([]);
  const [sortedDocs, setSortedDocs] = useState(documentStructureFromRedux);

  const setSortedDocumentRefresh = () => {
    const documentArray = [...documentStructureFromRedux];
    setSortedDocs(
      documentArray?.sort((a, b) =>
        a.number > b.number
          ? 1
          : a.number === b.number
          ? a.number > b.number
            ? 1
            : -1
          : -1
      )
    );
  };

  useEffect(() => {
    if (
      documentStructureFromRedux &&
      (!sortedDocs || currentLanguage !== i18n.language)
    ) {
      setSortedDocumentRefresh();
    }
    if (currentLanguage !== i18n.language) setCurrentLanguage(i18n.language);
  }, [sortedDocs, documentStructureFromRedux, currentLanguage]);

  useEffect(() => {
    if (documentStructureFromRedux) setSortedDocumentRefresh();
  }, [documentStructureFromRedux]);

  const [goTo, setGoTo] = useState(null);

  const searchContent = (item, found = []) => {
    let searchText = goTo;
    if (
      item.type == 2 &&
      item.number.toString() == searchText.toLocaleLowerCase()
    ) {
      found.push(item);
    }

    if (item.children && item.children?.length > 0) {
      for (var child of item.children) {
        found = searchContent(child, found);
      }
    }
    return found;
  };

  useEffect(() => {
    if (goTo && documentStructureFromRedux) {
      let found = [];
      documentStructureFromRedux.filter((item) => {
        if (searchContent(item, found)) {
          return item;
        }
        return false;
      });
      setShowingDocumentStructure(found);
    } else setShowingDocumentStructure([]);
  }, [goTo, documentStructureFromRedux]);

  const isMobile = window.outerWidth <= 600;
  const shouldShow = (isMobile && mobileVersion) || !isMobile;

  return loadingDocument ? (
    <>
      <LoadingScreen />
    </>
  ) : (
    shouldShow && (
      <div
        className={classNames(styles.container, styles.overflow)}
        onMouseEnter={() => dispatch(expandSidebar())}
        onMouseLeave={() => dispatch(shrinkSidebar())}
      >
        <div className={styles.closeButtonWrapper}>
          <p className={styles.titleContent}>{t("content")}</p>
        </div>
        <div className={styles.gotoLabel}>
          <LabelInput label={t("article search")} onChange={setGoTo} />
        </div>
        {goTo ? (
          showingDocumentStructure.length > 0 ? (
            <>
              <h4>{t("article")}:</h4>
              <ArticleSideBar item={showingDocumentStructure[0]} />
            </>
          ) : (
            <h4>{t("no matches")}</h4>
          )
        ) : (
          documentStructureFromRedux && sortedDocs?.map((part, index) => {
            return <PartSideBar key={index} item={part} />;
          })
        )}
      </div>
    )
  );
};
