import React from "react";
import { Post } from "../../components/Post/Post";
import styles from './Home.module.scss'
import { ToastContainer } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

export const HomePage = () => {
    const { t } = useTranslation();

    return <main className={styles.main}>
        {/* <Container className={styles.mainContainer}> */}
        {/* </Container> */}

        <Post />


        <div className="text-justify container p-3 col-md-6">

            <h4 className=" m-0">{t('legal commentary')}</h4>


            <div className="row m-0">
                {/* <div className="col-md-4 p-3"><iframe className="col-12"
                    src="https://www.youtube.com/embed/nxillLkPoJU">
                </iframe></div> */}
            </div>

            <hr />
            <div>
                <p>{t('home_page.t1')} <br />{t('home_page.t1_1')}  <a href="https://neighbourhood-enlargement.ec.europa.eu/kosovo-report-2022_en" target='_blank'>{t('home_page.t2')}</a> {t('home_page.t3')}  </p>

                <br />
                <p>{t('home_page.t4')} &nbsp;<a href="https://sigmaweb.org/" target='_blank'>SIGMA</a> {t('home_page.t5')} <br /> {t('home_page.t5_1')}
                </p>
            </div>
            <div>
                <h4>{t('home_page.t6')}</h4>
                <ul>
                    <li>{t('home_page.t7')} <Link to={'/content'}>{t('home_page.t8')}</Link> {t('home_page.t9')}  </li>
                    <li>{t('home_page.t10')}</li>
                    <li>{t('home_page.t11')}</li>
                    <li>{t('home_page.t12')}
                    </li>
                    <li>{t('home_page.t13')}<Link to={'/authors'}>{t('home_page.t14')}</Link> {t('home_page.t15')} </li>
                    <li>{t('home_page.t16')}</li>
                    <li>{t('home_page.t17')}<Link to={'/bibliography'}>{t('home_page.t18')}</Link> {t('home_page.t19')} </li>
                    <li>{t('home_page.t20')}
                    </li>
                </ul>
            </div>
        </div>

        <ToastContainer />
        {/* <ContentSidebar /> */}
    </main>
}