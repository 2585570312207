import { createSlice } from "@reduxjs/toolkit";

const SidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    expanded: false,
    mobileSidebar: false,
    goto: null,
  },
  reducers: {
    expandSidebar: (state) => {
      state.expanded = true;
    },
    shrinkSidebar: (state) => {
      state.expanded = false;
    },
    showSidebarMobile: (state) => {
      state.mobileSidebar = true;
    },
    hideSidebarMobile: (state) => {
      state.mobileSidebar = false;
    },
    setGoTo: (state, action) => {
      state.goto = action.payload;
    },
    toggleSidebarMobile: (state) => {
      state.mobileSidebar = !state.mobileSidebar;
    },
  },
});

export const {
  expandSidebar,
  toggleSidebarMobile,
  shrinkSidebar,
  showSidebarMobile,
  hideSidebarMobile,
  setGoTo,
} = SidebarSlice.actions;
export default SidebarSlice.reducer;
