import React from 'react'
import styles from './Section.module.scss';
import { useTranslation } from 'react-i18next';

export const Section = () => {
    
    const { t } = useTranslation();

    return (
        <div className={'container p-0 d-flex justify-content-end'}>
            <div className={'col-12 col-md-9 p-3'}>
                <div className={styles.sectionTitle}>
                    <h2>{t('section')} 8</h2>
                </div>
                <p>to be added</p>
                
            </div>
        </div>
    )
} 