import axios from "axios";
import React, { useState } from "react";
import { Modal, Button, FormLabel, FormGroup, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { updateModal, updateNewDescription, updateNewText } from "../../redux/popUpModalSlice";
import { ToastContainer, toast } from 'react-toastify';
import { setDocument, updateDocumentFetchTime } from "../../redux/documentSlice";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { FootnoteItem } from "./FootnoteItem";

export const PopUpModal = () => {
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const articleId = useSelector(state => state.document.articleIdSelected)
    const articleNumberSelectedInPage = useSelector(state => state.document.articleNumberSelected)

    const [externalLink, setExternalLink] = useState('')
    const [internalLink, setInternalLink] = useState('')
    const [action, setAction] = useState(null)

    const [articleNumber, setArticleNumber] = useState('')
    const [marginNumber, setMarginNumber] = useState('')
    const [footnoteSelected, setFootnoteSelected] = useState('')
    const [wordForTheLink, setWordForTheLink] = useState('')
    const [generatedLink, setGeneratedLink] = useState('')
    const [addNewFootnote, setAddNewFootnote] = useState(false)
    const [showFootnotesList, setShowFootnotesList] = useState(false)
    const [footnotesList, setFootnotesList] = useState(null)
    const [footnoteValue, setFootnoteValue] = useState('')
    // const [loading, setLoading] = useState(true)

    const dispatch = useDispatch();
    const modalText = useSelector(state => state.popUpModal.text)
    const modalDescription = useSelector(state => state.popUpModal.description)
    const show = useSelector(state => state.popUpModal.show)

    const object = useSelector(state => state.popUpModal)

    const hideModal = () => {
        dispatch(updateModal({ type: 'show', value: false }))
        dispatch(updateModal({ type: 'text', value: '' }))
        dispatch(updateModal({ type: 'description', value: '' }))
        setGeneratedLink(null)
        setExternalLink(null)
        setWordForTheLink(null)
    }

    const saveChanges = () => {
        const data = {
            text: object.text,
            type: object.type,
            number: object.number,
            children: object.children
        }
        axios.put('documents/' + object.documentId, data).then(res => {
            toast.success(t('update successfully'))
            axios.get('documents/tree?language=' + i18n.language).then((res) => {
                dispatch(setDocument(res.data.result.children));
                dispatch(updateDocumentFetchTime());
            });
            hideModal()
        }).catch(() => {
            toast.error(t('something went wrong, please try again!'))
        })
    }

    useEffect(() => {
        setCopySuccess('')
        if (wordForTheLink && externalLink) {
            let el = `<a target="_blank" href="${externalLink}">${wordForTheLink}</a>`
            setGeneratedLink(el)
        }
        else if (wordForTheLink && marginNumber) {
            let el = `<a target="_blank" href="article/?articleNumber=${articleNumber}${marginNumber ? `&marginNumber=${marginNumber}` : ''}">${wordForTheLink}</a>`
            setGeneratedLink(el)
        }
        else if (footnoteSelected) {
            let el = `<sup><a href="?articleNumber=${articleNumberSelectedInPage}#footnote-${footnoteSelected.id}">${footnoteSelected.number}</a></sup>`
            setGeneratedLink(el)
        }
        else {
            setGeneratedLink('')
        }
    }, [wordForTheLink, externalLink, marginNumber, articleNumber, footnoteSelected])

    useEffect(() => {

        return () => {
            setGeneratedLink(null)
            setExternalLink(null)
            setWordForTheLink(null)
            setFootnotesList(null)
            setFootnoteValue('')
        }
    }, [])

    useEffect(() => {
        if (!footnotesList) {
            axios.get(`footnotes/${searchParams.get('articleNumber')}`).then(res => {
                setFootnotesList(res.data.result || [])
            })
        }
    }, [footnotesList])

    useEffect(()=>{
        setGeneratedLink(null)
        setWordForTheLink('')
        setInternalLink(null)
        setExternalLink(null)
        setFootnoteSelected(null)
        setArticleNumber(null)
        setMarginNumber(null)
    },[action])


    const textAreaRef = useRef(null);
    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboard = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess('Copied!');
    };

    const saveNewFootnote = () => {
        axios.post('footnotes/' + articleId, {
            text: footnoteValue,
            number: footnotesList ? footnotesList.length + 1 : 1
        }).then((res) => {
            setFootnoteSelected(res.data.result)
            setFootnotesList(null)
            toast.success('Footnote saved')
        })
    }

    const selectFootnote = (footnoteId) => {
        setMarginNumber('')
        setExternalLink('')
        setFootnoteSelected(footnoteId)
    }
    // const renderItem = (item) => {
    //     if ([1, 2, 8, 11].includes(item.type)) {
    //         return <h4 className={STYLE_TYPES[item.type]}>

    //             {t(NAMINGS[item.type])}
    //             <button className={styles.buttonIgnore} onClick={item.type === 2 ? () => {
    //                 setInternalLink(`/article?id=${item.id}`)
    //                 setExternalLink('')
    //             } : () => {
    //                 //do nothing
    //             }} >{[11].includes(item.type) ? convertToRoman(item.number) : item.number}. {item.text}</button>
    //             {[1, 2, 8, 11].includes(item.number) &&
    //                 item.children?.sort((a, b) => a.number - b.number).map(renderItem)
    //             }
    //         </h4>
    //     }
    //     return
    // }

    return <Modal show={show} onHide={hideModal} dialogClassName='mx-5 mw-100' contentClassName="w-100"  >
        <ToastContainer />
        <ToastContainer />
        <Modal.Header>
            <Modal.Title>{t('edit the text')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-between'>
            <div className="col-6 p-2">
                <FormGroup className="mb-2">
                    <FormLabel>{t('text')}</FormLabel>
                    <textarea name="" id="" className='form-control' cols="10" rows="4" value={modalText} onChange={e => dispatch(updateNewText(e.target.value))}></textarea>
                </FormGroup>
                {modalDescription && <><FormLabel>{t('description')}</FormLabel>
                    <textarea name="" id="" className='form-control' cols="10" rows="4" value={modalDescription} onChange={e => dispatch(updateNewDescription(e.target.value))}></textarea>
                </>
                }

                <FormGroup>
                    <FormLabel>Select action</FormLabel>
                    <br />
                    <Button onClick={() => setAction('insertExternalLink')}>Insert external link</Button>
                    <Button onClick={() => setAction('insertInternalLink')} className="mx-2">Insert internal link</Button>
                    <Button onClick={() => setAction('insertFootnote')}>Insert footnote</Button>
                </FormGroup>



            </div>
            <div className="col-6 p-2">
                {action === 'insertExternalLink' &&
                    <FormGroup className="mb-2">
                        <FormLabel>{t('enter link')}</FormLabel>
                        <textarea name="" id="" className='form-control' cols="10" rows="1" placeholder="eg. https://www.google.com/" value={externalLink} onChange={e => { setExternalLink(e.target.value); setInternalLink('') }}></textarea>
                    </FormGroup>
                }
                {action === 'insertInternalLink' && <FormGroup>
                    <FormLabel> Set internal link to:</FormLabel>
                    {/* {loading ?
                    <>
                        <span>Loading...</span>
                    </>
                    :
                    <div className={styles.contentWrapper}>
                        {documentStructure.sort((a, b) => a.number - b.number).map(renderItem)}
                    </div>
                } */}

                    <FormGroup className="mb-2">
                        <FormLabel>{t('enter article number')}</FormLabel>
                        <FormControl type="number" name="" id="" className='form-control' cols="10" rows="1" placeholder="eg. 2" value={articleNumber} onChange={e => { setArticleNumber(e.target.value); setInternalLink('') }} />
                    </FormGroup>
                    <FormGroup className="mb-2">
                        <FormLabel>{t('enter margin number')}</FormLabel>
                        <FormControl type="number" name="" id="" className='form-control' cols="10" rows="1" placeholder="eg. 10" value={marginNumber} onChange={e => { setMarginNumber(e.target.value); setInternalLink('') }} />
                    </FormGroup>
                </FormGroup>
                }
                {action === 'insertFootnote' &&
                    <FormGroup>
                        <FormLabel> Or insert a footnote:</FormLabel>
                        <div className="d-flex">
                            <Button onClick={() => { setAddNewFootnote(true); setShowFootnotesList(false) }} >Create new footnote</Button>
                            <Button onClick={() => { setAddNewFootnote(false); setShowFootnotesList(true) }} className="mx-4">Insert an existing footnote</Button>
                        </div>
                        {
                            addNewFootnote &&
                            <>
                                <FormGroup className="mb-2">
                                    <FormLabel>{t('enter new footnote')}</FormLabel>
                                    <FormControl type="text" name="" id="" className='form-control' cols="10" rows="1" placeholder="eg. this is some text for footnote..." value={footnoteValue} onChange={e => { setFootnoteValue(e.target.value) }} />
                                </FormGroup>
                                <Button type="button" onClick={saveNewFootnote}>Save new footnote</Button>
                            </>
                        }
                        {
                            showFootnotesList &&

                            <div>
                                {
                                    (!footnotesList || footnotesList.length === 0) ?
                                        <p>No footnotes in the system for this article</p> : <p>Click footnote item to copy</p>
                                }
                                {
                                    footnotesList?.map((footnote, index) => {
                                        return <FootnoteItem key={index}  footnote={footnote} selectFootnote={selectFootnote}  update={()=>setFootnotesList(null)}/>
                                        
                                    })
                                }

                            </div>
                        }
                    </FormGroup>
                }
                {['insertExternalLink', 'insertInternalLink'].includes(action) &&
                    <FormGroup className="mb-2">
                        <FormLabel>{t('enter the word')}</FormLabel>
                        <textarea name="" id="" className='form-control' cols="10" rows="2" placeholder="eg. part 1 art. 2" value={wordForTheLink} onChange={e => setWordForTheLink(e.target.value)}></textarea>
                    </FormGroup>
                }

                <FormGroup className="mb-2 d-flex flex-column">
                    <FormLabel>{t('copy the link')}</FormLabel>
                    <FormControl ref={textAreaRef} value={generatedLink} onClick={generatedLink && copyToClipboard} onChange={e => dispatch(updateNewText(e.target.value))}></FormControl>
                    <FormLabel className="align-self-center text-success"><em>{copySuccess}</em></FormLabel>
                    {generatedLink &&
                        <FormLabel><em>{t('copy and paste the link')}</em></FormLabel>
                    }
                </FormGroup>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={saveChanges}>
                {t('save changes')}
            </Button>
            <Button variant="secondary" onClick={hideModal}>
                {t('close')}
            </Button>
        </Modal.Footer>
    </Modal>

}